import Header from "components/header/header";
import Quote from "inspirational-quotes";
import {useState, useEffect} from "react";
import {BsFillArrowUpRightCircleFill} from "react-icons/bs";
import onlineConvoImage from "media/graphics/female-doctor-convo.png";

function Home() {
    let [quote, setQuote] = useState({text: "", author: ""});
    useEffect(() => {
        setQuote(Quote.getQuote());
    }, []);
    return (
        <div className="relative flex flex-col w-full h-full min-h-screen max-w-screen">
            <Header></Header>
            <div className="relative flex flex-1 w-full bg-gray-100 z-1">
                <main className="flex flex-col w-full h-full p-5 space-y-5  md:px-10 lg:px-52">
                    <div className=" flex w-full lg:h-[30rem] lg:space-x-5 space-y-5 lg:space-y-0 flex-col lg:flex-row justify-center items-center">
                        <div className="w-full h-full bg-no-repeat bg-cover rounded-lg shadow-sm bg-colored lg:w-2/3 ">
                            <div className="flex flex-col p-4 ">
                                <span className="mt-10 text-xl font-semibold lg:text-3xl">Introducing the all-new</span>
                                <div className="mt-2">
                                    <span className="px-1 py-1 text-5xl font-bold leading-snug text-white rounded-sm lg:text-6xl decoration-clone bg-primary">
                                        EKMC Clinic Management System
                                    </span>
                                </div>
                                <span className="mt-10 font-mono text-xl lg:text-4xl decoration-clone ">
                                    A compilation of all pertinent tools and softwares that support EKMC Medical and Management Operations
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col w-full h-full p-4 bg-white rounded-lg shadow-sm lg:w-1/3">
                            <h2 className="text-2xl font-bold text-gray-700">Quote of the Day</h2>
                            <div className="flex flex-col items-center justify-center flex-grow p-4 space-y-4">
                                <span className="text-3xl font-bold tracking-tight text-center text-gray-600 mt-7">"{quote.text}"</span>
                                <span className="self-end text-lg italic font-bold text-right text-primary">- {quote.author}</span>
                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-col lg:flex-row w-full lg:h-[30rem] space-y-5 lg:space-y-0 lg:space-x-5">
                        <div className="w-full h-full p-4 bg-white rounded-lg shadow-sm lg:w-2/5">
                            <h2 className="text-2xl font-bold text-gray-700">Newest Partners</h2>
                            <div className="grid grid-cols-1 gap-3 pt-5 pl-5">
                                <div className="flex flex-row">
                                    <img
                                        src="https://drive.google.com/uc?export=view&id=1Hj1B-4X88S_fn-rd2mVzlkQSKJPXk2uU"
                                        className="w-24 h-24 border-4 rounded-full border-primary"
                                    ></img>
                                    <div className="flex items-center ml-6">
                                        <div className="flex flex-col">
                                            <span className="font-bold text-primary">Ralph Allen Prodigalidad</span>
                                            <span className="italic text-gray-500">Patient Care Officer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <img
                                        src="https://drive.google.com/uc?export=view&id=1gRbRWaz6pZZeefSwzyu7cqTx1-LOWONy"
                                        className="w-24 h-24 border-4 rounded-full border-secondary"
                                    ></img>
                                    <div className="flex items-center ml-6">
                                        <div className="flex flex-col">
                                            <span className="font-bold text-secondary">Gabriel Bellon</span>
                                            <span className="italic text-gray-500">VP for Business Development</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative flex flex-col items-center justify-center w-full p-5 rounded-lg shadow-sm  lg:w-3/5 lg:h-full lg:grid lg:grid-cols-2 bg-primaryPrime">
                            <BsFillArrowUpRightCircleFill className="absolute w-6 h-6 p-0 m-0 text-white top-2 right-2"></BsFillArrowUpRightCircleFill>
                            <div className="flex flex-col items-center justify-center p-3 ">
                                <div className="flex flex-col items-center px-5 py-5 mx-3 mt-5 text-3xl font-bold bg-white md:flex-row lg:flex-col whitespace-nowrap lg:px-10 rounded-2xl lg:text-4xl">
                                    <span className="mr-0 text-primary md:mr-2 lg:mr-0">E-Konsulta</span>
                                    <span className="text-secondary">Medical Clinic</span>
                                </div>
                                <span className="px-10 mt-10 text-lg font-normal tracking-wider text-center text-gray-700 indent-1">
                                    To be the <span className="inline underline">#1 Remote Consultation Provider</span> in the Philippines by providing{" "}
                                    <span className="font-bold">quality</span> and <span className="font-bold">affordable</span> healthcare services
                                </span>
                            </div>
                            <div className="flex items-center justify-center lg:p-20">
                                <img src={onlineConvoImage} alt="Virtual consultation provider" className="w-20 h-20 lg:h-full lg:w-full"></img>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Home;
