
import Header from 'components/header/header'
import { useEffect } from 'react'
import { fetchFollowupsDashboard } from 'features/dashboard/follow-ups/followupsDashboardSlice'
import { useSelector, useDispatch } from 'react-redux'
import { MdEmail } from 'react-icons/md'
import { AiFillPhone } from 'react-icons/ai'

function FollowUps() {
    const followupsDashboardData = useSelector(state => state.followupsDashboard)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchFollowupsDashboard())
    }, [])
    return (
        <div className="h-full flex flex-col min-h-screen max-h-screen w-full max-w-screen antialiased relative">
            <Header></Header>
            <div className="flex flex-col flex-1 w-full h-full  relative z-1">
                <div className="w-full h-auto grid grid-cols-4 gap-10 p-10 text-white">
                    <div className="bg-primary rounded-lg shadow-lg space-y-2 flex flex-col p-4 font-mono">
                        <h1 className="font-bold text-xl text-center">
                            Email Optins
                        </h1>
                        <span className="font-bold text-3xl text-center flex items-center justify-center gap-2">
                            {!followupsDashboardData.loading ? followupsDashboardData.data.emailOptin : "0"}
                            <MdEmail></MdEmail>
                        </span>
                        <span className="self-end pt-3">
                            Accurate as of {!followupsDashboardData.loading ? followupsDashboardData.data.timestamp : "0"}
                        </span>
                    </div>
                    <div className="bg-primary rounded-lg shadow-lg space-y-2 flex flex-col p-4 font-mono">
                        <h1 className="font-bold text-xl text-center">
                            SMS Optins
                        </h1>
                        <span className="font-bold text-3xl text-center flex items-center justify-center gap-2">
                            {!followupsDashboardData.loading ? followupsDashboardData.data.smsOptin : "0"}
                            <AiFillPhone></AiFillPhone>
                        </span>
                        <span className="self-end pt-3">
                            Accurate as of {!followupsDashboardData.loading ? followupsDashboardData.data.timestamp : "0"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FollowUps