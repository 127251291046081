import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: `AIzaSyATvrZV747vY-f-1VHlHAWy4bB4kGSWfQw`,
    authDomain: `ekmc-api.firebaseapp.com`,
    projectId: `ekmc-api`,
    storageBucket: `ekmc-api.appspot.com`,
    messagingSenderId: `196354031264`,
    appId: `1:196354031264:web:dd388bc98f294d9385d3c5`,
    measurementId: `G-YPDLB45VEN`,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const firestore = db;
const auth = firebase.auth();
const Timestamp = firebase.firestore.Timestamp;

export { auth, db, Timestamp, firebaseApp, firebase, firestore };

export default db;
