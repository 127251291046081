import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import "./index.css";
import "tw-elements";
import App from "./App";

if (process.env.REACT_APP_DEVELOPMENT === "FALSE") {
    disableReactDevTools();
}

// import ReactDOM from 'react-dom/client'
// ReactDOM.createRoot(document.getElementById('root')).render(<App />)

import { render } from "react-dom";
const container = document.getElementById("root");

render(<App />, container);
