/* eslint-disable no-async-promise-executor */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "scripts/dayjs-setup";
import { db, Timestamp } from "scripts/firebase-setup";

const initialState = {
    loading: true,
    data: {
        ads: [],
        campaigns: []
    },
    error: ""
};

export const fetchConsultations = createAsyncThunk(
    "paidPatients/fetchPaidPatients",
    async (args) => {
        let consultationDocuments = [];
        let leadsDocuments = [];
        let payload = [];
        let start = dayjs(args.start);
        let end = dayjs(args.end);
        let startTimestamp = new Timestamp(start.unix(), 0);
        let endTimestamp = new Timestamp(end.unix(), 0);
        let leadsSnapshot = await db
            .collection("patients")
            .where(`inquiryTime`, ">=", startTimestamp)
            .where(`inquiryTime`, "<=", endTimestamp)
            .get();
        let consultationsSnapshot = await db
            .collection("consultations")
            .where(`createdAt`, ">=", startTimestamp)
            .where(`createdAt`, "<=", endTimestamp)
            .get();
        for (let doc of leadsSnapshot.docs) {
            leadsDocuments.push(doc.data());
        }
        for (let doc of consultationsSnapshot.docs) {
            consultationDocuments.push(doc.data());
        }

        for (let consultationDocument of consultationDocuments) {
            let index = leadsDocuments.findIndex((i) => i.psID == consultationDocument.psID);
            let patientName = "None";
            let adID = "None";
            let manychatLink = consultationDocument.psID;
            if (index !== -1) {
                patientName = leadsDocuments[index].fFullName;
                manychatLink = leadsDocuments[index].manychatLink;
                adID = leadsDocuments[index].referralAdID || "None";
                if (leadsDocuments[index].referralRef) adID = leadsDocuments[index].referralRef;
            } else {
                let leadsSnapshot = await db
                    .collection("patients")
                    .where(`psID`, "==", consultationDocument.psID)
                    .get();
                let leadData = leadsSnapshot.docs[0].data();
                patientName = leadData.fFullName;
                manychatLink = leadData.manychatLink;
                let referralRef = leadData.referralRef;
                adID = leadData.referralAdID || "None";
                if (referralRef) adID = referralRef;
            }
            payload.push({
                timestamp: dayjs(consultationDocument.createdAt.toDate()).format("LLL"),
                patientName: patientName,
                adID: adID,
                psID: consultationDocument.psID,
                patientID: consultationDocument.patientID,
                consultationID: consultationDocument.ID,
                manychatLink: manychatLink,
                isBooked: consultationDocument.isBookedTagApplied,
                isConsulted: consultationDocument.isConsultedTagApplied,
                isCancelled: consultationDocument.isCancelledTagApplied,
                isPaid: consultationDocument.isPaidTagApplied,
                amountPaid: consultationDocument.amountPaid
            });
        }
        return payload;
    }
);

const consultationsSlice = createSlice({
    name: "consultations",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchConsultations.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchConsultations.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(fetchConsultations.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    }
});

export default consultationsSlice.reducer;
