import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'scripts/dayjs-setup'
import { db, Timestamp } from 'scripts/firebase-setup'
import adsData from 'media/database/adsData'

const initialState = {
    loading: true,
    data: {
        labels: [],
        metaInquiriesData: [],
        manychatInquiriesData: [],
        paidInquiriesData: [],
        coldInquiriesData: [],
        warmInquiriesData: [],
        hotInquiriesData: [],
        adQualityData: [],
    },
    error: "",
}

export const fetchDailyInquiries = createAsyncThunk('dailyInquiries/fetchDailyInquiries', async (args) => {
    let start = dayjs(args.start)
    let end = dayjs(args.end)
    let daysDiff = end.diff(start, 'days')
    let diffs = [...Array(daysDiff + 1).keys()].map(x => x)
    let dates = diffs.map((i) => start.add(i, 'days'))
    let labels = dates.map((i) => i.format("MMM DD"))
    let metaInquiriesData = []
    let manychatInquiriesData = []
    let paidInquiriesData = []
    let coldInquiriesData = []
    let warmInquiriesData = []
    let hotInquiriesData = []
    let adQualityData = []
    for await (let i of dates) {
        let currentDateStart = new Date(i)
        let currentDateEnd = new Date(i.add(1, 'days').subtract(1, 'seconds'))
        let leadsSnapshot = await db.collection('patients').where(`inquiryTime`, ">=", Timestamp.fromDate(currentDateStart)).where(`inquiryTime`, "<=", Timestamp.fromDate(currentDateEnd)).get()
        // let leadsSnapshot = await db.collection('patients').where(`inquiryTime`, ">=", Timestamp.fromDate(currentDateStart)).where(`inquiryTime`, "<=", Timestamp.fromDate(currentDateEnd)).limit(50).get()
        let manychatSnapshot = await db.collection('manychat-leads').where(`inquiryTimestamp`, ">=", Timestamp.fromDate(currentDateStart)).where(`inquiryTimestamp`, "<=", Timestamp.fromDate(currentDateEnd)).get()
        let paidInquiriesCounter = 0
        let coldTemp = 0
        let warmTemp = 0
        let hotTemp = 0
        leadsSnapshot.docs.forEach((doc) => {
            let interactions = 0
            let docData = doc.data()

            if ('messagesSentCounter' in docData && docData.messagesSentCounter >= 0) {
                interactions += docData.messagesSentCounter
            }
            if ('postbackCounter' in docData && docData.postbackCounter >= 0) {
                interactions += docData.postbackCounter
            }

            if ('referralAdID' in docData && docData.referralAdID !== " ") {
                paidInquiriesCounter += 1
                const snapshot = adQualityData.filter((x) => x.adID == docData.referralAdID);
                if (snapshot.length == 0) {
                    let adObject = adsData.find((x) => x.adID == docData.referralAdID)
                    adQualityData.push({
                        adID: docData.referralAdID,
                        adName: adObject && 'adName' in adObject ? adObject.adName : "unknown",
                        totalInquiries: 1,
                        coldInquiries: interactions <= 5 ? 1 : 0,
                        warmInquiries: interactions > 5 && interactions <= 25 ? 1 : 0,
                        hotInquiries: interactions > 25 ? 1 : 0,
                    })
                } else {
                    let adObject = snapshot[0]
                    adObject.totalInquiries += 1
                    if (interactions <= 5) {
                        coldTemp += 1
                        adObject.coldInquiries += 1
                    } else if (interactions <= 25) {
                        warmTemp += 1
                        adObject.warmInquiries += 1
                    } else {
                        hotTemp += 1
                        adObject.hotInquiries += 1
                    }
                    adQualityData[adQualityData.indexOf(snapshot[0])]
                }

            } else {
                if (interactions <= 5) {
                    coldTemp += 1
                } else if (interactions <= 25) {
                    warmTemp += 1
                } else {
                    hotTemp += 1
                }
            }



        })
        coldInquiriesData.push(coldTemp)
        warmInquiriesData.push(warmTemp)
        hotInquiriesData.push(hotTemp)
        paidInquiriesData.push(paidInquiriesCounter)
        metaInquiriesData.push(leadsSnapshot.size)
        manychatInquiriesData.push(manychatSnapshot.size)
    }
    let payload = {
        labels,
        metaInquiriesData,
        paidInquiriesData,
        coldInquiriesData,
        warmInquiriesData,
        hotInquiriesData,
        adQualityData,
        manychatInquiriesData
    }
    return payload
})


const dailyInquiriesSlice = createSlice({
    name: "dailyInquiries",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchDailyInquiries.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchDailyInquiries.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ""
        })
        builder.addCase(fetchDailyInquiries.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
})

export default dailyInquiriesSlice.reducer