
import Header from 'components/header/header'

import { useState, useEffect } from 'react'
import { DateRangeButton } from '../buttons'
import { fetchPaidLeads } from 'features/analytics/paidLeadsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { PaidLeadsByAdIDParent, PaidLeadsByCampaignIDParent } from './table'
import dayjs from 'scripts/dayjs-setup'


function PaidLeads() {
    const dailyInquiriesData = useSelector(state => state.paidLeads)
    const dispatch = useDispatch()
    let [analaysisDateRange, setAnalysisDateRange] = useState([dayjs({ year: dayjs().year(), month: dayjs().month(), date: dayjs().date() - 2 }).toDate(), dayjs({ year: dayjs().year(), month: dayjs().month(), date: dayjs().date() + 1 }).subtract(1, 'second').toDate()]);

    useEffect(() => {
        if (dailyInquiriesData.data.length == 0) {
            dispatch(fetchPaidLeads({
                start: analaysisDateRange[0], end: analaysisDateRange[1]
            }))
        }
    }, [])

    useEffect(() => {
        dispatch(fetchPaidLeads({
            start: analaysisDateRange[0], end: analaysisDateRange[1]
        }))
    }, [analaysisDateRange])
    return (
        <div className="h-full min-h-screen w-full max-w-screen antialiased relative">
            <Header></Header>
            <div className="flex flex-1 relative z-1 bg-white">
                <div className="flex w-full flex-col gap-4 p-4">
                    <div className="flex justify-end w-full">
                        <div className="flex bg-primary p-3 rounded-lg shadow-lg lg:mr-0">
                            <div className="bg-white p-2 rounded-sm flex items-center justify-center font-bold ">
                                <DateRangeButton value={analaysisDateRange} onChange={setAnalysisDateRange}></DateRangeButton>
                            </div>
                        </div>
                        <div className="hidden md:flex items-center justify-center mx-4 lg:mx-10">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                            </svg>
                        </div>
                    </div>
                    <div className="bg-primary p-5 flex flex-col rounded-lg gap-2">
                        <h2 className="text-4xl font-bold text-white ">
                            Paid Leads Counters (by Ad ID)
                        </h2>
                        <div className="px-10 py-5 w-full max-h-[40rem] overflow-y-scroll bg-white rounded-lg shadow-lg">
                            <PaidLeadsByAdIDParent></PaidLeadsByAdIDParent>
                        </div>
                    </div>
                    <div className="bg-primary p-5 flex flex-col rounded-lg gap-2">
                        <h2 className="text-4xl font-bold text-white ">
                            Paid Leads Counters (by Campaign ID)
                        </h2>
                        <div className="px-10 py-5 w-full  max-h-[40rem] overflow-y-scroll bg-white rounded-lg shadow-lg">
                            <PaidLeadsByCampaignIDParent></PaidLeadsByCampaignIDParent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaidLeads