import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";

import Header from "components/header/header";
import LoadingCircle from "components/graphics/loadingCircle";
import RequiredError from "components/medical-documents/requiredError";
import LoyaltyCardTemplate from "media/documents/loyalty-card-template.png";

function Generate() {
    let [isSubmitting, setIsSubmitting] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        // defaultValues: {},
        shouldUnregister: true,
    });
    const onSubmit = (data) => {
        setIsSubmitting(true);
        let payload = data;
        const config = {
            headers: {
                "Content-Type": `application/json;charset=UTF-8`,
                "Access-Control-Allow-Origin":
                    process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
            },
            withCredentials: true,
        };
        let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/loyalty-program/generate`;
        if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
            url = `${process.env.REACT_APP_DEV_API_DOMAIN}/loyalty-program/generate`;
        }
        axios.post(url, payload, config).then((response) => {
            setIsSubmitting(false);
            let link = response.data;
            alert(`Loyalty Card was Successfully generated`);
            window.open(link);
        });
    };
    const handleReset = () => {
        reset();
    };
    return (
        <div className="flex flex-col h-full min-h-screen max-h-screen w-full max-w-screen relative">
            <Header></Header>
            <div className="flex flex-1 relative z-1 w-full h-full bg-white">
                <div className="grid grid-cols-2 gap-10 p-10">
                    <div className="flex items-center justify-center px-20">
                        <form
                            className="flex flex-col w-full p-6 space-y-2 bg-white border-4 border-primary rounded-xl shadow-lg"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <h1 className="font-bold text-4xl text-primary leading-none">EKMC Loyalty Card Generation Form</h1>
                            <p className="font-mono text-gray-700 text-lg pb-4 font-bold ">
                                Please type the information exactly as how you want it to appear on the card
                            </p>

                            <div className="flex flex-col space-y-1 w-full">
                                <label id="subcriberID" htmlFor="subcriberID" className="font-bold">
                                    Subscriber's ID #
                                </label>
                                <input
                                    id="subcriberID"
                                    type="text"
                                    placeholder="000 123 156 7890"
                                    className="uppercase w-3/4 border-primary border-2"
                                    {...register("subcriberID", { required: true })}
                                />
                                {errors.subcriberID?.type == "required" && <RequiredError />}
                            </div>

                            <div className="flex flex-col space-y-1 w-full">
                                <label id="subscriberName" htmlFor="subscriberName" className="font-bold">
                                    Subscriber's name
                                </label>
                                <input
                                    id="subscriberName"
                                    type="text"
                                    placeholder="JUANA A. JUANITA"
                                    className="uppercase w-full border-primary border-2"
                                    {...register("subscriberName", { required: true })}
                                />
                                {errors.subscriberName?.type == "required" && <RequiredError />}
                            </div>

                            <div className="flex flex-col space-y-1 w-full">
                                <label id="expirationDate" htmlFor="expirationDate" className="font-bold">
                                    Expiration Date
                                </label>
                                <input
                                    id="expirationDate"
                                    type="text"
                                    placeholder="06/2027"
                                    className="uppercase w-1/2 border-primary border-2"
                                    {...register("expirationDate", { required: true })}
                                />
                                {errors.expirationDate?.type == "required" && <RequiredError />}
                            </div>

                            <div className="flex justify-end space-x-2">
                                <button type="submit" className="bg-green-500 text-white rounded-lg px-4 py-2 font-bold flex items-center gap-x-2">
                                    {isSubmitting && (
                                        <div className="h-4 w-4">
                                            <LoadingCircle></LoadingCircle>
                                        </div>
                                    )}
                                    Generate
                                </button>
                                <button type="button" className="bg-orange-500 text-white rounded-lg px-4 py-2 font-bold " onClick={handleReset}>
                                    Clear
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="flex items-center justify-center">
                        <img src={LoyaltyCardTemplate} alt="Loyalty Card Template" className="rounded-lg bg-primary p-4"></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Generate;
