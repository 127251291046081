/* eslint-disable no-async-promise-executor */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "scripts/dayjs-setup";
import { db, Timestamp } from "scripts/firebase-setup";
import adsData from "media/database/adsData";
import axios from "axios";

const initialState = {
    loading: true,
    data: {
        ads: [],
        campaigns: [],
    },
    error: "",
};

export const fetchPaidPatients = createAsyncThunk("paidPatients/fetchPaidPatients", async (args) => {
    let start = dayjs(args.start);
    let end = dayjs(args.end);
    let startTimestamp = new Timestamp(start.unix(), 0);
    let endTimestamp = new Timestamp(end.unix(), 0);
    let leadsSnapshot = await db.collection("patients").where(`inquiryTime`, ">=", startTimestamp).where(`inquiryTime`, "<=", endTimestamp).get();
    let consultationsSnapshot = await db.collection("consultations").where(`createdAt`, ">=", startTimestamp).where(`createdAt`, "<=", endTimestamp).get();
    let consultationDocuments = [];
    consultationsSnapshot.docs.forEach(async (doc) => {
        consultationDocuments.push(doc.data());
    });

    let ads = [];
    let campaigns = [];
    leadsSnapshot.docs.forEach(async (doc) => {
        let docData = doc.data();
        if ("referralAdID" in docData && docData.referralAdID !== " ") {
            let adObject = adsData.find((x) => x.adID == docData.referralAdID);
            let activeConsultationID = docData.activeConsultationID;
            let consultationData = consultationDocuments.find((x) => x.ID == activeConsultationID);
            let isBooked = consultationData ? consultationData.isBookedTagApplied : false;
            let isConsulted = consultationData ? consultationData.isConsultedTagApplied : false;
            let isCancelled = consultationData ? consultationData.isCancelledTagApplied : false;
            let isPaid = consultationData ? consultationData.isPaidTagApplied : false;
            let amountPaid = consultationData ? consultationData.amountPaid : false;
            const AdIDSnapshot = ads.find((x) => x.adID == docData.referralAdID);

            // add to ads list
            if (typeof AdIDSnapshot == "undefined") {
                ads.push({
                    adID: docData.referralAdID,
                    adName: adObject && "adName" in adObject ? adObject.adName : "unknown",
                    totalPaidLeads: 1,
                    totalBookedPatients: isBooked ? 1 : 0,
                    totalConsultedPatients: isConsulted ? 1 : 0,
                    totalCancelledPatients: isCancelled ? 1 : 0,
                    totalPaidPatients: isPaid ? 1 : 0,
                    revenue: amountPaid,
                    link:
                        process.env.REACT_APP_DEVELOPMENT == `TRUE`
                            ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}/analytics/ad/${docData.referralAdID}`
                            : `${process.env.REACT_APP_PROD_CMS_DOMAIN}/analytics/ad/${docData.referralAdID}`,
                });
            } else {
                let temp = AdIDSnapshot;
                temp.totalPaidLeads += 1;
                temp.totalBookedPatients += isBooked ? 1 : 0;
                temp.totalConsultedPatients += isConsulted ? 1 : 0;
                temp.totalCancelledPatients += isCancelled ? 1 : 0;
                temp.totalPaidPatients += isPaid ? 1 : 0;
                temp.revenue += amountPaid;
                ads[ads.indexOf(AdIDSnapshot)] = temp;
            }
            // add to campaigns list
            if (typeof adObject !== "undefined") {
                const campaignIDSnapshot = campaigns.find((x) => x.campaignID == adObject.campaignID);
                if (typeof campaignIDSnapshot == "undefined") {
                    campaigns.push({
                        campaignID: adObject.campaignID,
                        campaignName: adObject.campaignName,
                        totalPaidLeads: 1,
                        totalBookedPatients: isBooked ? 1 : 0,
                        totalConsultedPatients: isConsulted ? 1 : 0,
                        totalCancelledPatients: isCancelled ? 1 : 0,
                        totalPaidPatients: isPaid ? 1 : 0,
                        revenue: amountPaid,
                        link:
                            process.env.REACT_APP_DEVELOPMENT == `TRUE`
                                ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}/analytics/campaign/${adObject.campaignID}`
                                : `${process.env.REACT_APP_PROD_CMS_DOMAIN}/analytics/campaign/${adObject.campaignID}`,
                    });
                } else {
                    let temp = campaignIDSnapshot;
                    temp.totalPaidLeads += 1;
                    temp.totalBookedPatients += isBooked ? 1 : 0;
                    temp.totalConsultedPatients += isConsulted ? 1 : 0;
                    temp.totalCancelledPatients += isCancelled ? 1 : 0;
                    temp.totalPaidPatients += isPaid ? 1 : 0;
                    temp.revenue += amountPaid;
                    campaigns[campaigns.indexOf(campaignIDSnapshot)] = temp;
                }
            }
        }
    });
    let promises = [];
    for (let i = 0; i < ads.length; i++) {
        let promise = new Promise(async (resolve) => {
            try {
                let adInquiryObject = ads[i];
                let url = `https://graph.facebook.com/v14.0/${adInquiryObject.adID}/insights/`;
                const config = {
                    params: {
                        time_range: `{"since":"${start.format("YYYY-MM-DD")}","until":"${end.format("YYYY-MM-DD")}"}`,
                        fields: "spend",
                        access_token: process.env.REACT_APP_FBMARKETINGAPI_ACCESS_TOKEN,
                    },
                };
                let response = await axios.get(url, config);
                let campaignApiObject = response.data.data[0];
                ads[i].spend = parseFloat(campaignApiObject.spend);
                let profit = ads[i].revenue - parseFloat(campaignApiObject.spend);
                ads[i].profit = profit.toFixed(2);
                let costPerPaidPatient = ads[i].totalPaidPatients > 0 ? parseFloat(campaignApiObject.spend) / ads[i].totalPaidPatients : 0;
                ads[i].costPerPaidPatient = costPerPaidPatient.toFixed(2);
            } catch {
                ads[i].spend = 0;
                ads[i].profit = 0;
                ads[i].costPerPaidPatient = 0;
            }
            resolve();
        });
        promises.push(promise);
    }
    for (let i = 0; i < campaigns.length; i++) {
        let promise = new Promise(async (resolve) => {
            try {
                let campaignInquiryObject = campaigns[i];
                let url = `https://graph.facebook.com/v14.0/${campaignInquiryObject.campaignID}/insights/`;
                const config = {
                    params: {
                        time_range: `{"since":"${start.format("YYYY-MM-DD")}","until":"${end.format("YYYY-MM-DD")}"}`,
                        fields: "spend",
                        access_token: process.env.REACT_APP_FBMARKETINGAPI_ACCESS_TOKEN,
                    },
                };
                let response = await axios.get(url, config);
                let adApiObject = response.data.data[0];
                campaigns[i].spend = parseFloat(adApiObject.spend);
                let profit = campaigns[i].revenue - parseFloat(adApiObject.spend);
                campaigns[i].profit = profit.toFixed(2);
                let costPerPaidPatient = parseFloat(adApiObject.spend) / campaigns[i].totalPaidPatients;
                campaigns[i].costPerPaidPatient = costPerPaidPatient.toFixed(2);
            } catch {
                campaigns[i].spend = 0;
                campaigns[i].profit = 0;
                campaigns[i].costPerPaidPatient = 0;
            }
            resolve();
        });
        promises.push(promise);
    }
    await Promise.all(promises);

    let payload = {
        ads: ads,
        campaigns: campaigns,
    };

    return payload;
});

const paidPatientsSlice = createSlice({
    name: "paidPatients",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchPaidPatients.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchPaidPatients.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(fetchPaidPatients.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default paidPatientsSlice.reducer;
