/* eslint-disable react/prop-types */
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

export function DateRangeButton({ value, onChange }) {
    return (
        <div>
            <DateRangePicker onChange={onChange} value={value} />
        </div>
    );
}


export function adNameCell({ value }) {
    return (
        <div className="flex justify-start">
            <span>
                {value}
            </span>
        </div>
    );
}
