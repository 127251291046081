/* eslint-disable react/prop-types */
import { classNames } from "scripts/classnames";
function DocumentType({ value }) {
    const status = value ? value.toLowerCase() : `unknown`;
    return (
        <div className="flex justify-center">
            <span
                className={classNames(
                    `px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full text-center shadow-sm`,
                    status.startsWith(`lab-request`) ? `bg-green-100 text-green-700` : null,
                    status.startsWith(`prescription-form`) ? `bg-yellow-100 text-yellow-700` : null,
                    status.startsWith(`medical-certificate`) ? `bg-purple-100 text-purple-700` : null,
                    status.startsWith(`referral-form`) ? `bg-amber-100 text-amber-700` : null,
                    status.startsWith(`clinical-abstract`) ? `bg-orange-100 text-orange-700` : null
                )}
            >
                {status}
            </span>
        </div>
    );
}

export default DocumentType