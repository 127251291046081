import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "scripts/dayjs-setup";
import { db, Timestamp } from "scripts/firebase-setup";

const initialState = {
    loading: true,
    data: {
        daily: {
            labels: [],
            totalLeads: [],
            organicLeads: [],
            paidLeads: [],
        },
        am: {
            labels: [],
            totalLeads: [],
            organicLeads: [],
            paidLeads: [],
        },
        pm: {
            labels: [],
            totalLeads: [],
            organicLeads: [],
            paidLeads: [],
        },
    },
    error: "",
};

export const fetchLeads = createAsyncThunk("leads/fetchLeads", async (args) => {
    let start = dayjs(args.start);
    let end = dayjs(args.end);
    let daysDiff = end.diff(start, "day");
    let dailyLabels = [];
    let amLabels = [];
    let pmLabels = [];
    for (let i = 0; i <= daysDiff; i++) {
        let tempDayjs = start.add(i, "day");
        dailyLabels.push(tempDayjs.format("ll"));
        amLabels.push(`${tempDayjs.format("ll")} AM`);
        pmLabels.push(`${tempDayjs.format("ll")} PM`);
    }

    let startTimestamp = new Timestamp(start.unix(), 0);
    let endTimestamp = new Timestamp(end.unix(), 0);
    let leadsSnapshot = await db
        .collection("patients")
        .where(`inquiryTime`, ">=", startTimestamp)
        .where(`inquiryTime`, "<=", endTimestamp)
        .get();
    
        let manychatSnapshot = await db
        .collection("manychat-leads")
        .where(`inquiryTimestamp`, ">=", startTimestamp)
        .where(`inquiryTimestamp`, "<=", endTimestamp)
        .get();

    let dailyTotalLeads = dailyLabels.map(() => 0);
    let dailyOrganicLeads = dailyLabels.map(() => 0);
    let dailyPaidLeads = dailyLabels.map(() => 0);
    let manychatLeads = dailyLabels.map(() => 0);

    let amTotalLeads = dailyLabels.map(() => 0);
    let amOrganicLeads = dailyLabels.map(() => 0);
    let amPaidLeads = dailyLabels.map(() => 0);

    let pmTotalLeads = dailyLabels.map(() => 0);
    let pmOrganicLeads = dailyLabels.map(() => 0);
    let pmPaidLeads = dailyLabels.map(() => 0);

    leadsSnapshot.docs.forEach(async (doc) => {
        let docData = doc.data();
        let currentTimestamp = docData.inquiryTime;
        let currentDate = currentTimestamp.toDate();
        let currentDayjs = dayjs(currentDate);
        let isAm = currentDayjs.hour() <= 13 ? true : false; // if am shift or pm shift
        let index = currentDayjs.diff(start, "day");
        // paid leads
        if ("referralAdID" in docData && docData.referralAdID !== " ") {
            dailyPaidLeads[index] += 1;
            amPaidLeads[index] += isAm ? 1 : 0;
            pmPaidLeads[index] += isAm ? 0 : 1;
        } else {
            // organic lead
            dailyOrganicLeads[index] += 1;
            amOrganicLeads[index] += isAm ? 1 : 0;
            pmOrganicLeads[index] += isAm ? 0 : 1;
        }
        dailyTotalLeads[index] += 1;
        amTotalLeads[index] += isAm ? 1 : 0;
        pmTotalLeads[index] += isAm ? 0 : 1;
    });
console.log(manychatSnapshot.size)

    manychatSnapshot.docs.forEach(async (doc) => {
        let docData = doc.data();
        let currentTimestamp = docData.inquiryTimestamp;
        let currentDate = currentTimestamp.toDate();
        let currentDayjs = dayjs(currentDate);
        let index = currentDayjs.diff(start, "day");
        manychatLeads[index] += 1;

        
    })

    let payload = {
        daily: {
            labels: dailyLabels,
            totalLeads: dailyTotalLeads,
            organicLeads: dailyOrganicLeads,
            paidLeads: dailyPaidLeads,
            manychatLeads: manychatLeads
        },
        am: {
            labels: amLabels,
            totalLeads: amTotalLeads,
            organicLeads: amOrganicLeads,
            paidLeads: amPaidLeads,
        },
        pm: {
            labels: pmLabels,
            totalLeads: pmTotalLeads,
            organicLeads: pmOrganicLeads,
            paidLeads: pmPaidLeads,
        },
    };
    return payload;
});

const leadsSlice = createSlice({
    name: "leads",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchLeads.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchLeads.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(fetchLeads.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default leadsSlice.reducer;
