/* eslint-disable react/prop-types */
import axios from "axios";
import { useDispatch } from "react-redux";
import { set as setMedCert } from "features/medical-documents/med-cert/medCertSlice";
import { set as setPrescriptionForm } from "features/medical-documents/prescription-form/prescriptionFormSlice";
import { set as setLabRequest } from "features/medical-documents/lab-request/labRequestSlice";
import { set as setClinicalAbstract } from "features/medical-documents/clinical-abstract/clinicalAbstractSlice";
import { set as setReferralForm } from "features/medical-documents/referral-form/referralFormSlice";
import { useNavigate } from "react-router-dom";

function EditButton({ value }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const redirectEdit = async () => {
        let valueArgs = value.split(` `);
        let type = valueArgs[0];
        let documentID = valueArgs[1];
        if (type == `medical-certificate`) {
            let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/data/med-cert`;
            if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
                url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/data/med-cert`;
            }
            let payload = {
                documentID: documentID,
            };
            const config = {
                headers: {
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin":
                        process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
                },
                withCredentials: true,
            };
            let response = await axios.post(url, payload, config);
            dispatch(
                setMedCert({
                    documentID: documentID,
                    dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
                    patientName: response.data.patientName,
                    patientAge: response.data.patientAge,
                    patientSex: response.data.patientSex,
                    addressBuildingStreet: response.data.addressBuildingStreet,
                    addressCityProvince: response.data.addressCityProvince,
                    chiefComplaint: response.data.chiefComplaint,
                    impressions: response.data.impressions,
                    recommendations: response.data.recommendations,
                })
            );
            navigate("/medical-documents/generate/med-cert");
        } else if (type == `prescription-form`) {
            let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/data/prescription-form`;
            if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
                url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/data/prescription-form`;
            }
            let payload = {
                documentID: documentID,
            };
            const config = {
                headers: {
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin":
                        process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
                },
                withCredentials: true,
            };
            let response = await axios.post(url, payload, config);
            dispatch(
                setPrescriptionForm({
                    documentID: documentID,
                    dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
                    patientName: response.data.patientName,
                    patientAge: response.data.patientAge,
                    patientSex: response.data.patientSex,
                    address: response.data.address,
                    notes: response.data.notes,
                })
            );
            navigate("/medical-documents/generate/prescription-form");
        } else if (type == `lab-request`) {
            let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/data/lab-request`;
            if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
                url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/data/lab-request`;
            }
            let payload = {
                documentID: documentID,
            };
            const config = {
                headers: {
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin":
                        process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
                },
                withCredentials: true,
            };
            let response = await axios.post(url, payload, config);
            dispatch(
                setLabRequest({
                    documentID: documentID,
                    dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
                    patientName: response.data.patientName,
                    patientAge: response.data.patientAge,
                    patientSex: response.data.patientSex,
                    address: response.data.address,
                    notes: response.data.notes,
                })
            );
            navigate("/medical-documents/generate/lab-request");
        } else if (type == `clinical-abstract`) {
            let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/data/clinical-abstract`;
            if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
                url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/data/clinical-abstract`;
            }
            let payload = {
                documentID: documentID,
            };
            const config = {
                headers: {
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin":
                        process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
                },
                withCredentials: true,
            };
            let response = await axios.post(url, payload, config);
            dispatch(
                setClinicalAbstract({
                    documentID: documentID,
                    dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
                    patientName: response.data.patientName,
                    patientAge: response.data.patientAge,
                    patientSex: response.data.patientSex,
                    address: response.data.address,
                    notes: response.data.notes,
                    civilStatus: response.data.civilStatus,
                    chiefComplaint: response.data.chiefComplaint,
                    presentHistory: response.data.presentHistory,
                    pastHistory: response.data.pastHistory,
                    diagnosis: response.data.diagnosis,
                    therapeutics: response.data.therapeutics,
                    advice: response.data.advice,
                    diet: response.data.diet,
                })
            );
            navigate("/medical-documents/generate/clinical-abstract");
        } else if (type == `referral-form`) {
            let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/data/referral-form`;
            if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
                url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/data/referral-form`;
            }
            let payload = {
                documentID: documentID,
            };
            const config = {
                headers: {
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin":
                        process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
                },
                withCredentials: true,
            };
            let response = await axios.post(url, payload, config);
            dispatch(
                setReferralForm({
                    documentID: documentID,
                    dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
                    patientName: response.data.patientName,
                    patientAge: response.data.patientAge,
                    patientSex: response.data.patientSex,
                    diagnosis: response.data.diagnosis,
                    therapeutics: response.data.therapeutics,
                    referralReason: response.data.referralReason,
                    referralReceiver: response.data.referralReceiver,
                    dateOfConsultation: response.data.dateOfConsultation,
                    dateOfBirth: response.data.dateOfBirth,
                    history: response.data.history,
                    diagnostics: response.data.diagnostics,
                    remarks: response.data.remarks,
                })
            );
            navigate("/medical-documents/generate/referral-form");
        }
    };
    return (
        <div className="flex justify-center">
            <button
                className="px-3 py-1 uppercase text-center leading-wide font-bold text-xs rounded-full shadow-sm bg-sky-100 text-sky-700 hover:bg-sky-800 hover:text-white"
                onClick={redirectEdit}
            >
                EDIT / VIEW
            </button>
        </div>
    );
}

export default EditButton;
