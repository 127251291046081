import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Header from "components/header/header";
import clinicalAbstractTemplate from "media/documents/clinicalAbstractTemplate.png";
import RequiredError from "components/medical-documents/requiredError";
import { initialState, clear } from "features/medical-documents/clinical-abstract/clinicalAbstractSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FormMenu from "components/medical-documents/formMenu";

import LoadingCircle from "components/graphics/loadingCircle";
import { useState } from "react";
function ClinicalAbstract() {
    let [isSubmitting, setIsSubmitting] = useState(false);
    const profile = useSelector((state) => state.firebase.profile);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clinicalAbstractPayload = useSelector((state) => state.clinicalAbstract.clinicalAbstractPayload);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: clinicalAbstractPayload,
        shouldUnregister: true,
    });
    const onSubmit = (data) => {
        setIsSubmitting(true);
        let payload = {
            doctor: `${profile.displayName}`,
            date: `${data.dateOfCertification}`,
            name: `${data.patientName}`,
            age: `${data.patientAge}`,
            sex: `${data.patientSex}`,
            civil_status: `${data.civilStatus}`,
            history_present: `${data.presentHistory}`,
            history_past: `${data.pastHistory}`,
            chief_complaint: `${data.chiefComplaint}`,
            diagnosis: `${data.diagnosis}`,
            therapeutics: `${data.therapeutics}`,
            advice: `${data.advice}`,
            diet: `${data.diet}`,
        };
        const config = {
            headers: {
                "Content-Type": `application/json;charset=UTF-8`,
                "Access-Control-Allow-Origin":
                    process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
            },
            withCredentials: true,
        };
        if (clinicalAbstractPayload.documentID == "") {
            let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/generate/clinical-abstract`;
            if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
                url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/generate/clinical-abstract`;
            }
            axios.post(url, payload, config).then((response) => {
                let data = response.data;
                if (data.link) {
                    setIsSubmitting(false);
                    alert(`Clinical Abstract was successfully generated`);
                    navigate("/medical-documents/my-files");
                    window.open(data.link);
                } else {
                    setIsSubmitting(false);
                    alert(`There was an error generating your document`);
                }
            });
        } else {
            let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/update/clinical-abstract`;
            if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
                url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/update/clinical-abstract`;
            }
            payload.documentID = clinicalAbstractPayload.documentID;
            axios.post(url, payload, config).then((response) => {
                let data = response.data;
                if (data.link) {
                    setIsSubmitting(false);
                    alert(`Clinical Abstract was successfully updated`);
                    navigate(`/medical-documents/my-files`);
                    window.open(data.link);
                } else {
                    setIsSubmitting(false);
                    alert(`There was an error updating your document`);
                }
            });
        }
    };

    return (
        <div className="flex flex-col h-full min-h-screen w-full max-w-screen relative">
            <Header></Header>
            <div className="flex flex-1 relative z-1 w-full bg-medical-documents bg-cover bg-no-repeat">
                <main className="flex flex-col w-full h-full">
                    <FormMenu></FormMenu>
                    <div className="grid grid-cols-1 lg:grid-cols-2 w-full h-full p-5 gap-5 ">
                        <div className="flex items-center w-full justify-center md:px-10 ">
                            <form
                                className="flex flex-col w-full p-6 space-y-2 bg-white border-4 border-primary rounded-xl shadow-lg"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <h1 className="text-3xl font-bold">EKMC Clinical Abstract</h1>

                                <div className="flex flex-col space-y-1 w-full">
                                    <label id="dateOfCertification" htmlFor="dateOfCertification" className="font-bold">
                                        Date Of Certification
                                    </label>
                                    <input
                                        id="dateOfCertification"
                                        type="date"
                                        className="uppercase md:w-56 border-primary border-2"
                                        {...register("dateOfCertification", { required: true })}
                                    />
                                    {errors.dateOfCertification?.type == "required" && <RequiredError />}
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <label id="patientName" htmlFor="patientName" className="font-bold">
                                        Patient Name
                                    </label>
                                    <input
                                        id="patientName"
                                        type="text"
                                        className="w-full border-primary border-2"
                                        {...register("patientName", { required: true })}
                                        placeholder="Juan Dela Cruz"
                                    />
                                    {errors.patientName?.type == "required" && <RequiredError />}
                                </div>

                                <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                                    <div className="flex flex-col space-y-1 ">
                                        <label id="patientAge" htmlFor="patientAge" className="font-bold">
                                            Patient Age
                                        </label>
                                        <input
                                            id="patientAge"
                                            type="number"
                                            placeholder="Age"
                                            className="w-full border-primary border-2 md:w-40"
                                            {...register("patientAge", { required: true, min: 0 })}
                                        />
                                        {errors.patientAge?.type == "required" && (
                                            <span className="text-red-500 tracking-wide pl-2">*This field is required</span>
                                        )}
                                        {errors.patientAge?.type == "min" && (
                                            <span className="text-red-500 tracking-wide pl-2">*Patient age must be more than 0</span>
                                        )}
                                    </div>

                                    <div className="flex flex-col space-y-1 ">
                                        <label id="patientSex" htmlFor="patientSex" className="font-bold">
                                            Patient Sex
                                        </label>
                                        <select
                                            id="patientSex"
                                            placeholder="Sex"
                                            className="w-full border-primary border-2 md:w-40"
                                            {...register("patientSex", { required: true })}
                                        >
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </select>
                                        {errors.patientSex?.type == "required" && (
                                            <span className="text-red-500 tracking-wide pl-2">*This field is required</span>
                                        )}
                                    </div>

                                    <div className="flex flex-col space-y-1 ">
                                        <label id="civilStatus" htmlFor="civilStatus" className="font-bold">
                                            Civil Status
                                        </label>
                                        <select
                                            id="civilStatus"
                                            placeholder="Single"
                                            className="w-full border-primary border-2 md:w-40"
                                            {...register("civilStatus", { required: true })}
                                        >
                                            <option value="Single">Single</option>
                                            <option value="Married">Married</option>
                                            <option value="Separated">Separated</option>
                                            <option value="Divorced">Divorced</option>
                                            <option value="Widowed">Widowed</option>
                                        </select>
                                        {errors.civilStatus?.type == "required" && (
                                            <span className="text-red-500 tracking-wide pl-2">*This field is required</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <label id="chiefComplaint" htmlFor="chiefComplaint" className="font-bold">
                                        Chief Complaint
                                    </label>
                                    <input
                                        id="chiefComplaint"
                                        type="text"
                                        className="w-full border-primary border-2"
                                        {...register("chiefComplaint", { required: true })}
                                        placeholder="Chief Complaint"
                                    />
                                    {errors.chiefComplaint?.type == "required" && <RequiredError />}
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <label id="presentHistory" htmlFor="presentHistory" className="font-bold">
                                        History of Present Illness
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="presentHistory"
                                        type="text"
                                        className="w-full border-primary border-2"
                                        {...register("presentHistory", { required: true })}
                                        placeholder="History of Present Illness"
                                    />
                                    {errors.presentHistory?.type == "required" && <RequiredError />}
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <label id="pastHistory" htmlFor="pastHistory" className="font-bold">
                                        Past Medical History
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="pastHistory"
                                        type="text"
                                        className="w-full border-primary border-2"
                                        {...register("pastHistory", { required: true })}
                                        placeholder="Past Medical History"
                                    />
                                    {errors.pastHistory?.type == "required" && <RequiredError />}
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <label id="diagnosis" htmlFor="diagnosis" className="font-bold">
                                        Diagnosis/Impressions
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="diagnosis"
                                        type="text"
                                        className="w-full border-primary border-2"
                                        {...register("diagnosis", { required: true })}
                                        placeholder="Diagnosis"
                                    />
                                    {errors.diagnosis?.type == "required" && <RequiredError />}
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <label id="therapeutics" htmlFor="therapeutics" className="font-bold">
                                        Therapeutics
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="therapeutics"
                                        type="text"
                                        className="w-full border-primary border-2"
                                        {...register("therapeutics", { required: true })}
                                        placeholder="Therapeutics"
                                    />
                                    {errors.therapeutics?.type == "required" && <RequiredError />}
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <label id="advice" htmlFor="advice" className="font-bold">
                                        Advice
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="advice"
                                        type="text"
                                        className="w-full border-primary border-2"
                                        {...register("advice", { required: true })}
                                        placeholder="Advice"
                                    />
                                    {errors.advice?.type == "required" && <RequiredError />}
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <label id="diet" htmlFor="diet" className="font-bold">
                                        Diet
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="diet"
                                        type="text"
                                        className="w-full border-primary border-2"
                                        {...register("diet", { required: true })}
                                        placeholder="Diet"
                                    />
                                    {errors.diet?.type == "required" && <RequiredError />}
                                </div>

                                <div className="flex justify-end space-x-1 pt-4">
                                    <button type="submit" className="bg-green-500 text-white rounded-lg px-4 py-2 font-bold flex items-center gap-x-2 ">
                                        {clinicalAbstractPayload.documentID == "" ? "Generate" : "Update"}
                                        {isSubmitting && (
                                            <div className="h-4 w-4">
                                                <LoadingCircle></LoadingCircle>
                                            </div>
                                        )}
                                    </button>
                                    {clinicalAbstractPayload.documentID !== "" && (
                                        <button
                                            type="button"
                                            className="bg-red-500 text-white rounded-lg px-4 py-2 font-bold "
                                            onClick={() => {
                                                reset(clinicalAbstractPayload, { keepDefaultValues: true });
                                            }}
                                        >
                                            Reset
                                        </button>
                                    )}

                                    <button
                                        type="button"
                                        className="bg-orange-500 text-white rounded-lg px-4 py-2 font-bold "
                                        onClick={() => {
                                            dispatch(clear());
                                            reset(initialState.clinicalAbstractPayload);
                                        }}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="flex  rounded-xl items-center justify-center">
                            <img
                                className="rounded-xl shadow-lg w-full lg:max-w-xl border-4 border-primary"
                                src={clinicalAbstractTemplate}
                                alt="Clinical Abstract Template"
                            ></img>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default ClinicalAbstract;
