import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    loading: true,
    data: [],
    error: "",
};

export const fetchDocuments = createAsyncThunk("documents/fetchDocuments", async () => {
    let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/documents`;
    if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
        url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/documents`;
    }
    let payload = {};
    const config = {
        headers: {
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin":
                process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
        },
        withCredentials: true,
    };
    const response = await axios.post(url, payload, config);
    return response.data.data;
});

const documentsSlice = createSlice({
    name: "documents",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchDocuments.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchDocuments.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(fetchDocuments.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default documentsSlice.reducer;
