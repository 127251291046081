import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'scripts/dayjs-setup'
import { db, Timestamp } from 'scripts/firebase-setup'
import adsData from 'media/database/adsData'

const initialState = {
    loading: true,
    data: {
        info: {},
        inquiries: [],
        consultations: [],
    },
    error: "",
}

export const fetchAdAnalytics = createAsyncThunk('adAnalytics/fetchAdAnalytics', async (args) => {

    let start = dayjs(args.start)
    let end = dayjs(args.end)
    let adID = args.adID
    let startTimestamp = new Timestamp(start.unix(), 0)
    let endTimestamp = new Timestamp(end.unix(), 0)
    let leadsSnapshot = await db.collection('patients').where('referralAdID', '==', adID).where(`inquiryTime`, ">=", startTimestamp).where(`inquiryTime`, "<=", endTimestamp).get()
    let consultationsSnapshot = await db.collection('consultations').where(`createdAt`, ">=", startTimestamp).where(`createdAt`, "<=", endTimestamp).get()
    let consultationDocuments = []
    consultationsSnapshot.docs.forEach(async (doc) => {
        consultationDocuments.push(doc.data())
    })

    let inquiries = []
    let consultations = []
    let adObject = adsData.find((x) => x.adID == adID)

    leadsSnapshot.docs.forEach(
        async (doc) => {
            let docData = doc.data()
            let activeConsultationID = docData.activeConsultationID
            let consultationData = consultationDocuments.find((x) => x.ID == activeConsultationID)
            let isBooked = consultationData ? consultationData.isBookedTagApplied : false
            let isConsulted = consultationData ? consultationData.isConsultedTagApplied : false
            let isCancelled = consultationData ? consultationData.isCancelledTagApplied : false
            let isPaid = consultationData ? consultationData.isPaidTagApplied : false
            let amountPaid = consultationData ? consultationData.amountPaid : false
            inquiries.push({
                psID: docData.psID,
                patientID: docData.patientID,
                patientName: docData.fFullName,
                timestamp: dayjs(docData.inquiryTime.toDate()).format("LLL"),
                manychatLink: docData.manychatLink,
                messagesSentCounter: docData.messagesSentCounter,
            })
            if (consultationData) {
                consultations.push({
                    psID: docData.psID,
                    consultationID: activeConsultationID,
                    patientID: docData.patientID,
                    patientName: docData.fFullName,
                    timestamp: dayjs(docData.inquiryTime.toDate()).format("LLL"),
                    manychatLink: docData.manychatLink,
                    amountPaid,
                    isBooked,
                    isConsulted,
                    isCancelled,
                    isPaid
                })
            }

        }
    )

    let payload = {
        info: {
            adName: adObject.adName,
            adID: adID,
            campaignName: adObject.campaignName,
            campaignID: adObject.campaignID,
            adsetName: adObject.adsetName,
            adsetID: adObject.adsetID,
        },
        inquiries: inquiries,
        consultations: consultations,
    }
    return payload
})


const adAnalyticsSlice = createSlice({
    name: "adAnalytics",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAdAnalytics.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchAdAnalytics.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ""
        })
        builder.addCase(fetchAdAnalytics.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
    }
})

export default adAnalyticsSlice.reducer