/* eslint-disable react/jsx-key */

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

import { useSelector } from 'react-redux'
import { LoadingBlock } from 'components/loading/loading'

export function classNames(...classes) {
    return classes.filter(Boolean).join(` `);
}

export function DailyInquiries() {

    const dailyInquiriesData = useSelector(state => state.dailyInquiries)

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        scales: {
            y: {
                type: 'linear',
                grace: `200`,
                beginAtZero: true
            }

        }
    };

    const labels = dailyInquiriesData.data.labels

    const data = {
        labels,
        datasets: [
            {
                label: 'Manychat',
                data: dailyInquiriesData.data.manychatInquiriesData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                tension: 0.2
            },
            {
                label: 'Leads Database',
                data: dailyInquiriesData.data.metaInquiriesData,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                tension: 0.2
            },
            {
                label: 'Paid Inquiries',
                data: dailyInquiriesData.data.paidInquiriesData,
                borderColor: 'rgb(153, 162, 235)',
                backgroundColor: 'rgba(153, 162, 235, 0.5)',
                tension: 0.2
            },
        ],
    };
    return (
        <div className="h-full w-full">
            {!dailyInquiriesData.loading && <Line options={options} data={data} />}
            {dailyInquiriesData.loading && <LoadingBlock></LoadingBlock>}
        </div>
    )
}

export function DailyInquiriesQuality() {
    const dailyInquiriesData = useSelector(state => state.dailyInquiries)
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            title: {
                display: false,
                text: 'Chart.js Bar Chart - Stacked',
            },
        },
        scales: {
            x: {
                stacked: true,
                beginAtZero: true,
                grace: 200
            },
            y: {
                stacked: true,
                beginAtZero: true,
                grace: 200
            },
        },
    };

    const labels = dailyInquiriesData.data.labels

    const data = {
        labels,
        datasets: [
            {
                label: 'Less than 5 interactions',
                data: dailyInquiriesData.data.coldInquiriesData,
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: 'Less than 25 interactions',
                data: dailyInquiriesData.data.warmInquiriesData,
                backgroundColor: 'rgb(75, 192, 192)',
            },
            {
                label: 'More than 25 interactions',
                data: dailyInquiriesData.data.hotInquiriesData,
                backgroundColor: 'rgb(53, 162, 235)',
            },
        ],
    };

    return (
        <div className="h-full w-full">
            {!dailyInquiriesData.loading && <Bar options={options} data={data} />}
            {dailyInquiriesData.loading && <LoadingBlock></LoadingBlock>}
        </div>
    )
}

import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { useMemo, useState } from 'react'

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <label className="flex gap-x-2 items-baseline">
            <span className="text-gray-700">Search: </span>
            <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 whitespace-nowrap"
                value={value || ``}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </label>
    )
}


function AdQuality({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy
    )

    return (
        <div className="h-full w-full flex flex-col">
            <div className="flex gap-x-2 mb-2 font-bold p-2 items-center">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter} />
            </div>
            <div className="flex flex-1 overflow-x-scroll">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-6 py-3 text-xs text-center text-black uppercase tracking-wider font-bold">
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {rows.map(
                            (row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()} className="px-6 py-4 text-center whitespace-nowrap">{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                </table>
            </div>



        </div>
    )

}

import { adNameCell } from './buttons';

export function AdQualityParent() {
    const dailyInquiriesData = useSelector(state => state.dailyInquiries)
    const data = dailyInquiriesData.data.adQualityData
    const columns = useMemo(
        () => [
            {
                Header: 'Ad ID',
                accessor: 'adID',
            },
            {
                Header: 'Ad Name',
                accessor: 'adName',
                Cell: adNameCell
            },
            {
                Header: 'Total Inquiries',
                accessor: 'totalInquiries',
            },
            {
                Header: 'Cold Inquiries',
                accessor: 'coldInquiries',
            },
            {
                Header: 'Warm Inquiries',
                accessor: 'warmInquiries',
            },
            {
                Header: 'Hot Inquiries',
                accessor: 'hotInquiries',
            },
        ], []
    )
    return (
        <div>
            {!dailyInquiriesData.loading && <AdQuality columns={columns} data={data}></AdQuality>}
            {dailyInquiriesData.loading && <div className="w-full h-80">
                <LoadingBlock></LoadingBlock>
            </div>}
        </div>
    )
}
