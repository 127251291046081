const dayjs = require(`dayjs`);
let localizedFormat = require(`dayjs/plugin/localizedFormat`);
var utc = require(`dayjs/plugin/utc`);
var objectSupport = require("dayjs/plugin/objectSupport");
dayjs.extend(objectSupport);
dayjs.extend(localizedFormat);
dayjs().format();
dayjs.extend(utc);

export default dayjs;
