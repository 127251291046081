const referralsData = [
    {
        name: "Aguilar, Jenelle ",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "PCO",
        referralCode: "StaffJenelle",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027195511_014930/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffJenelle"
    },
    {
        name: "Aquino, Maria Alyssa",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        referralCode: "StaffMaria",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027195858_962948/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffMaria"
    },
    {
        name: "Burgos, Maria Sofia Milagros",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "End-to-End Leader",
        referralCode: "StaffSofia",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027195947_930866/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffSofia"
    },
    {
        name: "Cadiogan, Dianne Kate",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "Triage Nurse",
        referralCode: "NurseDianne",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027200030_651106/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=NurseDianne"
    },
    {
        name: "Cuna, Ivannah Kryztelle",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "PCO",
        referralCode: "StaffVana",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027200118_450807/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffVana"
    },
    {
        name: "Cunanan, Angela Mae",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "Triage Nurse",
        referralCode: "NurseAngela",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027200201_539806/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=NurseAngela"
    },
    {
        name: "Dela Cruz, Jade Barcelona",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "PCO",
        referralCode: "StaffJade",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027200252_328303/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffJade"
    },
    {
        name: "Dela Peña, Jessa",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "Triage Nurse",
        referralCode: "NurseJessa",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027200333_280155",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=NurseJessa"
    },
    {
        name: "Galvez, Shiela Mae",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "PCO",
        referralCode: "StaffShiela",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027200641_441724/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffShiela"
    },
    {
        name: "Hernandez Jiu Sien Chen",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "Decking Leader",
        referralCode: "StaffSien",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027200723_978270/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffSien"
    },
    {
        name: "Mariano, Katherine Dale",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "End-to-End Leader/ Triage Nurse",
        referralCode: "NurseKath",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027200807_867340/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=NurseKath"
    },
    {
        name: "Millado, Sheilla Jane",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        referralCode: "StaffSheilla",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201016_593121/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffSheilla"
    },
    {
        name: "Prodigalidad, Allen",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "PCO",
        referralCode: "StaffAllen",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201053_494850/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffAllen"
    },
    {
        name: "Tagama, Maria Rae",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "PCO",
        referralCode: "StaffMariaRae",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201134_951538/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffMariaRae"
    },
    {
        name: "Vergara, Alexandra",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "PCO",
        referralCode: "StaffAlexandra",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201327_559626/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffAlexandra"
    },
    {
        name: "Villanueva, Efren",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Staff",
        position: "Staff",
        referralCode: "StaffEfren",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201421_720853/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=StaffEfren"
    },
    {
        name: "Allauigan, Richard Miguel Jr., Domantay",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Medical Operations Manager",
        referralCode: "Richard",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201459_069461/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Richard"
    },
    {
        name: "Barrios, Micah Ella Castillo",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "VP of Sales",
        referralCode: "Micah",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201559_748132/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Micah"
    },
    {
        name: "Bayot, Nadine Monina",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Branding Officer",
        referralCode: "Nadine",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201644_998723/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Nadine"
    },
    {
        name: "Bellon, Gabriel Mari Medina",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "VP of Business Development",
        referralCode: "Gab",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201729_308512/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Gab"
    },
    {
        name: "Calamba, Jude",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Finance Officer",
        referralCode: "Jude",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201800_938716/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Jude"
    },
    {
        name: "Chan, Cher Andre",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Technology Officer",
        referralCode: "Cher",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201830_653598/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Cher"
    },
    {
        name: "Comia, Daryn Vladi Caringal",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Human Resources Officer ",
        referralCode: "Vladi",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201830_653598/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Vladi"
    },
    {
        name: "Cuna, Vanica Lorraine Barcenilla",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Executive Assistant",
        referralCode: "Vanica",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027201930_047510/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Vanica"
    },
    {
        name: "Del Valle, Kristal Kaye, Cruz",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Human Resources Officer",
        referralCode: "Kaye",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202010_180537/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Kaye"
    },
    {
        name: "Jamir, Jasper Sauquillo",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Executive Officer/ Chairman",
        referralCode: "Jasper",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202142_513572/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Jasper"
    },
    {
        name: "Jamir, Paula Jane Sauquillo",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Operating Officer",
        referralCode: "Paula",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202218_280386/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Paula"
    },
    {
        name: "Jamir, Vince",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Business Development Officer",
        referralCode: "Vince",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202257_720971/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Vince"
    },
    {
        name: "Mendoza, Sean Red, ",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Technology Officer ",
        referralCode: "Red",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202330_936582/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Red"
    },
    {
        name: "Ogata, Seijiro Bicalan",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Systems and Product Officer ",
        referralCode: "Jiro",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202407_381575/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Jiro"
    },
    {
        name: "Ramos, Shannen Shane Calamba",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "VP of Human Resources",
        referralCode: "Shannen",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202441_410180",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Shannen"
    },
    {
        name: "Realda, Zielle Frances Sibugan",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Sales Officer ",
        referralCode: "Frances",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202527_044901/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Frances"
    },
    {
        name: "Ringor, Regene Gayle Masangcay",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Marketing Officer",
        referralCode: "Regene",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202604_644182/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Regene"
    },
    {
        name: "Serrano, Miguel Franco Batac",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Finance Officer",
        referralCode: "Miguel",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202641_089881/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Miguel"
    },
    {
        name: "Sucgang, Hannah Mae",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Admin & Project Management",
        referralCode: "Mea",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202719_299859/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Mea"
    },
    {
        name: "Tamano, Mayne Ramzelle Gabriel",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Marketing Officer",
        referralCode: "Mayne",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202753_417673/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Mayne"
    },
    {
        name: "Zoleta, Angel lourdes",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Management",
        position: "Chief Information Officer",
        referralCode: "Angel",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20053170--content20221027202831_461825/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Angel"
    },
    {
        name: "Dr. Anna Kristina Pastoral",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocAnna",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocAnna"
    },
    {
        name: "Dr. Michel De Veyra",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Surgeon",
        referralCode: "DocDeVeyra",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocDeVeyra"
    },
    {
        name: "Dr. Eric Ecalnir",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Urologist",
        referralCode: "DocEric",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocEric"
    },
    {
        name: "Dr. Joan Manaloto-Mangubat",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Obstetrician",
        referralCode: "DocJo",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocJo"
    },
    {
        name: "Dr. Joan Elizabeth Chua",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Physician",
        referralCode: "DocJoan",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocJoan"
    },
    {
        name: "Dr. Reginald Manasan",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "DocManasan",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocManasan"
    },
    {
        name: "Dr. Reginaldo Mia",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "DocReg",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocReg"
    },
    {
        name: "Dr. Raponcel Tan",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine - Adult Cardiology",
        referralCode: "DocTan",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocTan"
    },
    {
        name: "Dr. Tech Test",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocTech",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocTech"
    },
    {
        name: "Dr. Tovya Dallo",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocTovya",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocTovya"
    },
    {
        name: "Dr. Dayang Sitti Rohilmina B. Jalad",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocYang",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocYang"
    },
    {
        name: "Dr. Alex Calara",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocAlex",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocAlex"
    },
    {
        name: "Dr. Annette Mia",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Pediatrician",
        referralCode: "DocAna",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocAna"
    },
    {
        name: "Dr. Angelica Gabagat",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocAngelica",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocAngelica"
    },
    {
        name: "Dr. Christian Ceasar Nerpiol",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Ophthalmology",
        referralCode: "DocChristian",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocChristian"
    },
    {
        name: "Dr. Euler Garrote",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Orthopedic Surgery",
        referralCode: "DocEuler",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocEuler"
    },
    {
        name: "Dr. Kharl Guieb",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocKharl",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocKharl"
    },
    {
        name: "Dr. Leonard Manabat",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Otorhinolaryngology Head and Neck Surgery",
        referralCode: "DocLeo",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocLeo"
    },
    {
        name: "Dr. Carlo Luigi Dela Cruz",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "DocLuigi",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocLuigi"
    },
    {
        name: "Dr. Mich Domenden",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocMich",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocMich"
    },
    {
        name: "Dr. Nessa Chan",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocNessa",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocNessa"
    },
    {
        name: "Dr. Nikki Joyce Lagasca",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocNikai",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocNikai"
    },
    {
        name: "Dr. Portia Angela Parilla",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Otorhinolaryngology - Head & Neck Surgery",
        referralCode: "DocPortia",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocPortia"
    },
    {
        name: "Dr. Reynaldo Dakanay",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocRey",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocRey"
    },
    {
        name: "Dr. Jennyleen Roxas",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "DocRoxas",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocRoxas"
    },
    {
        name: "Dr. Sarah Espiritu",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DocSarah",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocSarah"
    },
    {
        name: "Dr. Serianne Soriano",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "DocSerriane",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocSerriane"
    },
    {
        name: "Dr. Maria Carolina Briguela",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "DocCarol",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocCarol"
    },
    {
        name: "Dr. Ramon T. Amador",
        resourceType: "Link",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine - Diebetician",
        referralCode: "DocRam",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DocRam"
    },
    {
        name: "Dr. Anna Kristina Pastoral",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17216920",
        referralLink:
            "https://drive.google.com/file/d/1CIQdDHe3AnJ9dc3NJmOLp8P7li_dnBA2/view?usp=sharing"
    },
    {
        name: "Dr. Michel De Veyra",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Surgeon",
        referralCode: "w17217003",
        referralLink:
            "https://drive.google.com/file/d/1HZxkzF0Vi6lxWEjhabPkNXJ-_InTQBrb/view?usp=sharing"
    },
    {
        name: "Dr. Eric Ecalnir",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Urologist",
        referralCode: "w17216971",
        referralLink:
            "https://drive.google.com/file/d/18lw7naFZPwaO-6BTLWHnGI5dbZWK7cfx/view?usp=sharing"
    },
    {
        name: "Dr. Joan Manaloto-Mangubat",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Obstetrician",
        referralCode: "w17216989",
        referralLink:
            "https://drive.google.com/file/d/1WRYLwt5jWIyacRhLkXIRoqdEPLd1yLkB/view?usp=sharing"
    },
    {
        name: "Dr. Joan Elizabeth Chua",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Physician",
        referralCode: "w17216980",
        referralLink:
            "https://drive.google.com/file/d/1kKAtvLStHfrpSs-ndJ6jvV-tFLYKXAH6/view?usp=sharing"
    },
    {
        name: "Dr. Reginald Manasan",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "w17217027",
        referralLink:
            "https://drive.google.com/file/d/10RR_XhfA__ud-Fykx01xNmcffxVRZb-s/view?usp=sharing"
    },
    {
        name: "Dr. Reginaldo Mia",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "w17217036",
        referralLink:
            "https://drive.google.com/file/d/1MLp2F1tH-aRxtehWzw2a8LCuNv_2j5fc/view?usp=sharing"
    },
    {
        name: "Dr. Raponcel Tan",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine - Adult Cardiology",
        referralCode: "w17216895",
        referralLink:
            "https://drive.google.com/file/d/1f8QPgVXVcR58ZHQQCL9oPv3D0MStLAWi/view?usp=sharing"
    },
    {
        name: "Dr. Tech Test",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17165881",
        referralLink:
            "https://drive.google.com/file/d/1vv5hX9FSKk1eI7_a5jMhfSJZ3qtYeGWH/view?usp=sharing"
    },
    {
        name: "Dr. Tovya Dallo",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17217043",
        referralLink:
            "https://drive.google.com/file/d/10MrKhlclj89pi3z6KulRKylkjZc9ZLAa/view?usp=sharing"
    },
    {
        name: "Dr. Dayang Sitti Rohilmina B. Jalad",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17216941",
        referralLink:
            "https://drive.google.com/file/d/19vko7shhv8ykun8_Zgvyx4duX_9rOR_3/view?usp=sharing"
    },
    {
        name: "Dr. Alex Calara",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17222123",
        referralLink:
            "https://drive.google.com/file/d/1u_nZujQ8z5oVVGc4xZmtvSoXsJS4utSz/view?usp=sharing"
    },
    {
        name: "Dr. Annette Mia",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Pediatrician",
        referralCode: "w17222132",
        referralLink:
            "https://drive.google.com/file/d/1aOHlNAbL5Bk9FkGPnOYmKU3OCApYDYIb/view?usp=sharing"
    },
    {
        name: "Dr. Angelica Gabagat",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17222143",
        referralLink:
            "https://drive.google.com/file/d/1itgDhbK-QClIhamoFhpg87HGsjAG1BBf/view?usp=sharing"
    },
    {
        name: "Dr. Christian Ceasar Nerpiol",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Ophthalmology",
        referralCode: "w17222153",
        referralLink:
            "https://drive.google.com/file/d/1HXNqwjGp2gFMO6Pb5gqxrJBwkc0iQE6s/view?usp=sharing"
    },
    {
        name: "Dr. Euler Garrote",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Orthopedic Surgery",
        referralCode: "w17222163",
        referralLink:
            "https://drive.google.com/file/d/1NwFPYbPAkVDbSEdkTrlyFa3igb_Lur5e/view?usp=sharing"
    },
    {
        name: "Dr. Kharl Guieb",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17222169",
        referralLink:
            "https://drive.google.com/file/d/1FewBwZkJWAkWSBViQAHq_-V9T8qgYfzh/view?usp=sharing"
    },
    {
        name: "Dr. Leonard Manabat",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Otorhinolaryngology Head and Neck Surgery",
        referralCode: "w17222177",
        referralLink:
            "https://drive.google.com/file/d/1cqGGTzprPRkSWNyGllFj1DhR1EI3NQHX/view?usp=sharing"
    },
    {
        name: "Dr. Carlo Luigi Dela Cruz",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "w17222181",
        referralLink:
            "https://drive.google.com/file/d/1VEOAlcCfnNYLqn-upRTAfOGQHvQ830cs/view?usp=sharing"
    },
    {
        name: "Dr. Mich Domenden",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17222190",
        referralLink:
            "https://drive.google.com/file/d/1KVAk1_JK5eOPGuYibKK1p6xunZXRu1_Q/view?usp=sharing"
    },
    {
        name: "Dr. Nessa Chan",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17222229",
        referralLink:
            "https://drive.google.com/file/d/1ugxZRRSj0V3SbN53nPOJ6oaPTIbStQDv/view?usp=sharing"
    },
    {
        name: "Dr. Nikki Joyce Lagasca",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17222240",
        referralLink:
            "https://drive.google.com/file/d/1SokRKnvV0P9Z-fH2aJsiBsqf7lG6CdVc/view?usp=sharing"
    },
    {
        name: "Dr. Portia Angela Parilla",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Otorhinolaryngology - Head & Neck Surgery",
        referralCode: "w17222254",
        referralLink:
            "https://drive.google.com/file/d/1DgFuGdFvoUuvUUX4XKUSv82Omv-wN5F4/view?usp=sharing"
    },
    {
        name: "Dr. Reynaldo Dakanay",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17222259",
        referralLink:
            "https://drive.google.com/file/d/1shogxqeekVViC8amUI3b-QBEqC-ZNk80/view?usp=sharing"
    },
    {
        name: "Dr. Jennyleen Roxas",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "w17222266",
        referralLink:
            "https://drive.google.com/file/d/1fPlz9pXqgkDNffpaDMlmUDX7bZ7hQVoT/view?usp=sharing"
    },
    {
        name: "Dr. Sarah Espiritu",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17222272",
        referralLink:
            "https://drive.google.com/file/d/1GOvhjUkcS4FhT1NpYiVclykH63e4nV6o/view?usp=sharing"
    },
    {
        name: "Dr. Serianne Soriano",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "w17222281",
        referralLink:
            "https://drive.google.com/file/d/1Y2HfOECOuDr863E4SRDgRvivc_RDxsz3/view?usp=sharing"
    },
    {
        name: "Dr. Maria Carolina Briguela",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "w17451392",
        referralLink:
            "https://drive.google.com/file/d/1Su6SZ9h7p-5-nctKxo41jP0HeL_iZM3x/view?usp=sharing"
    },
    {
        name: "Dr. Ramon T. Amador",
        resourceType: "QR Code",
        referralType: "Paid",
        userType: "Doctor",
        position: "Internal Medicine - Diebetician",
        referralCode: "w17451445",
        referralLink:
            "https://drive.google.com/file/d/1bVDlBIIQ0Ke6J35cq3H7tWU5hoK30P1C/view?usp=sharing"
    },
    {
        name: "Dr. Anna Kristina Pastoral",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrAnna",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20220929120809_073202/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrAnna"
    },
    {
        name: "Dr. Michel De Veyra",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Surgeon",
        referralCode: "DrDeVeyra",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20220929121208_524712/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrDeVeyra"
    },
    {
        name: "Dr. Eric Ecalnir",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Urologist",
        referralCode: "DrEric",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20220929121452_944641/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrEric"
    },
    {
        name: "Dr. Joan Manaloto-Mangubat",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Obstetrician",
        referralCode: "DrJo",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221003151343_121727/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrJo"
    },
    {
        name: "Dr. Joan Elizabeth Chua",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Physician",
        referralCode: "DrJoan",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221003151616_891991/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrJoan"
    },
    {
        name: "Dr. Reginald Manasan",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "DrManasan",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221003151749_994850/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrManasan"
    },
    {
        name: "Dr. Reginaldo Mia",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "DrReg",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221003152002_913998/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrReg"
    },
    {
        name: "Dr. Raponcel Tan",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine - Adult Cardiology",
        referralCode: "DrTan",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221003152153_395197/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrTan"
    },
    {
        name: "Dr. Tech Test",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrTech",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221003152427_178165/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrTech"
    },
    {
        name: "Dr. Tovya Dallo",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrTovya",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004154221_781763",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrTovya"
    },
    {
        name: "Dr. Dayang Sitti Rohilmina B. Jalad",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrYang",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004154333_355623/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrYang"
    },
    {
        name: "Dr. Alex Calara",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrAlex",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004154524_796543/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrAlex"
    },
    {
        name: "Dr. Annette Mia",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Pediatrician",
        referralCode: "DrAna",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004154633_693469/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrAna"
    },
    {
        name: "Dr. Angelica Gabagat",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrAngelica",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004154752_128276/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrAngelica"
    },
    {
        name: "Dr. Christian Ceasar Nerpiol",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Ophthalmology",
        referralCode: "DrChristian",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004154921_459145",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrChristian"
    },
    {
        name: "Dr. Euler Garrote",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Orthopedic Surgery",
        referralCode: "DrEuler",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004155037_519191",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrEuler"
    },
    {
        name: "Dr. Kharl Guieb",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrKharl",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004155154_625318/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrKharl"
    },
    {
        name: "Dr. Leonard Manabat",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Otorhinolaryngology Head and Neck Surgery",
        referralCode: "DrLeo",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004155319_936421/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrLeo"
    },
    {
        name: "Dr. Carlo Luigi Dela Cruz",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "DrLuigi",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004155432_070934/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrLuigi"
    },
    {
        name: "Dr. Mich Domenden",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrMich",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004155539_934885/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrMich"
    },
    {
        name: "Dr. Nessa Chan",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrNessa",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004155645_883279/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrNessa"
    },
    {
        name: "Dr. Nikki Joyce Lagasca",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrNikai",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004155741_615764/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrNikai"
    },
    {
        name: "Dr. Portia Angela Parilla",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Otorhinolaryngology - Head & Neck Surgery",
        referralCode: "DrPortia",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004155835_691672/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrPortia"
    },
    {
        name: "Dr. Reynaldo Dakanay",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrRey",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004160019_887559",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrRey"
    },
    {
        name: "Dr. Jennyleen Roxas",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "DrRoxas",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004160123_182680/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrRoxas"
    },
    {
        name: "Dr. Sarah Espiritu",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "DrSarah",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004160246_437688/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrSarah"
    },
    {
        name: "Dr. Serianne Soriano",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "DrSerriane",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004160337_731142",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrSerriane"
    },
    {
        name: "Dr. Maria Carolina Briguela",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "DrCarol",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004160450_878134",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrCarol"
    },
    {
        name: "Dr. Ramon T. Amador",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine - Diebetician",
        referralCode: "DrRam",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221004160537_763811/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DrRam"
    },
    {
        name: "Dr. Anna Kristina Pastoral",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17680770",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20220929121820_541847/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1p9-UVg8gxysCE1aCTjQEF9Llw9i64Xx0/view?usp=sharing"
    },
    {
        name: "Dr. Michel De Veyra",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Surgeon",
        referralCode: "w17680806",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20220929122524_358771/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1AZNNlg2A9CyoYt-Bkl7ybSTnRvDmoYxy/view?usp=sharing"
    },
    {
        name: "Dr. Eric Ecalnir",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Urologist",
        referralCode: "w17714022",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006141653_523389/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1rn8lRP-vYC75HI3RgSZSxwpvXrjXcgN7/view?usp=sharing"
    },
    {
        name: "Dr. Joan Manaloto-Mangubat",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Obstetrician",
        referralCode: "w17714057",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006142057_553601/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1Bsv-qWJ8qALGVKyhTvQZTUfcCyNE5-M0/view?usp=sharing"
    },
    {
        name: "Dr. Joan Elizabeth Chua",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Physician",
        referralCode: "w17714086",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006142211_191292/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1XBhzHfQu4JciFBmF_ymYd0LcaJfdZcpz/view?usp=sharing"
    },
    {
        name: "Dr. Reginald Manasan",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "w17714094",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006142331_440652/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1ydbxjSFMBjJJnNNhncHeBthHDG0qZX5F/view?usp=sharing"
    },
    {
        name: "Dr. Reginaldo Mia",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "w17714102",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006142434_131186/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1kIHj0Qsx1vBNPIIhcAlTMiTFlGr9MzSx/view?usp=sharing"
    },
    {
        name: "Dr. Raponcel Tan",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine - Adult Cardiology",
        referralCode: "w17714113",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006142546_986917/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1v3kZy2f4vqOi3VnpBwDrBL-Eg0OeyUAs/view?usp=sharing"
    },
    {
        name: "Dr. Tech Test",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17714141",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006143200_811698/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1j2NrvRx6Cl2XSpjn0bjyO8VUxAuHUOuw/view?usp=sharing"
    },
    {
        name: "Dr. Tovya Dallo",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17714147",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006143257_093821/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1pbDVVGRKc_pYIxLoTOZPc-3-tEj1xtuY/view?usp=sharing"
    },
    {
        name: "Dr. Dayang Sitti Rohilmina B. Jalad",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17714150",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006143401_710889/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1sCJ5mTfnWTPfbci_9TLvKe6W1Mc5WCgN/view?usp=sharing"
    },
    {
        name: "Dr. Alex Calara",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17714155",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006143531_919641/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1y-iACQeAaTyoB1rBhBO28P43laNSzeiy/view?usp=sharing"
    },
    {
        name: "Dr. Annette Mia",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Pediatrician",
        referralCode: "w17714199",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006144300_787929/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1HyMER1Oa-BaMcAbl7HJwdNVbwtRiYqF0/view?usp=sharing"
    },
    {
        name: "Dr. Angelica Gabagat",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17714208",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006144416_796516/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1_QVTNZ57V7LfNccOqwb-WgJ8Wh5KRpI-/view?usp=sharing"
    },
    {
        name: "Dr. Christian Ceasar Nerpiol",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Ophthalmology",
        referralCode: "w17714213",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006144530_141498/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/15r18TMhfnT4ecu2zJdsb4AML1pbNLuPe/view?usp=sharing"
    },
    {
        name: "Dr. Euler Garrote",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Orthopedic Surgery",
        referralCode: "w17714225",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006144656_379540/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1vXVvFxZPqoII26i37k4WYEhaU6G0-cCH/view?usp=sharing"
    },
    {
        name: "Dr. Kharl Guieb",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17714239",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006144814_301516/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1g2HyiyP1W-eCz3egf9AcZ2ehfm28uG3G/view?usp=sharing"
    },
    {
        name: "Dr. Leonard Manabat",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Otorhinolaryngology Head and Neck Surgery",
        referralCode: "w17714249",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221006145010_394344/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1i-HkDRdOr4IIks9RGdym5ReI3lJ5SywW/view?usp=sharing"
    },
    {
        name: "Dr. Carlo Luigi Dela Cruz",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "w17717844",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007065830_869996/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1WwFL2w-IY9gbGu1i7UAEVMCIXDkuggyv/view?usp=sharing"
    },
    {
        name: "Dr. Mich Domenden",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17717855",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007070150_292687/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/16cNk91FwECxCtNy35G7zpehac2HC9jiB/view?usp=sharing"
    },
    {
        name: "Dr. Nessa Chan",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17717862",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007070312_483321/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1U7B1KEefh6aRJEhbinN6_hgg8ywdFXNy/view?usp=sharing"
    },
    {
        name: "Dr. Nikki Joyce Lagasca",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17717867",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007070758_787891/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1yCvtRjaEY_6oF_tki_vTj7v-4A31qvuc/view?usp=sharing"
    },
    {
        name: "Dr. Portia Angela Parilla",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Otorhinolaryngology - Head & Neck Surgery",
        referralCode: "w17717876",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007071118_394775/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1qJd3yFyGpmxNU5DuESzim0Pucjf1WSXD/view?usp=sharing"
    },
    {
        name: "Dr. Reynaldo Dakanay",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17717889",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007071300_342441/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1zpRvejP0tZKt8bLPm9Ne2wrJZ-Dabt9A/view?usp=sharing"
    },
    {
        name: "Dr. Jennyleen Roxas",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "w17717901",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007071623_230376/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1vjFuvxOD-UCyVpahX3QHuHq1YzemGEZC/view?usp=sharing"
    },
    {
        name: "Dr. Sarah Espiritu",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "General Practitioner",
        referralCode: "w17717909",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007071748_088908/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1OMJ2nRpk7UjwUxYUbyRW0XzTVUFhQQRF/view?usp=sharing"
    },
    {
        name: "Dr. Serianne Soriano",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine",
        referralCode: "w17717913",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007071923_485144/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1NRQKU4tgqEngKGQh9edRLJq0uXySEFk1/view?usp=sharing"
    },
    {
        name: "Dr. Maria Carolina Briguela",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Psychiatry",
        referralCode: "w17717918",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007072033_585397/edit#messenger_code_settings",
        referralLink:
            "https://drive.google.com/file/d/1EYhBEKrVy98W0g0MW5Qbqmipj7wMmmLk/view?usp=sharing"
    },
    {
        name: "Dr. Ramon T. Amador",
        resourceType: "QR Code",
        referralType: "Organic",
        userType: "Doctor",
        position: "Internal Medicine - Diebetician",
        referralCode: "w17717924",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007072202_954935/edit#messenger_code_settings",
        referralLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--content20221007072202_954935/edit#messenger_code_settings"
    },
    {
        name: "Alliah Joy Pages",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "AlliahPages",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221126125746_198476",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=AlliahPages"
    },
    {
        name: "Patrisha Deanne Santos",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "Pat",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221126125946_175256",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Pat"
    },
    {
        name: "Sandy",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "Sandy",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221126130134_384162",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Sandy"
    },
    {
        name: "Erykka Jazmeen Nolasco",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "Jaz",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221126130221_852343",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Jaz"
    },
    {
        name: "Kim Vidal",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "KimVidal",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221126130300_361491",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=KimVidal"
    },
    {
        name: "Chriselle Soco",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "ChriselleSoco",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221126130357_627677",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=ChriselleSoco"
    },
    {
        name: "Justine Santos Dy",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "DyMarxian",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221126130438_980829",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=DyMarxian"
    },
    {
        name: "Rhen De Guzman",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "Rhen",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221205174049_266215",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Rhen"
    },
    {
        name: "Viktoria De Leon",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "Viktoria",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221205174123_105617",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Viktoria"
    },
    {
        name: "Emiline",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "teybolforwan",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221213112447_654916/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=teybolforwan"
    },
    {
        name: "Jomayco Tomuran",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "ChubbyBearMD",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221205180654_666683",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=ChubbyBearMD"
    },
    {
        name: "Tineelicious",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "Tineelicious",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221207163449_898722",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=Tineelicious"
    },
    {
        name: "Cel Martinez",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "marcelinedocix",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221213112540_795337/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=marcelinedocix"
    },
    {
        name: "Micah",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "MicahTEST",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=ChubbyBearMD"
    },
    {
        name: "Jan Toby Cruz",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "tobycruzz",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221213112642_064251/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=tobycruzz"
    },
    {
        name: "Ezro John Pariñas",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Tiktok",
        position: "Partner Influencer",
        referralCode: "ezrojohn_rmt",
        manychatLink:
            "https://manychat.com/fb101907765279119/cms/files/18606196--20291075--content20221213112727_445536/edit#setup",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=ezrojohn_rmt"
    },
    {
        name: "Website",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Marketing",
        position: "Website",
        referralCode: "ekmcwebsite",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=ekmcwebsite"
    },
    {
        name: "Tiktok",
        resourceType: "Link",
        referralType: "Organic",
        userType: "Marketing",
        position: "Tiktok",
        referralCode: "ekonsultamc",
        referralLink: "https://m.me/banyuhay.ekonsulta?ref=ekonsultamc"
    }
];

export default referralsData;
