import image from "media/graphics/male-female-doctor.png";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useFirebase } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingCircle from "components/graphics/loadingCircle";
function Login() {
    const [isLogging, setIsLogging] = useState(false);
    const [loginError, setLoginError] = useState("");
    let navigate = useNavigate();
    const firebase = useFirebase();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm();
    const onSubmit = (data) => {
        setIsLogging(true);
        firebase
            .login({
                email: data.email,
                password: data.password,
            })
            .then((userPackage) => {
                let uid = userPackage.user.user.uid;
                let accessToken = userPackage.user.user._delegate.accessToken;
                let url =
                    process.env.REACT_APP_DEVELOPMENT == `TRUE`
                        ? `${process.env.REACT_APP_DEV_API_DOMAIN}/login`
                        : `${process.env.REACT_APP_PROD_API_DOMAIN}/login`;
                let payload = {
                    idToken: accessToken,
                    uid: uid,
                };
                let options = {
                    withCredentials: true,
                    headers: {
                        "Content-Type": `application/json;charset=UTF-8`,
                        "Access-Control-Allow-Origin":
                            process.env.REACT_APP_DEVELOPMENT == `TRUE`
                                ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}`
                                : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
                    },
                };
                axios
                    .post(url, payload, options)
                    .then(() => {
                        setIsLogging(false);
                        navigate("/home");
                    })
                    .catch((err) => {
                        setIsLogging(false);
                        console.log(err.message);
                    });
            })
            .catch((err) => {
                setIsLogging(false);
                if (err.code == "auth/wrong-password") {
                    setLoginError("Your email address and/or password is incorrect.");
                } else if (err.code == "auth/user-not-found") {
                    setLoginError("User not found! Email address may be incorrect.");
                }
            });
    };
    return (
        <div className="flex items-center justify-center antialiased min-h-screen min-w-screen bg-login bg-no-repeat bg-cover">
            <div className="flex items-center justify-center bg-blue-500 lg:max-w-4xl max-w-lg lg:h-96 rounded-lg shadow-lg mx-5 md:mx-10">
                <div className="h-full w-full grid grid-cols-1 gap-3 lg:grid-cols-2 p-6  ">
                    <div className="flex flex-col  relative text-white">
                        <div>
                            <h1 className="font-bold text-4xl tracking-wide">E-Konsulta Medical Clinic</h1>
                            <p className="text-gray-200 text-sm">Welcome to EKMC's very own Clinic Management System!</p>
                        </div>
                        <div className="flex flex-grow justify-center items-center margin-0">
                            <div className="">
                                <img src={image} alt="CMS Doctors" className="max-h-52"></img>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <form className="bg-white rounded-lg flex flex-col gap-3 px-7 py-6 w-full" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="email" id="email" className="font-bold">
                                    Email
                                </label>
                                <input
                                    {...register("email", { required: true })}
                                    id="email"
                                    type="text"
                                    placeholder="Your email"
                                    className="rounded-lg ring-1 ring-gray-300 outline-none focus:ring-2 focus:ring-blue-400 border-0"
                                ></input>
                                {errors.email?.type === "required" && <span className="text-red-500 text-left ml-2 text-sm">*Email is required</span>}
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="password" id="password" className="font-bold">
                                    Password
                                </label>
                                <input
                                    {...register("password", { required: true })}
                                    id="password"
                                    type="password"
                                    placeholder="Your email"
                                    className="rounded-lg ring-1 ring-gray-300 outline-none focus:ring-2 focus:ring-blue-400 border-0"
                                ></input>
                                {errors.password && <span className="text-red-500 text-left ml-2 text-sm font-bold">*Password is required</span>}
                                {loginError != "" && <span className="text-red-500 text-left ml-2 text-sm font-bold">{loginError}</span>}
                            </div>
                            <div className="flex justify-between items-center mt-5">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="bg-primary text-white rounded-lg py-2 px-6 cursor-pointer flex items-center font-bold space-x-2"
                                >
                                    {isLogging && (
                                        <div className="h-4 w-4">
                                            <LoadingCircle></LoadingCircle>
                                        </div>
                                    )}
                                    {isLogging ? <span>Logging in...</span> : <span>Login</span>}
                                </button>
                                <span className="text-primary font-semibold text-right text-sm pr-1 cursor-pointer ">Forgot password?</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
