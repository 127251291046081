
import Header from 'components/header/header'
import { useState, useEffect } from 'react'
import { DateRangeButton } from '../buttons'
import { fetchLeads } from 'features/analytics/leadsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DailyLeadsParent, PerShiftLeadsParent } from './leads-charts'
import dayjs from 'scripts/dayjs-setup'

function Leads() {
    const leadsData = useSelector(state => state.paidLeads)
    const dispatch = useDispatch()
    let [analaysisDateRange, setAnalysisDateRange] = useState([dayjs({ year: dayjs().year(), month: dayjs().month(), date: dayjs().date() - 6 }).toDate(), dayjs({ year: dayjs().year(), month: dayjs().month(), date: dayjs().date() + 1 }).subtract(1, 'second').toDate()]);

    useEffect(() => {
        if (leadsData.data.length == 0) {
            dispatch(fetchLeads({
                start: analaysisDateRange[0], end: analaysisDateRange[1]
            }))
        }
    }, [])

    useEffect(() => {
        dispatch(fetchLeads({
            start: analaysisDateRange[0], end: analaysisDateRange[1]
        }))
    }, [analaysisDateRange])

    return (
        <div className="h-full min-h-screen w-full max-w-screen antialiased relative">
        <Header></Header>
        <div className="flex flex-1 relative z-1 bg-white">
            <div className="flex w-full flex-col gap-4 p-4 lg:px-40">
                <div className="flex justify-end w-full">
                    <div className="flex bg-primary p-3 rounded-lg shadow-lg lg:mr-0">
                        <div className="bg-white p-2 rounded-sm flex items-center justify-center font-bold ">
                            <DateRangeButton value={analaysisDateRange} onChange={setAnalysisDateRange}></DateRangeButton>
                        </div>
                    </div>
                    <div className="hidden md:flex items-center justify-center mx-4 lg:mx-10">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                        </svg>
                    </div>
                </div>
                <div className="bg-primary p-5 flex flex-col rounded-lg gap-2">
                    <h2 className="text-4xl font-bold text-white ">
                        Daily Leads Counters 
                    </h2>
                    <div className="w-full px-10 py-7 lg:h-[40rem] bg-white rounded-lg shadow-lg mx-auto my-auto">
                       <DailyLeadsParent></DailyLeadsParent>
                    </div>
                </div>
                <div className="bg-primary p-5 flex flex-col rounded-lg gap-2">
                    <h2 className="text-4xl font-bold text-white ">
                        Per Shift Leads Counters 
                    </h2>
                    <div className="w-full px-10 py-7 lg:h-[40rem] bg-white rounded-lg shadow-lg mx-auto my-auto">
                            <PerShiftLeadsParent></PerShiftLeadsParent>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Leads