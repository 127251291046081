import Header from "components/header/header";

import {useState, useEffect} from "react";
import {DateRangeButton} from "components/buttons/dateRangeButton";
import {fetchConsultations} from "features/patients/consultations/consultationsSlice";
import {useDispatch, useSelector} from "react-redux";
import {ConsultationsParent} from "./consultations-table";
import dayjs from "scripts/dayjs-setup";

export default function Consultations() {
    const dailyInquiriesData = useSelector((state) => state.paidLeads);
    const dispatch = useDispatch();
    let [analaysisDateRange, setAnalysisDateRange] = useState([
        dayjs({year: dayjs().year(), month: dayjs().month(), date: dayjs().date() - 1}).toDate(),
        dayjs({year: dayjs().year(), month: dayjs().month(), date: dayjs().date() + 1})
            .subtract(1, "second")
            .toDate(),
    ]);

    useEffect(() => {
        if (dailyInquiriesData.data.length == 0) {
            dispatch(
                fetchConsultations({
                    start: analaysisDateRange[0],
                    end: analaysisDateRange[1],
                })
            );
        }
    }, []);

    useEffect(() => {
        dispatch(
            fetchConsultations({
                start: analaysisDateRange[0],
                end: analaysisDateRange[1],
            })
        );
    }, [analaysisDateRange]);
    return (
        <div className="relative w-full h-full min-h-screen antialiased max-w-screen">
            <Header></Header>
            <div className="relative flex flex-1 bg-white z-1">
                <div className="flex flex-col w-full gap-4 p-4">
                    <div className="flex justify-end w-full">
                        <div className="flex p-3 rounded-lg shadow-lg bg-primary lg:mr-0">
                            <div className="flex items-center justify-center p-2 font-bold bg-white rounded-sm ">
                                <DateRangeButton value={analaysisDateRange} onChange={setAnalysisDateRange}></DateRangeButton>
                            </div>
                        </div>
                        <div className="items-center justify-center hidden mx-4 md:flex lg:mx-10">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-primary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 p-5 rounded-lg bg-primary">
                        <h2 className="text-4xl font-bold text-white ">Patient Consultations</h2>
                        <div className="px-10 py-5 w-full max-h-[40rem] overflow-y-scroll bg-white rounded-lg shadow-lg">
                            <ConsultationsParent></ConsultationsParent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
