import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

import { useSelector } from "react-redux";
import { LoadingBlock } from "components/loading/loading";

function DailyLeads({ leadsData }) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: "Daily Leads Charts",
            },
        },
    };
    const data = {
        labels: leadsData.daily.labels,
        datasets: [
            {
                label: "Total Meta Leads",
                data: leadsData.daily.totalLeads,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Manychat Leads",
                data: leadsData.daily.manychatLeads,
                borderColor: "rgb(255, 20, 20)",
                backgroundColor: "rgba(255, 20, 20, 0.5)",
            },
            {
                label: "Meta Organic Leads",
                data: leadsData.daily.organicLeads,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "Meta Paid Leads",
                data: leadsData.daily.paidLeads,
                borderColor: "rgb(153, 232, 40)",
                backgroundColor: "rgba(153, 232, 40, 0.5)",
            },
        ],
    };
    return <Line options={options} data={data} />;
}

function PerShiftLeads({ leadsData }) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: "Per Shift Leads Charts",
            },
        },
    };
    const data = {
        labels: leadsData.daily.labels,
        datasets: [
            {
                label: "AM Total Leads",
                data: leadsData.am.totalLeads,
                borderColor: "rgb(255, 120, 132)",
                backgroundColor: "rgba(255, 120, 132, 0.5)",
            },
            {
                label: "AM Organic Leads",
                data: leadsData.am.organicLeads,
                borderColor: "rgb(53, 140, 235)",
                backgroundColor: "rgba(53, 140, 235, 0.5)",
            },
            {
                label: "AM Paid Leads",
                data: leadsData.am.paidLeads,
                borderColor: "rgb(200, 255, 200)",
                backgroundColor: "rgba(200, 255, 200, 0.5)",
            },
            {
                label: "PM Total Leads",
                data: leadsData.pm.totalLeads,
                borderColor: "rgb(255, 99, 100)",
                backgroundColor: "rgba(255, 99, 100, 0.5)",
            },
            {
                label: "PM Organic Leads",
                data: leadsData.pm.organicLeads,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "PM Paid Leads",
                data: leadsData.pm.paidLeads,
                borderColor: "rgb(153, 232, 40)",
                backgroundColor: "rgba(153, 232, 40, 0.5)",
            },
        ],
    };
    return <Line options={options} data={data} />;
}

export function DailyLeadsParent() {
    const leadsData = useSelector((state) => state.leads);
    return (
        <div>
            {!leadsData.loading && (
                <div className="w-full lg:h-[36rem]">
                    <DailyLeads leadsData={leadsData.data}></DailyLeads>
                </div>
            )}
            {leadsData.loading && (
                <div className="w-full lg:h-[36rem]">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}

export function PerShiftLeadsParent() {
    const leadsData = useSelector((state) => state.leads);
    return (
        <div>
            {!leadsData.loading && (
                <div className="w-full lg:h-[36rem]">
                    <PerShiftLeads leadsData={leadsData.data}></PerShiftLeads>
                </div>
            )}
            {leadsData.loading && (
                <div className="w-full lg:h-[36rem]">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}
