import {configureStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import {firebaseReducer, actionTypes as rrfActionTypes, getFirebase} from "react-redux-firebase";
import {createFirestoreInstance, firestoreReducer, constants as rfConstants} from "redux-firestore";
import {firebase} from "scripts/firebase-setup";

import prescriptionFormReducer from "features/medical-documents/prescription-form/prescriptionFormSlice";
import medCertReducer from "features/medical-documents/med-cert/medCertSlice";
import labRequestReducer from "features/medical-documents/lab-request/labRequestSlice";
import referralFormReducer from "features/medical-documents/referral-form/referralFormSlice";
import clinicalAbstractReducer from "features/medical-documents/clinical-abstract/clinicalAbstractSlice";
import myFilesReducer from "features/medical-documents/my-files/myFilesSlice";
import dailyInquiriesReducer from "features/analytics/dailyInquiriesSlice";
import documentsReducer from "features/patients/documents/documentsSlice";
import referralsReducer from "features/dashboard/referrals/referralsSlice";
import followupsDashboardReducer from "features/dashboard/follow-ups/followupsDashboardSlice";
import paidLeadsReducer from "features/analytics/paidLeadsSlice";
import adAnalyticsReducer from "features/analytics/adAnalyticsSlice";
import paidPatientsReducer from "features/analytics/paidPatientsSlice";
import leadsReducer from "features/analytics/leadsSlice";
import consultationsReducer from "features/patients/consultations/consultationsSlice";
const rrfConfig = {
    userProfile: "cms-users",
    useFirestoreForProfile: true,
};

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    myFiles: myFilesReducer,
    referrals: referralsReducer,
    documents: documentsReducer,
    medCert: medCertReducer,
    prescriptionForm: prescriptionFormReducer,
    labRequest: labRequestReducer,
    referralForm: referralFormReducer,
    clinicalAbstract: clinicalAbstractReducer,
    dailyInquiries: dailyInquiriesReducer,
    followupsDashboard: followupsDashboardReducer,
    paidLeads: paidLeadsReducer,
    adAnalytics: adAnalyticsReducer,
    paidPatients: paidPatientsReducer,
    leads: leadsReducer,
    consultations: consultationsReducer,
});
const initialState = {};

export const reduxStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    // just ignore every redux-firebase and react-redux-firebase action type
                    ...Object.keys(rfConstants.actionTypes).map((type) => `${rfConstants.actionsPrefix}/${type}`),
                    ...Object.keys(rrfActionTypes).map((type) => `@@reactReduxFirebase/${type}`),
                ],
                ignoredPaths: ["firebase", "firestore"],
            },
            thunk: {
                extraArgument: {
                    getFirebase,
                },
            },
        }),
    preloadedState: initialState,
    devTools: process.env.REACT_APP_DEVELOPMENT == `FALSE` ? false : true,
});

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: reduxStore.dispatch,
    createFirestoreInstance,
};
