

export function LoadingPage() {
    return (
        <div className="flex bg-primary min-h-screen h-full justify-center items-center">
            <div className="flex justify-center items-center text-white">
                <div className="flex flex-col gap-4">
                    <div className="spinner-border animate-spin inline-block w-16 h-16 border-4 rounded-full" role="status">
                    </div>
                    <span className="">Loading...</span>
                </div>

            </div>
        </div>
    )
}

export function LoadingBlock() {
    return (
        <div className="flex bg-primary w-full h-full justify-center items-center">
            <div className="flex justify-center items-center text-white">
                <div className="flex flex-col gap-4">
                    <div className="spinner-border animate-spin inline-block w-16 h-16 border-4 rounded-full" role="status">
                    </div>
                    <span className="">Loading...</span>
                </div>

            </div>
        </div>
    )
}


export default LoadingPage