import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    loading: true,
    data: [],
    error: "",
};

export const fetchMyFiles = createAsyncThunk("myFiles/fetchMyFiles", async (doctor) => {
    let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/medical-documents/list`;
    if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
        url = `${process.env.REACT_APP_DEV_API_DOMAIN}/medical-documents/list`;
    }
    let payload = {
        doctor: doctor,
    };
    const config = {
        headers: {
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin":
                process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
        },
        withCredentials: true,
    };
    const response = await axios.post(url, payload, config);
    return response.data.data;
});

const myFilesSlice = createSlice({
    name: "myFiles",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchMyFiles.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMyFiles.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(fetchMyFiles.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default myFilesSlice.reducer;
