const backgroundColors = ["rgb(255, 99, 132, 0.5)", "rgb(75, 25, 192, 0.5)", "rgb(53, 162, 235, 0.5)", "rgb(53, 255, 43, 0.5)", "rgb(253, 255, 43, 0.5)"];

const borderColors = ["rgb(255, 99, 132, 1)", "rgb(75, 25, 192, 1)", "rgb(53, 162, 235, 1)", "rgb(53, 255, 43, 1)", "rgb(253, 255, 43, 1)"];

function getRandomColors(count) {
    let backgrounds = [];
    let borders = [];
    for (let i = 0; i < count; i++) {
        let index = Math.floor(Math.random() * backgroundColors.length);
        backgrounds.push(backgroundColors[index]);
        borders.push(borderColors[index]);
    }
    return [backgroundColors, borderColors];
}

export function getRandomBackgroundColor() {
    let index = Math.floor(Math.random() * backgroundColors.length);
    return backgroundColors[index];
}
export default getRandomColors;
