import { useState } from 'react'
import { useNavigate } from "react-router-dom";

import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'

import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'

import { AiOutlineDown } from 'react-icons/ai'
import DefaultProfilePicture from 'media/graphics/default-profilepicture.png'

function Userbutton() {
    const firebase = useFirebase()
    let navigate = useNavigate();
    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()

    const profile = useSelector(state => state.firebase.profile)

    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-end',
    })

    return (
        <Popover className="">
            <Popover.Button ref={setReferenceElement} className="flex items-center justify-center py-2 px-5 font-bold bg-white shadow-lg rounded-xl border border-blue-300">
                <img src={DefaultProfilePicture} alt="Profile Picture" className="h-5 w-5 mr-2"></img>
                <span>
                    {profile && profile.displayName ? profile.displayName.split(" ")[0] !== "Dr." ? profile.displayName.split(" ")[0] : `${profile.displayName.split(" ")[0]} ${profile.displayName.split(" ")[1]}` : "User"}
                </span>
                <AiOutlineDown className="h-4 w-5 ml-2">
                </AiOutlineDown>
            </Popover.Button>
            <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}>
                {() => (
                    <div className="bg-white flex flex-col p-3 rounded-lg shadow-lg mt-1 border-2 border-primary">
                        <div className="text-left font-semibold">
                            {profile && profile.displayName ? profile.displayName : "User"}
                        </div>
                        <div className="text-left italic font-light text-gray-500">
                            {profile && profile.position ? profile.position : "Role"}
                        </div>
                        <div className="border border-black w-full my-2">
                        </div>
                        <button className=" text-primary text-left font-bold" onClick={() => {
                            firebase.logout().then(() => {
                                navigate('/login')
                            })
                        }}>
                            Sign Out
                        </button>
                    </div>

                )}
            </Popover.Panel>
        </Popover>
    )
}

export default Userbutton