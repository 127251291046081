import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    prescriptionFormPayload: {
        documentID: "",
        dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
        patientName: "",
        patientAge: "0",
        patientSex: "M",
        address: "",
        notes: "",
    }
}

export const prescriptionFormSlice = createSlice({
    name: 'prescriptionForm',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            state.prescriptionFormPayload = action.payload
        },
        clear: (state) => {
            state.prescriptionFormPayload = initialState.prescriptionFormPayload
        }
    }
})

export const { set, clear } = prescriptionFormSlice.actions

export default prescriptionFormSlice.reducer