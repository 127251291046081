import { DateRangeButton } from "components/buttons/dateRangeButton";
import Header from "components/header/header";
import dayjs from "scripts/dayjs-setup";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchReferrals } from "features/dashboard/referrals/referralsSlice";
import {
    LineChartParent,
    PieChartParent,
    ReferredPatientsParent,
    ReferredTiktokPatientsParent,
    SummaryReferredPatientsParent,
    SummaryReferredTiktokPatientsParent
} from "./referralsCharts";

export default function Referrals() {
    const dispatch = useDispatch();
    let [analaysisDateRange, setAnalysisDateRange] = useState([
        dayjs({ year: dayjs().year(), month: dayjs().month(), date: dayjs().date() - 6 }).toDate(),
        dayjs({ year: dayjs().year(), month: dayjs().month(), date: dayjs().date() + 1 })
            .subtract(1, "second")
            .toDate()
    ]);

    useEffect(() => {
        dispatch(
            fetchReferrals({
                start: analaysisDateRange[0],
                end: analaysisDateRange[1]
            })
        );
    }, [analaysisDateRange]);

    return (
        <div className="relative w-full h-full min-h-screen antialiased max-w-screen">
            <Header></Header>
            <div className="relative flex flex-1 w-full bg-white z-1">
                <div className="flex flex-col w-full gap-4 p-4 mx-10 lg:mx-20">
                    <div className="flex justify-end w-full">
                        <div className="flex p-3 rounded-lg shadow-lg bg-primary lg:mr-0">
                            <div className="flex items-center justify-center p-2 font-bold bg-white rounded-sm ">
                                <DateRangeButton
                                    value={analaysisDateRange}
                                    onChange={setAnalysisDateRange}
                                ></DateRangeButton>
                            </div>
                        </div>
                        <div className="items-center justify-center hidden mx-4 md:flex lg:mx-10">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-primary"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-row w-full h-[36rem] bg-blue-400 rounded-xl shadow-xl p-5 gap-5 ">
                        <div className="w-2/5 h-full p-5 mx-auto my-auto overflow-auto bg-white rounded-lg shadow-lg">
                            <PieChartParent></PieChartParent>
                        </div>
                        <div className="w-3/5 h-full p-5 mx-auto my-auto overflow-auto bg-white rounded-lg shadow-lg">
                            <LineChartParent></LineChartParent>
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-5 p-5 bg-blue-400 shadow-xl min-h-auto rounded-xl">
                        <h2 className="text-4xl font-bold text-white ">Referrals Tracker</h2>
                        <div className="w-full h-full px-10 bg-white rounded-lg shadow-lg py-7">
                            <ReferredPatientsParent></ReferredPatientsParent>
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-5 p-5 bg-blue-400 shadow-xl min-h-auto rounded-xl">
                        <h2 className="text-4xl font-bold text-white ">Referrers Summary</h2>
                        <div className="w-full h-full px-10 bg-white rounded-lg shadow-lg py-7">
                            <SummaryReferredPatientsParent></SummaryReferredPatientsParent>
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-5 p-5 bg-blue-400 shadow-xl min-h-auto rounded-xl">
                        <h2 className="text-4xl font-bold text-white ">Tiktok Referrals Tracker</h2>
                        <div className="w-full h-full px-10 bg-white rounded-lg shadow-lg py-7">
                            <ReferredTiktokPatientsParent></ReferredTiktokPatientsParent>
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-5 p-5 bg-blue-400 shadow-xl min-h-auto rounded-xl">
                        <h2 className="text-4xl font-bold text-white ">Tiktok Referrers Summary</h2>
                        <div className="w-full h-full px-10 bg-white rounded-lg shadow-lg py-7">
                            <SummaryReferredTiktokPatientsParent></SummaryReferredTiktokPatientsParent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
