import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    medCertPayload: {
        documentID: "",
        dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
        patientName: "",
        patientAge: "0",
        patientSex: "M",
        addressBuildingStreet: "",
        addressCityProvince: "",
        chiefComplaint: "",
        impressions: "",
        recommendations: ""
    }
}

export const medCertSlice = createSlice({
    name: 'medCert',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            state.medCertPayload = action.payload
        },
        clear: (state) => {
            state.medCertPayload = initialState.medCertPayload
        }
    }
})

export const { set, clear } = medCertSlice.actions

export default medCertSlice.reducer