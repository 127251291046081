const adsData = [
    {
        adName: "[EKMC 1] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 1] Acquisition | Smart - 125/day - AM SCHEDULE (6am-3pm)",
        campaignID: "23849911631620542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911631630542",
        adID: "23849911631650542",
    },
    {
        adName: "[EKMC 2] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 2] Acquisition | Smart - 225/day - PM SCHEDULE (2pm-11pm)",
        campaignID: "23849911634710542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911634720542",
        adID: "23849911634690542",
    },
    {
        adName: "[EKMC 2] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 2] Acquisition | Smart - 225/day - PM SCHEDULE (2pm-11pm)",
        campaignID: "23849911634710542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911634720542",
        adID: "23849911634700542",
    },
    {
        adName: "[EKMC 3] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 3] AM Acquisition | Smart - 200/day (6-3PM 7.30 bid cap) Multiple Ad Creatives",
        campaignID: "23849911746250542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911746270542",
        adID: "23849911746300542",
    },
    {
        adName: "[EKMC 3] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 3] AM Acquisition | Smart - 200/day (6-3PM 7.30 bid cap) Multiple Ad Creatives",
        campaignID: "23849911746250542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911746270542",
        adID: "23849911746310542",
    },
    {
        adName: "[EKMC 3] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 3] AM Acquisition | Smart - 200/day (6-3PM 7.30 bid cap) Multiple Ad Creatives",
        campaignID: "23849911746250542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911746270542",
        adID: "23849911746320542",
    },
    {
        adName: "[EKMC 3] Super Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 3] AM Acquisition | Smart - 200/day (6-3PM 7.30 bid cap) Multiple Ad Creatives",
        campaignID: "23849911746250542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911746270542",
        adID: "23849911746330542",
    },
    {
        adName: "[EKMC 4] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 4] PM Acquisition | Smart - 375/day - (2-11PM 6 bid cap) Multiple Ad Creatives",
        campaignID: "23849911867420542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911867480542",
        adID: "23849911867400542",
    },
    {
        adName: "[EKMC 4] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 4] PM Acquisition | Smart - 375/day - (2-11PM 6 bid cap) Multiple Ad Creatives",
        campaignID: "23849911867420542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911867480542",
        adID: "23849911867430542",
    },
    {
        adName: "[EKMC 4] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 4] PM Acquisition | Smart - 375/day - (2-11PM 6 bid cap) Multiple Ad Creatives",
        campaignID: "23849911867420542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911867480542",
        adID: "23849911867460542",
    },
    {
        adName: "[EKMC 4] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 4] PM Acquisition | Smart - 375/day - (2-11PM 6 bid cap) Multiple Ad Creatives",
        campaignID: "23849911867420542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911867480542",
        adID: "23849911867470542",
    },
    {
        adName: "[EKMC 5] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932066290542",
    },
    {
        adName: "[EKMC 5] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932066310542",
    },
    {
        adName: "[EKMC 5] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932066320542",
    },
    {
        adName: "[EKMC 5] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932066330542",
    },
    {
        adName: "[EKMC 5] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932066340542",
    },
    {
        adName: "[EKMC 5] MedCert Verification",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932071230542",
    },
    {
        adName: "[EKMC 5] 3 Easy Steps",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932072540542",
    },
    {
        adName: "[EKMC 6] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 6] Acquisition | BPO - 115/day (AM & PM shift ad set)",
        campaignID: "23849945890190542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23849945890210542",
        adID: "23849945890220542",
    },
    {
        adName: "[EKMC 6] MedCert 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 6] Acquisition | BPO - 115/day (AM & PM shift ad set)",
        campaignID: "23849945890190542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849945890200542",
        adID: "23849945890230542",
    },
    {
        adName: "[EKMC 6] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 6] Acquisition | BPO - 115/day (AM & PM shift ad set)",
        campaignID: "23849945890190542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849945890200542",
        adID: "23849945908580542",
    },
    {
        adName: "[EKMC 6] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 6] Acquisition | BPO - 115/day (AM & PM shift ad set)",
        campaignID: "23849945890190542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849945890200542",
        adID: "23849945913760542",
    },
    {
        adName: "[EKMC 6] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 6] Acquisition | BPO - 115/day (AM & PM shift ad set)",
        campaignID: "23849945890190542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23849945890210542",
        adID: "23849945927410542",
    },
    {
        adName: "[EKMC 6] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 6] Acquisition | BPO - 115/day (AM & PM shift ad set)",
        campaignID: "23849945890190542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23849945890210542",
        adID: "23849953933770542",
    },
    {
        adName: "[EKMC 8] MedCert 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 8] PM Acquisition | BPO - 100/day (2-11PM)",
        campaignID: "23849996922680542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849996922740542",
        adID: "23849996922690542",
    },
    {
        adName: "[EKMC 8] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 8] PM Acquisition | BPO - 100/day (2-11PM)",
        campaignID: "23849996922680542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849996922740542",
        adID: "23849996922700542",
    },
    {
        adName: "[EKMC 8] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 8] PM Acquisition | BPO - 100/day (2-11PM)",
        campaignID: "23849996922680542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849996922670542",
        adID: "23849996922720542",
    },
    {
        adName: "[EKMC 8] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 8] PM Acquisition | BPO - 100/day (2-11PM)",
        campaignID: "23849996922680542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849996922740542",
        adID: "23849996922730542",
    },
    {
        adName: "[EKMC 8] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 8] PM Acquisition | BPO - 100/day (2-11PM)",
        campaignID: "23849996922680542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849996922670542",
        adID: "23849996922750542",
    },
    {
        adName: "[EKMC 8] MGA DOKTOR",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 8] PM Acquisition | BPO - 100/day (2-11PM)",
        campaignID: "23849996922680542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23849996922670542",
        adID: "23849996958430542",
    },
    {
        adName: "[EKMC 7] MGA DOKTOR",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 7] AM Acquisition | BPO - 225/day (6-3PM)",
        campaignID: "23850045411950542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23850045412000542",
        adID: "23850045411960542",
    },
    {
        adName: "[EKMC 7] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 7] AM Acquisition | BPO - 225/day (6-3PM)",
        campaignID: "23850045411950542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23850045412000542",
        adID: "23850045411980542",
    },
    {
        adName: "[EKMC 7] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 7] AM Acquisition | BPO - 225/day (6-3PM)",
        campaignID: "23850045411950542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23850045411970542",
        adID: "23850045411990542",
    },
    {
        adName: "[EKMC 7] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 7] AM Acquisition | BPO - 225/day (6-3PM)",
        campaignID: "23850045411950542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23850045412000542",
        adID: "23850045412010542",
    },
    {
        adName: "[EKMC 7] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 7] AM Acquisition | BPO - 225/day (6-3PM)",
        campaignID: "23850045411950542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23850045411970542",
        adID: "23850045412020542",
    },
    {
        adName: "[EKMC 7] MedCert 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 7] AM Acquisition | BPO - 225/day (6-3PM)",
        campaignID: "23850045411950542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23850045411970542",
        adID: "23850045412030542",
    },
    {
        adName: "[EKMC 4] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 4] AM Acquisition | Smart - 590/day (6-3PM)",
        campaignID: "23850062644970542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850062644930542",
        adID: "23850062644950542",
    },
    {
        adName: "[EKMC 4] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 4] AM Acquisition | Smart - 590/day (6-3PM)",
        campaignID: "23850062644970542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850062644930542",
        adID: "23850062644960542",
    },
    {
        adName: "[EKMC 4] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 4] AM Acquisition | Smart - 590/day (6-3PM)",
        campaignID: "23850062644970542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850062644930542",
        adID: "23850062644980542",
    },
    {
        adName: "[EKMC 2] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 2] PM Acquisition | Smart - 1225/day (2-11PM)",
        campaignID: "23850062718590542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850062718570542",
        adID: "23850062718580542",
    },
    {
        adName: "[EKMC 2] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 2] PM Acquisition | Smart - 1225/day (2-11PM)",
        campaignID: "23850062718590542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850062718570542",
        adID: "23850062718600542",
    },
    {
        adName: "[EKMC 2] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 2] PM Acquisition | Smart - 1225/day (2-11PM)",
        campaignID: "23850062718590542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850062718570542",
        adID: "23850062718610542",
    },
    {
        adName: "[EKMC 1] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 910/day (AM & PM shift ad set)",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days AM SCHED",
        adsetID: "23850062760120542",
        adID: "23850062760050542",
    },
    {
        adName: "[EKMC 1] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 910/day (AM & PM shift ad set)",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days AM SCHED",
        adsetID: "23850062760120542",
        adID: "23850062760070542",
    },
    {
        adName: "[EKMC 1] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 910/day (AM & PM shift ad set)",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days AM SCHED",
        adsetID: "23850062760120542",
        adID: "23850062760080542",
    },
    {
        adName: "[EKMC 1] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 910/day (AM & PM shift ad set)",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days PM SCHED",
        adsetID: "23850062760100542",
        adID: "23850062760090542",
    },
    {
        adName: "[EKMC 1] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 910/day (AM & PM shift ad set)",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days PM SCHED",
        adsetID: "23850062760100542",
        adID: "23850062760110542",
    },
    {
        adName: "[EKMC 1] MedCert 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 910/day (AM & PM shift ad set)",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days PM SCHED",
        adsetID: "23850062760100542",
        adID: "23850062760130542",
    },
    {
        adName: "[EKMC 9] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 9] Acquisition | Smart - 145/day (8AM-11PM)",
        campaignID: "23850144841200542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850144841230542",
        adID: "23850144841190542",
    },
    {
        adName: "[EKMC 9] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 9] Acquisition | Smart - 145/day (8AM-11PM)",
        campaignID: "23850144841200542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850144841230542",
        adID: "23850144841210542",
    },
    {
        adName: "[EKMC 9] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 9] Acquisition | Smart - 145/day (8AM-11PM)",
        campaignID: "23850144841200542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850144841230542",
        adID: "23850144841220542",
    },
    {
        adName: "[EKMC 10] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 10] Acquisition | BPO - 492/day (6AM-10PM)",
        campaignID: "23850173519550542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23850173519560542",
        adID: "23850173519570542",
    },
    {
        adName: "[EKMC 10] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 10] Acquisition | BPO - 492/day (6AM-10PM)",
        campaignID: "23850173519550542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23850173519560542",
        adID: "23850173519580542",
    },
    {
        adName: "[EKMC 10] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 10] Acquisition | BPO - 492/day (6AM-10PM)",
        campaignID: "23850173519550542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23850173519560542",
        adID: "23850173519590542",
    },
    {
        adName: "[EKMC 12] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 12] Acquisition | BPO - 57/day",
        campaignID: "23850273836190542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23850273836170542",
        adID: "23850273836180542",
    },
    {
        adName: "[EKMC 12] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 12] Acquisition | BPO - 57/day",
        campaignID: "23850273836190542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23850273836170542",
        adID: "23850273836200542",
    },
    {
        adName: "[EKMC 12] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 12] Acquisition | BPO - 57/day",
        campaignID: "23850273836190542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23850273836170542",
        adID: "23850273836210542",
    },
    {
        adName: "[EKMC 13] MGA DOKTOR",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 975/day (2-11PM)",
        campaignID: "23850298671380542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23850298671450542",
        adID: "23850298671390542",
    },
    {
        adName: "[EKMC 13] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 975/day (2-11PM)",
        campaignID: "23850298671380542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23850298671450542",
        adID: "23850298671400542",
    },
    {
        adName: "[EKMC 13] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 975/day (2-11PM)",
        campaignID: "23850298671380542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23850298671410542",
        adID: "23850298671420542",
    },
    {
        adName: "[EKMC 13] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 975/day (2-11PM)",
        campaignID: "23850298671380542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23850298671410542",
        adID: "23850298671430542",
    },
    {
        adName: "[EKMC 13] MedCert 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 975/day (2-11PM)",
        campaignID: "23850298671380542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23850298671410542",
        adID: "23850298671440542",
    },
    {
        adName: "[EKMC 13] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 975/day (2-11PM)",
        campaignID: "23850298671380542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23850298671450542",
        adID: "23850298671460542",
    },
    {
        adName: "[Old Audience] Flu + Pneumo",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] Old Audience_05/02/2022",
        adsetID: "23850492401730542",
        adID: "23850492401670542",
    },
    {
        adName: "[Old Audience] Vaccines Old Super Pub",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] Old Audience_05/02/2022",
        adsetID: "23850492401730542",
        adID: "23850492401690542",
    },
    {
        adName: "[Old Audience] Vaccines Old Super Pub",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] EKMC Smart Audience",
        adsetID: "23850492401650542",
        adID: "23850492401700542",
    },
    {
        adName: "[Old Audience] Flu + Pneumo",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] EKMC Smart Audience",
        adsetID: "23850492401650542",
        adID: "23850492401710542",
    },
    {
        adName: "[EKMC 14] Updated Cover Photo (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 14] Page Likes 2 Ad Sets | 115/day",
        campaignID: "23850521363350542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850521363580542",
        adID: "23850521363790542",
    },
    {
        adName: "[EKMC 14] Medical Certificate (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 14] Page Likes 2 Ad Sets | 115/day",
        campaignID: "23850521363350542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850521363580542",
        adID: "23850521439390542",
    },
    {
        adName: "[EKMC 14] Medical Certificate (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 14] Page Likes 2 Ad Sets | 115/day",
        campaignID: "23850521363350542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850521462810542",
        adID: "23850521462790542",
    },
    {
        adName: "[EKMC 14] Updated Cover Photo (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 14] Page Likes 2 Ad Sets | 115/day",
        campaignID: "23850521363350542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850521462810542",
        adID: "23850521462800542",
    },
    {
        adName: "[EKMC 15] Medical Certificate (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 15] Reach 2 Ad Sets | 50/day",
        campaignID: "23850521490360542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850521490380542",
        adID: "23850521490340542",
    },
    {
        adName: "[EKMC 15] Updated Cover Photo (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 15] Reach 2 Ad Sets | 50/day",
        campaignID: "23850521490360542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850521490380542",
        adID: "23850521490350542",
    },
    {
        adName: "[EKMC 15] Medical Certificate (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 15] Reach 2 Ad Sets | 50/day",
        campaignID: "23850521490360542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850521490390542",
        adID: "23850521490370542",
    },
    {
        adName: "[EKMC 15] Updated Cover Photo (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 15] Reach 2 Ad Sets | 50/day",
        campaignID: "23850521490360542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850521490390542",
        adID: "23850521490400542",
    },
    {
        adName: "[EKMC 14] Doctors Pub 2 (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 14] Page Likes 2 Ad Sets | 115/day",
        campaignID: "23850521363350542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850521462810542",
        adID: "23850563467120542",
    },
    {
        adName: "[EKMC 14] Doctors Pub 2 (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 14] Page Likes 2 Ad Sets | 115/day",
        campaignID: "23850521363350542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850521363580542",
        adID: "23850563485250542",
    },
    {
        adName: "[EKMC 15] Medical Certificate (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 15] Lookalike - Acquisition 2 Ad Sets | 105/day",
        campaignID: "23850597283510542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850597283540542",
        adID: "23850597283480542",
    },
    {
        adName: "[EKMC 15] Doctors Pub 2 (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 15] Lookalike - Acquisition 2 Ad Sets | 105/day",
        campaignID: "23850597283510542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850597283540542",
        adID: "23850597283500542",
    },
    {
        adName: "[EKMC 15] Updated Cover Photo (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 15] Lookalike - Acquisition 2 Ad Sets | 105/day",
        campaignID: "23850597283510542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850597283540542",
        adID: "23850597283520542",
    },
    {
        adName: "[EKMC 15] Doctors Pub 2 (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 15] Lookalike - Acquisition 2 Ad Sets | 105/day",
        campaignID: "23850597283510542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850597283490542",
        adID: "23850597283530542",
    },
    {
        adName: "[EKMC 15] Medical Certificate (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 15] Lookalike - Acquisition 2 Ad Sets | 105/day",
        campaignID: "23850597283510542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850597283490542",
        adID: "23850597283550542",
    },
    {
        adName: "[EKMC 15] Updated Cover Photo (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 15] Lookalike - Acquisition 2 Ad Sets | 105/day",
        campaignID: "23850597283510542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850597283490542",
        adID: "23850597283560542",
    },
    {
        adName: "[Ideal + Current] Vaccines Old Super Pub",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] Ideal + Current",
        adsetID: "23850632936380542",
        adID: "23850632936390542",
    },
    {
        adName: "[Ideal + Current Flu + Pneumo",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] Ideal + Current",
        adsetID: "23850632936380542",
        adID: "23850632936400542",
    },
    {
        adName: "[EKMC 15] Medical Cert Video (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 15] Lookalike - Acquisition 2 Ad Sets | 105/day",
        campaignID: "23850597283510542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23850597283540542",
        adID: "23850720576110542",
    },
    {
        adName: "[EKMC 15] Medical Cert Video (M)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 15] Lookalike - Acquisition 2 Ad Sets | 105/day",
        campaignID: "23850597283510542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23850597283490542",
        adID: "23850720596650542",
    },
    {
        adName: "[EKMC 16] Medical Cert Video (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Acquisition / Retargeting - Acquisition 2 Ad Sets | 70/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Engaged 365 days",
        adsetID: "23850720647720542",
        adID: "23850720647660542",
    },
    {
        adName: "[EKMC 16] Medical Cert Video (L/F)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Acquisition / Retargeting - Acquisition 2 Ad Sets | 70/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Liked/Follow Page",
        adsetID: "23850720647620542",
        adID: "23850720647700542",
    },
    {
        adName: "[EKMC 2] Medical Cert Video",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 2] PM Acquisition | Smart - 1225/day (2-11PM)",
        campaignID: "23850062718590542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850062718570542",
        adID: "23850724618870542",
    },
    {
        adName: "[EKMC 13] Medical Cert Video",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 975/day (2-11PM)",
        campaignID: "23850298671380542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23850298671410542",
        adID: "23850724622160542",
    },
    {
        adName: "[EKMC 7] MedCert Video",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 7] AM Acquisition | BPO - 225/day (6-3PM)",
        campaignID: "23850045411950542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23850045412000542",
        adID: "23850728857930542",
    },
    {
        adName: "[EKMC 4] MedCert Video",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 4] AM Acquisition | Smart - 590/day (6-3PM)",
        campaignID: "23850062644970542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850062644930542",
        adID: "23850728870670542",
    },
    {
        adName: "[EKMC 16] Medical Cert Video (Vax Aud)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Acquisition / Retargeting - Acquisition 2 Ad Sets | 70/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Vaccines Audience",
        adsetID: "23850728917770542",
        adID: "23850728917800542",
    },
    {
        adName: "[EKMC 16] Loyalty Rewards (E)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Acquisition / Retargeting - Acquisition 2 Ad Sets | 70/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Engaged 365 days",
        adsetID: "23850720647720542",
        adID: "23850821429350542",
    },
    {
        adName: "[EKMC 16] Loyalty Rewards (L/F)",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Acquisition / Retargeting - Acquisition 2 Ad Sets | 70/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Liked/Follow Page",
        adsetID: "23850720647620542",
        adID: "23850821433860542",
    },
    {
        adName: "[EKMC 10] Loyalty Rewards",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 10] Acquisition | BPO - 492/day (6AM-10PM)",
        campaignID: "23850173519550542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23850173519560542",
        adID: "23850821446610542",
    },
    {
        adName: "[Ideal + Current] Rainy Season",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] Ideal + Current",
        adsetID: "23850632936380542",
        adID: "23850821454260542",
    },
    {
        adName: "[Old Audience] Rainy Season",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] Old Audience_05/02/2022",
        adsetID: "23850492401730542",
        adID: "23850821458970542",
    },
    {
        adName: "[Old Audience] Rainy Season",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 100/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] EKMC Smart Audience",
        adsetID: "23850492401650542",
        adID: "23850821462350542",
    },
    {
        adName: "Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[TEST-EKMC] Acquisition | Smart Aud - 200/day (AD SCHEDULE)",
        campaignID: "23849838797390542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849838797410542",
        adID: "23849838797440542",
    },
    {
        adName: "Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[TEST-EKMC] Acquisition | Smart Aud - 200/day (AD SCHEDULE)",
        campaignID: "23849838797390542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849838797410542",
        adID: "23849838797460542",
    },
    {
        adName: "[EKMC 1] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 1] Acquisition | Smart - 125/day - AM SCHEDULE (6am-3pm)",
        campaignID: "23849911631620542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911631630542",
        adID: "23849911631640542",
    },
    {
        adName: "[EKMC 3] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 3] AM Acquisition | Smart - 200/day (6-3PM 7.30 bid cap) Multiple Ad Creatives",
        campaignID: "23849911746250542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911746270542",
        adID: "23849911746260542",
    },
    {
        adName: "[EKMC 3] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 3] AM Acquisition | Smart - 200/day (6-3PM 7.30 bid cap) Multiple Ad Creatives",
        campaignID: "23849911746250542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911746270542",
        adID: "23849911746280542",
    },
    {
        adName: "[EKMC 3] Super Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 3] AM Acquisition | Smart - 200/day (6-3PM 7.30 bid cap) Multiple Ad Creatives",
        campaignID: "23849911746250542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911746270542",
        adID: "23849911746290542",
    },
    {
        adName: "[EKMC 4] Super Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 4] PM Acquisition | Smart - 375/day - (2-11PM 6 bid cap) Multiple Ad Creatives",
        campaignID: "23849911867420542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911867480542",
        adID: "23849911867410542",
    },
    {
        adName: "[EKMC 4] Super Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 4] PM Acquisition | Smart - 375/day - (2-11PM 6 bid cap) Multiple Ad Creatives",
        campaignID: "23849911867420542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911867480542",
        adID: "23849911867440542",
    },
    {
        adName: "[EKMC 4] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 4] PM Acquisition | Smart - 375/day - (2-11PM 6 bid cap) Multiple Ad Creatives",
        campaignID: "23849911867420542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849911867480542",
        adID: "23849911867450542",
    },
    {
        adName: "[EKMC 5] Super Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932066300542",
    },
    {
        adName: "[EKMC 5] Super Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932066350542",
    },
    {
        adName: "[EKMC 5] Mga Serbisyo",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 5] PM Acquisition | Smart - 400/day - (2-11PM 7 bid cap) Multiple Ad Creatives",
        campaignID: "23849932066270542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23849932066280542",
        adID: "23849932073320542",
    },
    {
        adName: "[EKMC 11] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 11] Acquisition | Smart - 358/day",
        campaignID: "23850263746190542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850263746160542",
        adID: "23850263746170542",
    },
    {
        adName: "[EKMC 11] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 11] Acquisition | Smart - 358/day",
        campaignID: "23850263746190542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850263746160542",
        adID: "23850263746180542",
    },
    {
        adName: "[EKMC 11] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 11] Acquisition | Smart - 358/day",
        campaignID: "23850263746190542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850263746160542",
        adID: "23850263746200542",
    },
    {
        adName: "[P-EKMC 1] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 1] Acquisition | Smart - 260/day",
        campaignID: 6241025416955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6241025417555,
        adID: 6241025418555,
    },
    {
        adName: "[P-EKMC 1] Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 1] Acquisition | Smart - 260/day",
        campaignID: 6241025416955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6241025417555,
        adID: 6255533621355,
    },
    {
        adName: "[JVC 1] Explore our Facilities",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC 1] Broad Targeting 11/06/2021",
        adsetID: 6256426884355,
        adID: 6259228282555,
    },
    {
        adName: "[P-EKMC 2] MedCert Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 2] Acquisition | BPO - 170/day",
        campaignID: 6259476675755,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6259476676155,
        adID: 6259476675955,
    },
    {
        adName: "[P-EKMC 2] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 2] Acquisition | BPO - 170/day",
        campaignID: 6259476675755,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6259476704155,
        adID: 6259476703955,
    },
    {
        adName: "[JVC 1] Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC 1] Broad Targeting 11/06/2021",
        adsetID: 6256426884355,
        adID: 6259781752355,
    },
    {
        adName: "[JVC 1] Vets",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC 1] Broad Targeting 11/06/2021",
        adsetID: 6256426884355,
        adID: 6259781810155,
    },
    {
        adName: "Pneumo + Flu",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[VACCINES] Acquisition | Vax Default - 150/day",
        campaignID: 6265384312155,
        adsetName: "[Vaccines] Ideal+Current Client_03/07/2022",
        adsetID: 6265384315955,
        adID: 6265384317155,
    },
    {
        adName: "[P-EKMC 4] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 4] AM Acquisition | Smart - 135/day (6-3PM)",
        campaignID: 6266163500555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266163500355,
        adID: 6266163500755,
    },
    {
        adName: "[P-EKMC 4] Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 4] AM Acquisition | Smart - 135/day (6-3PM)",
        campaignID: 6266163500555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266163500355,
        adID: 6266163500955,
    },
    {
        adName: "Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 5] Acquisition | Smart Aud - 250/day (TESTING 7Php bid - CHECK IF 100% SPENT)",
        campaignID: 6266164142955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266164142355,
        adID: 6266164142555,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 5] Acquisition | Smart Aud - 250/day (TESTING 7Php bid - CHECK IF 100% SPENT)",
        campaignID: 6266164142955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266164142355,
        adID: 6266164142755,
    },
    {
        adName: "[P-EKMC 6] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 6] Acquisition | Smart - 60/day",
        campaignID: 6266815434955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266815434555,
        adID: 6266815434355,
    },
    {
        adName: "[P-EKMC 6] Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 6] Acquisition | Smart - 60/day",
        campaignID: 6266815434955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266815434555,
        adID: 6266815434755,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition | Look-alike - 400/day (WITH 10 BID CAP)",
        campaignID: 6266152918755,
        adsetName: "Lookalike (PH, 1% to 2%) - [Engaged] 120",
        adsetID: 6266921145955,
        adID: 6266921145555,
    },
    {
        adName: "Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition | Look-alike - 400/day (WITH 10 BID CAP)",
        campaignID: 6266152918755,
        adsetName: "Lookalike (PH, 1% to 2%) - [Engaged] 120",
        adsetID: 6266921145955,
        adID: 6266921145755,
    },
    {
        adName: "[P-EKMC 7] Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 7] AM Acquisition | Smart - 75/day (6-3PM GOAL: LOW CPR + SPENT 100% BUDGET)",
        campaignID: 6267131505355,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267131505155,
        adID: 6267131505555,
    },
    {
        adName: "[P-EKMC 7] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 7] AM Acquisition | Smart - 75/day (6-3PM GOAL: LOW CPR + SPENT 100% BUDGET)",
        campaignID: 6267131505355,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267131505155,
        adID: 6267131505755,
    },
    {
        adName: "Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 8] Acquisition | Smart - 230/day (PM: 2-11PM: AD SCHEDULE GOAL: LOW CPR + SPENT 100% BUDGET)",
        campaignID: 6267131667555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267131667355,
        adID: 6267131667755,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 8] Acquisition | Smart - 230/day (PM: 2-11PM: AD SCHEDULE GOAL: LOW CPR + SPENT 100% BUDGET)",
        campaignID: 6267131667555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267131667355,
        adID: 6267131667955,
    },
    {
        adName: "[P-EKMC 3] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition | Look-alike - 400/day (WITH 10 BID CAP)",
        campaignID: 6266152918755,
        adsetName: "Lookalike (PH, 1% to 2%) - [Messaged] 365",
        adsetID: 6267132662155,
        adID: 6267132662355,
    },
    {
        adName: "[P-EKMC 3] Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition | Look-alike - 400/day (WITH 10 BID CAP)",
        campaignID: 6266152918755,
        adsetName: "Lookalike (PH, 1% to 2%) - [Messaged] 365",
        adsetID: 6267132662155,
        adID: 6267132662555,
    },
    {
        adName: "[P-EKMC 9] Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 9] PM Acquisition | Smart - 70/day (2-11PM)",
        campaignID: 6267585662755,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267585662355,
        adID: 6267585662155,
    },
    {
        adName: "[P-EKMC 9] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 9] PM Acquisition | Smart - 70/day (2-11PM)",
        campaignID: 6267585662755,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267585662355,
        adID: 6267585662555,
    },
    {
        adName: "[P-EKMC 10] MedCert Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718155,
        adID: 6267775717355,
    },
    {
        adName: "[P-EKMC 11] MedCert Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718555,
        adID: 6267775717555,
    },
    {
        adName: "[P-EKMC 10] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6267775718755,
        adID: 6267775717755,
    },
    {
        adName: "[P-EKMC 11] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6267775717155,
        adID: 6267775717955,
    },
    {
        adName: "Super Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] Acquisition - Smart 500/day | 7php bid | many ad creatives",
        campaignID: 6267776050555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267776050355,
        adID: 6267776284755,
    },
    {
        adName: "[P-EKMC 11] MedCert Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718555,
        adID: 6268468037555,
    },
    {
        adName: "[P-EKMC 11] Super Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6267775717155,
        adID: 6268468059755,
    },
    {
        adName: "[P-EKMC 11] Super Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6267775717155,
        adID: 6268468162555,
    },
    {
        adName: "[P-EKMC 11] Super Pub Compiled",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6267775717155,
        adID: 6268468187955,
    },
    {
        adName: "[P-EKMC 11] Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718555,
        adID: 6268468215355,
    },
    {
        adName: "[P-EKMC 11] Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718555,
        adID: 6268468279555,
    },
    {
        adName: "[P-EKMC 11] MGA DOKTOR",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 11] PM Acquisition | BPO - 135/day (2-11PM)",
        campaignID: 6267775718355,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718555,
        adID: 6268468335955,
    },
    {
        adName: "[P-EKMC 10] Super Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6267775718755,
        adID: 6268468628755,
    },
    {
        adName: "[P-EKMC 10] Super Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6267775718755,
        adID: 6268468715355,
    },
    {
        adName: "[P-EKMC 10] Super Pub compiled",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days – Copy",
        adsetID: 6267775718755,
        adID: 6268468789955,
    },
    {
        adName: "[P-EKMC 10] MedCert Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718155,
        adID: 6268468877555,
    },
    {
        adName: "[P-EKMC 10] Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718155,
        adID: 6268469027555,
    },
    {
        adName: "[P-EKMC 10] Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718155,
        adID: 6268469125155,
    },
    {
        adName: "[P-EKMC 10] MGA DOKTOR",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 10] AM Acquisition | BPO - 60/day (6-3PM)",
        campaignID: 6267775716955,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6267775718155,
        adID: 6268469159555,
    },
    {
        adName: "[P-EKMC 12] Super Pub 1 Lookalike Messaged",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Messaged] 60",
        adsetID: 6268756387555,
        adID: 6268756387955,
    },
    {
        adName: "[P-EKMC 12] Doctors Pub 3 Lookalike Engaged",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268756388755,
        adID: 6268756388155,
    },
    {
        adName: "[P-EKMC 12] Doctors Pub 3 Lookalike Messaged",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Messaged] 60",
        adsetID: 6268756387555,
        adID: 6268756388355,
    },
    {
        adName: "[P-EKMC 12] MedCert 3 Lookalike Engaged",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268756388755,
        adID: 6268756388555,
    },
    {
        adName: "[P-EKMC 12] Super Pub 1 Lookalike Website",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Website Visit] 90",
        adsetID: 6268756534555,
        adID: 6268756534355,
    },
    {
        adName: "[P-EKMC 12] Doctors Pub 3 Lookalike Website",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Website Visit] 90",
        adsetID: 6268756534555,
        adID: 6268756534755,
    },
    {
        adName: "[P-EKMC 12] MedCert 3 Lookalike Website",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Website Visit] 90",
        adsetID: 6268756534555,
        adID: 6268758008355,
    },
    {
        adName: "[P-EKMC 12] MedCert 3 Lookalike Messaged - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Messaged] 60",
        adsetID: 6268756387555,
        adID: 6268758513755,
    },
    {
        adName: "[P-EKMC 12] Super Pub 1 Lookalike Engaged - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268756388755,
        adID: 6268758775355,
    },
    {
        adName: "[P-EKMC 13] Doctors Pub 3 Lookalike Messaged",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Messaged] 60",
        adsetID: 6268759948355,
        adID: 6268759946155,
    },
    {
        adName: "[P-EKMC 13] MedCert 3 Lookalike Messaged - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Messaged] 60",
        adsetID: 6268759948355,
        adID: 6268759946355,
    },
    {
        adName: "[P-EKMC 13] Doctors Pub 3 Lookalike Engaged",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268759948155,
        adID: 6268759946755,
    },
    {
        adName: "[P-EKMC 13] MedCert 3 Lookalike Engaged",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268759948155,
        adID: 6268759947155,
    },
    {
        adName: "[P-EKMC 13] Doctors Pub 3 Lookalike Website",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Website Visit] 90",
        adsetID: 6268759946555,
        adID: 6268759947355,
    },
    {
        adName: "[P-EKMC 13] MedCert 3 Lookalike Website",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Website Visit] 90",
        adsetID: 6268759946555,
        adID: 6268759947555,
    },
    {
        adName: "[P-EKMC 13] Super Pub 1 Lookalike Website",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Website Visit] 90",
        adsetID: 6268759946555,
        adID: 6268759947755,
    },
    {
        adName: "[P-EKMC 13] Super Pub 1 Lookalike Messaged",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Messaged] 60",
        adsetID: 6268759948355,
        adID: 6268759947955,
    },
    {
        adName: "[P-EKMC 9] MedCert 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 9] PM Acquisition | Smart - 70/day (2-11PM)",
        campaignID: 6267585662755,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267585662355,
        adID: 6269038855355,
    },
    {
        adName: "[P-EKMC 7] MedCert Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 7] AM Acquisition | Smart - 75/day (6-3PM GOAL: LOW CPR + SPENT 100% BUDGET)",
        campaignID: 6267131505355,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267131505155,
        adID: 6269038993955,
    },
    {
        adName: "[P-EKMC 6] MedCert Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 6] Acquisition | Smart - 60/day",
        campaignID: 6266815434955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266815434555,
        adID: 6269039055155,
    },
    {
        adName: "[P-EKMC 4] MedCert Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 4] AM Acquisition | Smart - 135/day (6-3PM)",
        campaignID: 6266163500555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266163500355,
        adID: 6269039084155,
    },
    {
        adName: "[P-EKMC 2] Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 2] Acquisition | BPO - 170/day",
        campaignID: 6259476675755,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6259476676155,
        adID: 6269039096355,
    },
    {
        adName: "[P-EKMC 13] Doctors Pub 3 Lookalike Website - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268759948155,
        adID: 6269039440155,
    },
    {
        adName: "[P-EKMC 13] MedCert 3 Lookalike Website - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268759948155,
        adID: 6269039440355,
    },
    {
        adName: "[P-EKMC 13] Super Pub 1 Lookalike Website - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 13] PM Acquisition | Look-alike - 400/day (2-11PM 6.50 bid cap)",
        campaignID: 6268759945955,
        adsetName: "PM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268759948155,
        adID: 6269039444555,
    },
    {
        adName: "[P-EKMC 12] Doctors Pub 3 Lookalike Website - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268756388755,
        adID: 6269039473955,
    },
    {
        adName: "[P-EKMC 12] MedCert 3 Lookalike Website - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268756388755,
        adID: 6269039475555,
    },
    {
        adName: "[P-EKMC 12] Super Pub 1 Lookalike Website - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] AM Acquisition | Look-alike - 300/day (6-3PM 6.50 bid cap)",
        campaignID: 6268756387755,
        adsetName: "AM Lookalike (PH, 0% to 2%) - [Engaged] 90",
        adsetID: 6268756388755,
        adID: 6269039477555,
    },
    {
        adName: "[JVC] Hiring Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "JVC Employment | Small Animal Veterinarian",
        campaignID: 6270485420355,
        adsetName: "[JVC] Veterinary Medicine",
        adsetID: 6270485423755,
        adID: 6270485433555,
    },
    {
        adName: "Flu Summer Promo",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[VACCINES] Acquisition | Vax Default - 150/day",
        campaignID: 6265384312155,
        adsetName: "[Vaccines] Ideal+Current Client_03/07/2022",
        adsetID: 6265384315955,
        adID: 6270622492355,
    },
    {
        adName: "Flu + Pneumo",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[VACCINES] Acquisition | Vax Default - 150/day",
        campaignID: 6265384312155,
        adsetName: "[Vaccines] Ideal+Current Client_03/07/2022",
        adsetID: 6265384315955,
        adID: 6270922909955,
    },
    {
        adName: "Vaccines Old Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[VACCINES] Acquisition | Vax Default - 150/day",
        campaignID: 6265384312155,
        adsetName: "[Vaccines] Ideal+Current Client_03/07/2022",
        adsetID: 6265384315955,
        adID: 6271016179355,
    },
    {
        adName: "[Old Audience] Flu Summer Promo",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[VACCINES] Acquisition | Vax Default - 150/day",
        campaignID: 6265384312155,
        adsetName: "[Vaccines] Old Audience_05/02/2022",
        adsetID: 6273153998755,
        adID: 6273153998555,
    },
    {
        adName: "[Old Audience] Vaccines Old Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[VACCINES] Acquisition | Vax Default - 150/day",
        campaignID: 6265384312155,
        adsetName: "[Vaccines] Old Audience_05/02/2022",
        adsetID: 6273153998755,
        adID: 6273153998955,
    },
    {
        adName: "[Old Audience] Flu + Pneumo",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[VACCINES] Acquisition | Vax Default - 150/day",
        campaignID: 6265384312155,
        adsetName: "[Vaccines] Old Audience_05/02/2022",
        adsetID: 6273153998755,
        adID: 6273153999155,
    },
    {
        adName: "[JVC 2] Explore our Facilities",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC] 2 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6275720506555,
        adID: 6275720506355,
    },
    {
        adName: "[JVC 2] Vets",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC] 2 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6275720506555,
        adID: 6275720506755,
    },
    {
        adName: "[JVC 2] Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC] 2 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6275720506555,
        adID: 6275720506955,
    },
    {
        adName: "[JVC 1] Our Location",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC 1] Broad Targeting 11/06/2021",
        adsetID: 6256426884355,
        adID: 6275820124155,
    },
    {
        adName: "[JVC 2] Our Location",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC] 2 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6275720506555,
        adID: 6275820239955,
    },
    {
        adName: "[JVC 1] Self Talk",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC 1] Broad Targeting 11/06/2021",
        adsetID: 6256426884355,
        adID: 6276135548555,
    },
    {
        adName: "[JVC 2] Self Talk",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC] 2 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6275720506555,
        adID: 6276135595155,
    },
    {
        adName: "[JVC 4] Know our Services",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 3 & 4] Acquisition | 2 ad sets - 110/day",
        campaignID: 6277598014155,
        adsetName: "[JVC] 4 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6277598011755,
        adID: 6277598013355,
    },
    {
        adName: "[JVC 3] Know our Services",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 3 & 4] Acquisition | 2 ad sets - 110/day",
        campaignID: 6277598014155,
        adsetName: "[JVC 3] Broad Targeting 11/06/2021",
        adsetID: 6277598013155,
        adID: 6277598117555,
    },
    {
        adName: "[JVC 3] Our Vets",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 3 & 4] Acquisition | 2 ad sets - 110/day",
        campaignID: 6277598014155,
        adsetName: "[JVC 3] Broad Targeting 11/06/2021",
        adsetID: 6277598013155,
        adID: 6278468440355,
    },
    {
        adName: "[JVC 4] Our Vets",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 3 & 4] Acquisition | 2 ad sets - 110/day",
        campaignID: 6277598014155,
        adsetName: "[JVC] 4 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6277598011755,
        adID: 6278468582555,
    },
    {
        adName: "[JVC 2] Our Vets 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC] 2 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6275720506555,
        adID: 6278468700755,
    },
    {
        adName: "[JVC 2] Our Services",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC] 2 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6275720506555,
        adID: 6278468824355,
    },
    {
        adName: "Parvo",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 5] Post Engagement - 110/day",
        campaignID: 6279840801155,
        adsetName: "Post Engagement - CANDI audience",
        adsetID: 6279840801555,
        adID: 6279840801955,
    },
    {
        adName: "Parvo",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 5] Post Engagement - 110/day",
        campaignID: 6279840801155,
        adsetName: "Post Engagement - JVC Broad Targeting",
        adsetID: 6279842139555,
        adID: 6279842139355,
    },
    {
        adName: "Blood Parasite",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 5] Post Engagement - 110/day",
        campaignID: 6279840801155,
        adsetName: "Post Engagement - CANDI audience",
        adsetID: 6279840801555,
        adID: 6279842268355,
    },
    {
        adName: "Blood Parasite",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 5] Post Engagement - 110/day",
        campaignID: 6279840801155,
        adsetName: "Post Engagement - JVC Broad Targeting",
        adsetID: 6279842139555,
        adID: 6279842339355,
    },
    {
        adName: "[JVC 3] Know our Facilities",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 3 & 4] Acquisition | 2 ad sets - 110/day",
        campaignID: 6277598014155,
        adsetName: "[JVC 3] Broad Targeting 11/06/2021",
        adsetID: 6277598013155,
        adID: 6279842633555,
    },
    {
        adName: "[JVC 4] Know our Facilities",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC 3 & 4] Acquisition | 2 ad sets - 110/day",
        campaignID: 6277598014155,
        adsetName: "[JVC] 4 Lookalike Engaged & Messaged 365 Days 05/27/2022",
        adsetID: 6277598011755,
        adID: 6279842712355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        campaignID: 6237982094555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6237982095355,
        adID: 6237982096155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "E-Konsulta Medical Clinic - Message Replies",
        campaignID: 6238062717155,
        adsetName: "PH - 18+",
        adsetID: 6238062717955,
        adID: 6238062719955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "1.0 - EFFECTIVE - 1K",
        campaignID: 6238364154555,
        adsetName: "PH - 18+",
        adsetID: 6238364159155,
        adID: 6238364161755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tuesday | Try 1 | 750 | Bulk vs. Effective 1",
        campaignID: 6238588171955,
        adsetName: "PH - 18+",
        adsetID: 6238588174755,
        adID: 6238588177555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 2 | 500",
        campaignID: 6238705064555,
        adsetName: "PH - 18+",
        adsetID: 6238705065955,
        adID: 6238705069755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Smart Audience | 3",
        campaignID: 6239069095555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239069095955,
        adID: 6239069097155,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Smart Audience | 4",
        campaignID: 6239069116355,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239069116755,
        adID: 6239069117755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Try 3 | 500",
        campaignID: 6239069557355,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239069557755,
        adID: 6239069558755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Smart Audience | 500 | 2",
        campaignID: 6239139013755,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239139014755,
        adID: 6239139014955,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Smart Audience | 500 | 1",
        campaignID: 6239139027555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239139027755,
        adID: 6239139029155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue | Try 1 | 350",
        campaignID: 6239298927355,
        adsetName: "PH - 18+",
        adsetID: 6239298928355,
        adID: 6239298930755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Default | 500",
        campaignID: 6239406744555,
        adsetName: "PH - 18+",
        adsetID: 6239406746555,
        adID: 6239406750355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "SAT | Try 1 | 500 - 1",
        campaignID: 6239764049955,
        adsetName: "PH - 18+",
        adsetID: 6239764051755,
        adID: 6239764055555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "SAT | Try 1 | 500 - 3",
        campaignID: 6239764235155,
        adsetName: "PH - 18+",
        adsetID: 6239764236555,
        adID: 6239764242555,
    },
    {
        adName: 'Post: "Filipino homes made smarter and greener."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Portfolio - Default | 250 - 2",
        campaignID: 6239764785955,
        adsetName: 'Post: "Filipino homes made smarter and greener."',
        adsetID: 6239764786555,
        adID: 6239764787155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial E | 750",
        campaignID: 6240493204355,
        adsetName: "PH - 18-60",
        adsetID: 6240493205155,
        adID: 6240493206955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Default | Loft (Most Effective) - 150",
        campaignID: 6240895090755,
        adsetName: "PH - 20-64",
        adsetID: 6240895091355,
        adID: 6240895092755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed-Thu | BPO Try - 300",
        campaignID: 6240952670155,
        adsetName: "PH - 20+",
        adsetID: 6240952671555,
        adID: 6240952678955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Default - 500",
        campaignID: 6240954321555,
        adsetName: "PH - 18+",
        adsetID: 6240954322355,
        adID: 6240954330155,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed-Thu | Smart Audience - 1000",
        campaignID: 6240988103955,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6240988104355,
        adID: 6240988105555,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC] Acquisition | BPO 2 - 800/day",
        campaignID: 6241034658555,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6241034662555,
        adID: 6241034664355,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC 3] Acquisition | BPO 1 - 750/day",
        campaignID: 6241034849155,
        adsetName: "BPO_Excluded messaged 30 days",
        adsetID: 6241034850355,
        adID: 6241034855555,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC 5] Acquisition | Trial E - 600/day",
        campaignID: 6241039792755,
        adsetName: "Trial E_Interests_Excluded messaged 14 days",
        adsetID: 6241039793555,
        adID: 6241039795155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "MedCert | BPO (20-55) - 350",
        campaignID: 6241643673155,
        adsetName: "PH - 20-55",
        adsetID: 6241643675155,
        adID: 6241643677755,
    },
    {
        adName: "Loft Post",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Acquisition Campaign | Loft | Default Target - 1000/week",
        campaignID: 6244036039755,
        adsetName: "Default Audience",
        adsetID: 6244036040355,
        adID: 6244036041155,
    },
    {
        adName: "Loft Post",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Acquisition Campaign | Loft | Ideal Avatar - 70/day",
        campaignID: 6245595713555,
        adsetName: "Ideal Avatar Audience",
        adsetID: 6245595714355,
        adID: 6245595714755,
    },
    {
        adName: "MedCert Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC 3] Acquisition - MedCert | 993/day",
        campaignID: 6246408015355,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6246408017755,
        adID: 6246408019155,
    },
    {
        adName: "Super Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC 7 - Off] Traffic & Semi-Retargeting | 481/day",
        campaignID: 6247198362155,
        adsetName: "Look-alike (1-2%)_Engaged in 365 days",
        adsetID: 6247198362555,
        adID: 6247198362955,
    },
    {
        adName: "Super Pub 3.0",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Off] Retargeting - Engaged but not converted | 289/day",
        campaignID: 6247613949355,
        adsetName: "Custom_+engaged,-messaged + Pixel",
        adsetID: 6247613949955,
        adID: 6247613950555,
    },
    {
        adName: "Earthy Coastal Post",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-DDB] Acquisition & Retargeting | 420/day",
        campaignID: 6248015834155,
        adsetName: "Try 1_Broad",
        adsetID: 6248015835155,
        adID: 6248015835955,
    },
    {
        adName: "Modern Industrial Design",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-DDB] Acquisition & Retargeting | 420/day",
        campaignID: 6248015834155,
        adsetName: "Engaged to page_Retargeting",
        adsetID: 6248015849755,
        adID: 6248015853355,
    },
    {
        adName: "Modern Med Style",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-DDB] Acquisition & Retargeting | 420/day",
        campaignID: 6248015834155,
        adsetName: "Look-alike_Retargeting",
        adsetID: 6248025348155,
        adID: 6248025347955,
    },
    {
        adName: "Vaccine Introduction Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Vaccines - Acquisition Campaign - 50/day",
        campaignID: 6248123889355,
        adsetName: "Vaccine Audience #1 - Interests/Behavior",
        adsetID: 6248123890355,
        adID: 6248123891355,
    },
    {
        adName: "Vaccine Intro Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest/Loc_Try 1_All vaccine locations",
        adsetID: 6248152541755,
        adID: 6248152542755,
    },
    {
        adName: "Vaccine Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest/Loc_Try 1_All vaccine locations",
        adsetID: 6248152541755,
        adID: 6248321075555,
    },
    {
        adName: "MECQ/ECQ/GCQ Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC-OFF] Engagement - ECQ/MECQ | 220/day",
        campaignID: 6248473952355,
        adsetName: "Broad_Interested in current events",
        adsetID: 6248473953155,
        adID: 6248473953555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Engagement Campaign | Senior Citizen Pub - 200/day",
        campaignID: 6248508573955,
        adsetName: "PH - 13+",
        adsetID: 6248508574955,
        adID: 6248508577955,
    },
    {
        adName: "Vaccine Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest-based_Vax Locations",
        adsetID: 6248778814755,
        adID: 6248778814955,
    },
    {
        adName: "Vaccine Intro Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest-based_Vax Locations",
        adsetID: 6248778814755,
        adID: 6248778815155,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC 7 - Off] Traffic & Semi-Retargeting | 481/day",
        campaignID: 6247198362155,
        adsetName: "Look-alike (1-2%)_Messaged in 365 days",
        adsetID: 6249057139355,
        adID: 6249057141755,
    },
    {
        adName: "Eslite Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Eslite-MKTNG] Acquisition | 50/day",
        campaignID: 6249261119355,
        adsetName: "Eslite_Try 1_Aug 30",
        adsetID: 6249261120355,
        adID: 6249261120755,
    },
    {
        adName: "CANDI ID Tag Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Candi-ADHOC] Acquisition | 250/week",
        campaignID: 6249263448755,
        adsetName: "Candi_Try 1_Aug 30",
        adsetID: 6249263449555,
        adID: 6249263450355,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC-OFF] Acquisition - Trial | 202/day",
        campaignID: 6249473901955,
        adsetName: "Jade Audience_Try 1_Sept 1",
        adsetID: 6249473902955,
        adID: 6249473903755,
    },
    {
        adName: "[FAILED] Best of Scandinavian Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[DDB] For Page Likes | 55/day",
        campaignID: 6249481750555,
        adsetName: "Try 1_Broad+even semi-related",
        adsetID: 6249481751355,
        adID: 6249481752155,
    },
    {
        adName: "Modern Industrial Design",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-DDB] Acquisition & Retargeting | 420/day",
        campaignID: 6248015834155,
        adsetName: "Interest-based_Acquisition",
        adsetID: 6249561575755,
        adID: 6249561575555,
    },
    {
        adName: "Vaccine 5/5 Details",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Engaged w/ post or ad, 60 days_Vax Locations",
        adsetID: 6249810894355,
        adID: 6249810894555,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC-OFF] Acquisition - Trial | 202/day",
        campaignID: 6249473901955,
        adsetName: "Trial Audience_Job/Triage_Sept 4",
        adsetID: 6249811221155,
        adID: 6249811220955,
    },
    {
        adName: "Vaccine Pande Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest-based_Vax Locations",
        adsetID: 6248778814755,
        adID: 6250582748955,
    },
    {
        adName: "Super Pub 1 - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC-OFF] Acquisition - Trial | 202/day",
        campaignID: 6249473901955,
        adsetName: "Trial Audience_Job/Triage_Sept 4",
        adsetID: 6249811221155,
        adID: 6251967062555,
    },
    {
        adName: "Super Pub 1 - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC-OFF] Acquisition - Trial | 202/day",
        campaignID: 6249473901955,
        adsetName: "Trial Audience_Job/Triage_Sept 4",
        adsetID: 6249811221155,
        adID: 6251967090355,
    },
    {
        adName: "MECQ/ECQ/GCQ Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC-OFF] Reach | 220/day",
        campaignID: 6252922517155,
        adsetName: "Broad_Anyone",
        adsetID: 6252922516955,
        adID: 6252922517355,
    },
    {
        adName: "Live Q&A",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC-OFF] Reach | 220/day",
        campaignID: 6252922517155,
        adsetName: "Broad_Anyone",
        adsetID: 6252922516955,
        adID: 6252922637755,
    },
    {
        adName: "Vaccine 5/5 Details",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest-based_Vax Locations",
        adsetID: 6248778814755,
        adID: 6252922897755,
    },
    {
        adName: "Vaccine 5/5 Details",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest/Loc_Try 1_All vaccine locations",
        adsetID: 6248152541755,
        adID: 6252922959955,
    },
    {
        adName: "Vaccine Super Pub - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest/Loc_Try 1_All vaccine locations",
        adsetID: 6248152541755,
        adID: 6253031265155,
    },
    {
        adName: "Vaccine 5/5 Details - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest/Loc_Try 1_All vaccine locations",
        adsetID: 6248152541755,
        adID: 6253031266755,
    },
    {
        adName: "Vaccine 5/5 Details - Copy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[Vaccines] Acquisition & Retargeting | 180/day",
        campaignID: 6248152540955,
        adsetName: "Interest/Loc_Try 1_All vaccine locations",
        adsetID: 6248152541755,
        adID: 6253031268355,
    },
    {
        adName: "PCOS Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC-OFF] Reach | 220/day",
        campaignID: 6252922517155,
        adsetName: "Broad_Anyone",
        adsetID: 6252922516955,
        adID: 6253443217755,
    },
    {
        adName: "Loft Post",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-DDB] Acquisition & Retargeting | 420/day",
        campaignID: 6248015834155,
        adsetName: "Rich Audience_Acquisition_10/11/21",
        adsetID: 6253443823555,
        adID: 6253443823755,
    },
    {
        adName: "Super Pub 3.0",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC 1] Acquisition - Trial | Rich - 580/day",
        campaignID: 6253445670755,
        adsetName: "Rich Audience",
        adsetID: 6253445671555,
        adID: 6253445672355,
    },
    {
        adName: "[JVC] Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[JVC] Acquisition | 2 ad sets - 230/day",
        campaignID: 6256426883955,
        adsetName: "[JVC 1] Broad Targeting 11/06/2021",
        adsetID: 6256426884355,
        adID: 6256426884955,
    },
    {
        adName: "MedCert Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC 2 - New] MedCert for Travel - 289/day",
        campaignID: 6257581143355,
        adsetName: "Travel - Excluding 14 days",
        adsetID: 6257581143955,
        adID: 6257581144355,
    },
    {
        adName: "Super Pub 3.0",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 2] Acquisition | BPO - 170/day",
        campaignID: 6259476675755,
        adsetName: "Trial E_Interests_Excluded messaged 14 days",
        adsetID: 6259477093155,
        adID: 6259477092955,
    },
    {
        adName: "Summarized Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 2] Acquisition | BPO - 170/day",
        campaignID: 6259476675755,
        adsetName: "Trial Broad Audience_Fitness & Wellness",
        adsetID: 6261622348555,
        adID: 6261622348955,
    },
    {
        adName: "Trabaho: Triage Nurse",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Trabaho: Triage Nurse",
        campaignID: 6262459283555,
        adsetName: "Trabaho: Triage Nurse",
        adsetID: 6262459284555,
        adID: 6262459285355,
    },
    {
        adName: "Job: Patient Care Officer",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Job: Patient Care Officer",
        campaignID: 6262803523155,
        adsetName: "Job: Patient Care Officer",
        adsetID: 6262803523955,
        adID: 6262803524355,
    },
    {
        adName: "[PNPH] Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[PNPH] Page Likes",
        campaignID: 6263680178955,
        adsetName: "[PNPH] Page Likes",
        adsetID: 6263680179355,
        adID: 6263680179755,
    },
    {
        adName: "Mga Doktor",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition - PM Shift | 310/day - 2 sets",
        campaignID: 6265357627155,
        adsetName: "Health-related",
        adsetID: 6265357627955,
        adID: 6265357626355,
    },
    {
        adName: "Mga Doktor",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition - PM Shift | 310/day - 2 sets",
        campaignID: 6265357627155,
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: 6265357626555,
        adID: 6265357627355,
    },
    {
        adName: "Pneumo Super Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-Vaccines] 180/day Acquisition (Pneumo Only)",
        campaignID: 6265390437755,
        adsetName: "[Vaccines] Rich + Current Client Data_02/04/2022",
        adsetID: 6265390437955,
        adID: 6265390437555,
    },
    {
        adName: "New Pneumo Pub",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-Vaccines] 180/day Acquisition (Pneumo Only)",
        campaignID: 6265390437755,
        adsetName: "[Vaccines] Rich + Current Client Data_02/04/2022",
        adsetID: 6265390437955,
        adID: 6265390530155,
    },
    {
        adName: "Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 4] 660/day Acquisition - Trial Bidding",
        campaignID: 6265482599955,
        adsetName: "Lookalike CTA in 365 days",
        adsetID: 6265482600355,
        adID: 6265482601155,
    },
    {
        adName: "MedCert Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 4] 660/day Acquisition - Trial Bidding",
        campaignID: 6265482599955,
        adsetName: "Lookalike CTA in 365 days",
        adsetID: 6265482600355,
        adID: 6265483081755,
    },
    {
        adName: "Doctor Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition - PM Shift | 310/day - 2 sets",
        campaignID: 6265357627155,
        adsetName: "Health-related",
        adsetID: 6265357627955,
        adID: 6265492057355,
    },
    {
        adName: "Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 4] 660/day Acquisition - Trial Bidding",
        campaignID: 6265482599955,
        adsetName: "Look-alike engaged in 365 days",
        adsetID: 6266039723955,
        adID: 6266039723555,
    },
    {
        adName: "MedCert Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 4] 660/day Acquisition - Trial Bidding",
        campaignID: 6265482599955,
        adsetName: "Look-alike engaged in 365 days",
        adsetID: 6266039723955,
        adID: 6266039723755,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition | Look-alike - 400/day (WITH 10 BID CAP)",
        campaignID: 6266152918755,
        adsetName: "Lookalike (PH, 1% to 2%) - [Engaged] 365",
        adsetID: 6266152919755,
        adID: 6266152918955,
    },
    {
        adName: "Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 3] Acquisition | Look-alike - 400/day (WITH 10 BID CAP)",
        campaignID: 6266152918755,
        adsetName: "Lookalike (PH, 1% to 2%) - [Engaged] 365",
        adsetID: 6266152919755,
        adID: 6266152919155,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC] Acquisition | Smart Aud - 300/day (W/O TURNING OFF DAILY TO CHECK SPEND % AND GET AN IDEA)",
        campaignID: 6266163996955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266163996755,
        adID: 6266163997155,
    },
    {
        adName: "Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC] Acquisition | Smart Aud - 300/day (W/O TURNING OFF DAILY TO CHECK SPEND % AND GET AN IDEA)",
        campaignID: 6266163996955,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266163996755,
        adID: 6266163997355,
    },
    {
        adName: "Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-TEST] Acquisition | Smart Aud - 200/day (ACCELERATED W/ 30Php BID CAP)",
        campaignID: 6266164236155,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266164235955,
        adID: 6266164236355,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-TEST] Acquisition | Smart Aud - 200/day (ACCELERATED W/ 30Php BID CAP)",
        campaignID: 6266164236155,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6266164235955,
        adID: 6266164236555,
    },
    {
        adName: "Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-A/B 1 - Ad Set Comparison] Look-alike: Messaged - 200/day",
        campaignID: 6266816028955,
        adsetName: "Lookalike (PH, 1% to 2%) - [Messaged] 365",
        adsetID: 6266816029155,
        adID: 6266816028755,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-A/B 1 - Ad Set Comparison] Look-alike: Messaged - 200/day",
        campaignID: 6266816028955,
        adsetName: "Lookalike (PH, 1% to 2%) - [Messaged] 365",
        adsetID: 6266816029155,
        adID: 6266816029355,
    },
    {
        adName: "Donation Drive Publication",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC] Donation Drive - Reach",
        campaignID: 6266910364355,
        adsetName: "[EKMC - Donation Drive] Rich + Donation Drive",
        adsetID: 6266910364755,
        adID: 6266910365155,
    },
    {
        adName: "Donation Drive Publication",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[EKMC] Donation Drive - Post Engagement",
        campaignID: 6266912493555,
        adsetName: "[EKMC - Donation Drive] Rich + Donation Drive",
        adsetID: 6266912493355,
        adID: 6266912493755,
    },
    {
        adName: "Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-A/B 1 - Ad Set Comparison] Look-alike: Messaged - 200/day",
        campaignID: 6266816028955,
        adsetName: "Lookalike (PH, 1% to 2%) - [Messaged] 120",
        adsetID: 6266920660555,
        adID: 6266920660355,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-A/B 1 - Ad Set Comparison] Look-alike: Messaged - 200/day",
        campaignID: 6266816028955,
        adsetName: "Lookalike (PH, 1% to 2%) - [Messaged] 120",
        adsetID: 6266920660555,
        adID: 6266920660755,
    },
    {
        adName: "Doctors Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] Acquisition - Smart 500/day | 7php bid | many ad creatives",
        campaignID: 6267776050555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267776050355,
        adID: 6267776050755,
    },
    {
        adName: "Super Pub 1",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] Acquisition - Smart 500/day | 7php bid | many ad creatives",
        campaignID: 6267776050555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267776050355,
        adID: 6267776050955,
    },
    {
        adName: "Super Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] Acquisition - Smart 500/day | 7php bid | many ad creatives",
        campaignID: 6267776050555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267776050355,
        adID: 6267776253155,
    },
    {
        adName: "Doctors Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] Acquisition - Smart 500/day | 7php bid | many ad creatives",
        campaignID: 6267776050555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267776050355,
        adID: 6267776373355,
    },
    {
        adName: "MedCert Pub 2",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] Acquisition - Smart 500/day | 7php bid | many ad creatives",
        campaignID: 6267776050555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267776050355,
        adID: 6267776415955,
    },
    {
        adName: "MedCert Pub 3",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[OFF-EKMC 12] Acquisition - Smart 500/day | 7php bid | many ad creatives",
        campaignID: 6267776050555,
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: 6267776050355,
        adID: 6267776486755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        campaignID: 6237253609155,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6237253610555,
        adID: 6237253610755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        campaignID: 6237977986355,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6237977986755,
        adID: 6237977987755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        campaignID: 6237978915155,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6237978915955,
        adID: 6237978916755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        campaignID: 6237986954955,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6237986955755,
        adID: 6237986956355,
    },
    {
        adName: 'Post: "Gustong makabili ng USANA products sa abot-kayang..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Gustong makabili ng USANA products sa abot-kayang..."',
        campaignID: 6238007822155,
        adsetName: 'Post: "Gustong makabili ng USANA products sa abot-kayang..."',
        adsetID: 6238007824155,
        adID: 6238007824955,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        campaignID: 6238058202155,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6238058202755,
        adID: 6238058203355,
    },
    {
        adName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        campaignID: 6238058625155,
        adsetName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        adsetID: 6238058626355,
        adID: 6238058626955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Effective",
        campaignID: 6238112335955,
        adsetName: "PH - 18+",
        adsetID: 6238112336755,
        adID: 6238112338355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "1.0 - EFFECTIVE - 1k",
        campaignID: 6238364306155,
        adsetName: "PH - 18+",
        adsetID: 6238364307555,
        adID: 6238364309355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "1.1 - EFFECTIVE - 1K",
        campaignID: 6238365109555,
        adsetName: "PH - 18+",
        adsetID: 6238365110755,
        adID: 6238365112955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Effective",
        campaignID: 6238448048355,
        adsetName: "PH - 18+",
        adsetID: 6238448049155,
        adID: 6238448053355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Try 1 | Without interests",
        campaignID: 6238448106155,
        adsetName: "PH - 18+",
        adsetID: 6238448107155,
        adID: 6238448108555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Effective | Bulk vs. Small 1",
        campaignID: 6238448186555,
        adsetName: "PH - 18+",
        adsetID: 6238448187555,
        adID: 6238448193555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Effective | Bulk vs. Small 2",
        campaignID: 6238448203555,
        adsetName: "PH - 18+",
        adsetID: 6238448204155,
        adID: 6238448205755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Monday | Effective | 500",
        campaignID: 6238494713755,
        adsetName: "PH - 18+",
        adsetID: 6238494715755,
        adID: 6238494717955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tuesday | Try 1 | 750 | Bulk vs. Effective 2",
        campaignID: 6238588279755,
        adsetName: "PH - 18+",
        adsetID: 6238588281955,
        adID: 6238588293955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 1 | 500 | Bulk vs. Effective 1",
        campaignID: 6238704321755,
        adsetName: "PH - 18+",
        adsetID: 6238704322755,
        adID: 6238704326555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 1 | 500 | Bulk vs. Effective 2",
        campaignID: 6238704430555,
        adsetName: "PH - 18+",
        adsetID: 6238704432155,
        adID: 6238704435155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 1 | 500 | Bulk vs. Effective 3",
        campaignID: 6238704505955,
        adsetName: "PH - 18+",
        adsetID: 6238704506755,
        adID: 6238704510955,
    },
    {
        adName: 'Post: "Cozy Scandinavian Inspired Dwelling Design 🏠"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Default | 250",
        campaignID: 6238706738155,
        adsetName: 'Post: "Cozy Scandinavian Inspired Dwelling Design 🏠"',
        adsetID: 6238706738755,
        adID: 6238706739555,
    },
    {
        adName: 'Post: "Cozy Scandinavian Inspired Dwelling Design 🏠"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 1 | 250",
        campaignID: 6238706804955,
        adsetName: 'Post: "Cozy Scandinavian Inspired Dwelling Design 🏠"',
        adsetID: 6238706805355,
        adID: 6238706806355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Try 1 | 1500 | Bulk vs. Effective 3",
        campaignID: 6238822348755,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6238822349555,
        adID: 6238822350355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Try 1 | 500",
        campaignID: 6238822765355,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6238822765555,
        adID: 6238822766555,
    },
    {
        adName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Try 1 | 500",
        campaignID: 6238822893155,
        adsetName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        adsetID: 6238822893755,
        adID: 6238822894355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Smart Audience | 500",
        campaignID: 6238822950155,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6238822950755,
        adID: 6238822951955,
    },
    {
        adName: 'Post: "Cozy Scandinavian Inspired Dwelling Design 🏠"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Default | 250",
        campaignID: 6238823016555,
        adsetName: 'Post: "Cozy Scandinavian Inspired Dwelling Design 🏠"',
        adsetID: 6238823016755,
        adID: 6238823017355,
    },
    {
        adName: 'Post: "Cozy Scandinavian Inspired Dwelling Design 🏠"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Try 2 | 250",
        campaignID: 6238823869555,
        adsetName: 'Post: "Cozy Scandinavian Inspired Dwelling Design 🏠"',
        adsetID: 6238823869755,
        adID: 6238823870555,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Smart Audience | 7",
        campaignID: 6239068980555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239068981355,
        adID: 6239068981555,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Smart Audience | 2",
        campaignID: 6239068993755,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239068994355,
        adID: 6239068995355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Smart Audience | 6",
        campaignID: 6239069148355,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239069148755,
        adID: 6239069149955,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Smart Audience | 5",
        campaignID: 6239069163555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239069163955,
        adID: 6239069164555,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Try 1 | 750",
        campaignID: 6239069216755,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239069217355,
        adID: 6239069217955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Try 1 | 500",
        campaignID: 6239069674755,
        adsetName: "PH - 18+",
        adsetID: 6239069676155,
        adID: 6239069682755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Try 4 | 500",
        campaignID: 6239069954155,
        adsetName: "PH - 30+",
        adsetID: 6239069955155,
        adID: 6239069956355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Smart Audience | 500 | 3",
        campaignID: 6239139047555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239139047955,
        adID: 6239139049155,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Smart Audience | 500 | 4",
        campaignID: 6239139064555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239139065155,
        adID: 6239139066155,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sunday | Try 1 | 500 | 5",
        campaignID: 6239139087555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239139087955,
        adID: 6239139088555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Try 1 | 500 | 1",
        campaignID: 6239187700555,
        adsetName: "PH - 18+",
        adsetID: 6239187701355,
        adID: 6239187703755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Try 1 | 500 | 2",
        campaignID: 6239187753555,
        adsetName: "PH - 18+",
        adsetID: 6239187754155,
        adID: 6239187756355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Try 1 | 500",
        campaignID: 6239187980155,
        adsetName: "PH - 18+",
        adsetID: 6239187980755,
        adID: 6239187982955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Try 3 | 500",
        campaignID: 6239188162555,
        adsetName: "Cavite, Bacoor, Cavite, Calabarzon, Philippines +1... - 18-50",
        adsetID: 6239188163355,
        adID: 6239188165355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Smart Audience | 500",
        campaignID: 6239201891555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239201891755,
        adID: 6239201892755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Default | 250",
        campaignID: 6239207778955,
        adsetName: "PH - 18+",
        adsetID: 6239207779955,
        adID: 6239207782555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Default | 250",
        campaignID: 6239207920355,
        adsetName: "PH - 18+",
        adsetID: 6239207927555,
        adID: 6239207937155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue | Try 1 | 1000",
        campaignID: 6239298310955,
        adsetName: "PH - 18+",
        adsetID: 6239298312155,
        adID: 6239298314555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue | Try 1 | 500 | 2",
        campaignID: 6239298349355,
        adsetName: "PH - 18+",
        adsetID: 6239298350555,
        adID: 6239298355555,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue | Smart Audience | 500",
        campaignID: 6239299008555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239299008955,
        adID: 6239299009955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue | Try 1 | 500",
        campaignID: 6239299265555,
        adsetName: "PH - 18+",
        adsetID: 6239299266555,
        adID: 6239299269955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue | Default | 500 | Bulk vs. Small 1",
        campaignID: 6239301665155,
        adsetName: "PH - 18+",
        adsetID: 6239301665955,
        adID: 6239301668155,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Smart Audience | 1000",
        campaignID: 6239406917355,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239406918555,
        adID: 6239406919155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 1 | 1000",
        campaignID: 6239407006555,
        adsetName: "PH - 18+",
        adsetID: 6239407007755,
        adID: 6239407009755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 1 | 850",
        campaignID: 6239407617155,
        adsetName: "PH - 18+",
        adsetID: 6239407622755,
        adID: 6239407624955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 3 | 200",
        campaignID: 6239407680955,
        adsetName: "Cavite, Bacoor, Cavite, Calabarzon, Philippines +1... - 18-50",
        adsetID: 6239407681955,
        adID: 6239407685355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Default - 10AM | 250",
        campaignID: 6239536047355,
        adsetName: "PH - 18+",
        adsetID: 6239536048155,
        adID: 6239536049755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Default - 10AM | 250 | 2",
        campaignID: 6239536074955,
        adsetName: "PH - 18+",
        adsetID: 6239536076155,
        adID: 6239536079555,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Smart Audience | 1000",
        campaignID: 6239536402755,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239536403555,
        adID: 6239536403955,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Smart Audience | 1000 | 2",
        campaignID: 6239536633955,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239536634555,
        adID: 6239536634955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Try 1 | 1515",
        campaignID: 6239536763355,
        adsetName: "PH - 18+",
        adsetID: 6239536763955,
        adID: 6239536765155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Try 4 | 200",
        campaignID: 6239536930755,
        adsetName: "Cavite, Bacoor, Cavite, Calabarzon, Philippines +1... - F - 18-50",
        adsetID: 6239536932555,
        adID: 6239536936955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Default | 500 - 9AM",
        campaignID: 6239637074755,
        adsetName: "PH - 18+",
        adsetID: 6239637075355,
        adID: 6239637077355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Default | 500 - 4PM",
        campaignID: 6239637154955,
        adsetName: "PH - 18+",
        adsetID: 6239637155955,
        adID: 6239637159155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Try 3 | 200",
        campaignID: 6239637481555,
        adsetName: "Cavite, Bacoor, Cavite, Calabarzon, Philippines +1... - 18-50",
        adsetID: 6239637483155,
        adID: 6239637487955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Try 1 | 500 | 1",
        campaignID: 6239638023755,
        adsetName: "PH - 18+",
        adsetID: 6239638029955,
        adID: 6239638032555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Try 1 | 500 | 2",
        campaignID: 6239638147355,
        adsetName: "PH - 18+",
        adsetID: 6239638155155,
        adID: 6239638161355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Try 1 | 527",
        campaignID: 6239638182555,
        adsetName: "PH - 18+",
        adsetID: 6239638188155,
        adID: 6239638202755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Smart Audience | 1000 - 10AM",
        campaignID: 6239688129155,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239688130155,
        adID: 6239688131355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "SAT | Try 1 | 500 - 2",
        campaignID: 6239764214355,
        adsetName: "PH - 18+",
        adsetID: 6239764215755,
        adID: 6239764217555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "SAT | Try 3 | 500",
        campaignID: 6239764333555,
        adsetName: "Cavite, Bacoor, Cavite, Calabarzon, Philippines +1... - 18-50",
        adsetID: 6239764337555,
        adID: 6239764342355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Smart Audience | 727",
        campaignID: 6239764543555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6239764544155,
        adID: 6239764544955,
    },
    {
        adName: 'Post: "Filipino homes made smarter and greener."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Portfolio - Default | 250 - 1",
        campaignID: 6239764733155,
        adsetName: 'Post: "Filipino homes made smarter and greener."',
        adsetID: 6239764735155,
        adID: 6239764736155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Try 1 | 500",
        campaignID: 6240175871555,
        adsetName: "PH - 18+",
        adsetID: 6240175872755,
        adID: 6240175874755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu | Try 1 - Age/Language | 1500",
        campaignID: 6240233055755,
        adsetName: "PH - 20-60",
        adsetID: 6240233059555,
        adID: 6240233063955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Trial A.2 | 1500",
        campaignID: 6240369659755,
        adsetName: "PH - 20+",
        adsetID: 6240369660755,
        adID: 6240369663155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial E | 500 - 1",
        campaignID: 6240493097355,
        adsetName: "PH - 18-60",
        adsetID: 6240493098355,
        adID: 6240493102955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial E | 500 - 2",
        campaignID: 6240493125555,
        adsetName: "PH - 18-60",
        adsetID: 6240493126955,
        adID: 6240493128955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial E | 500 - 3",
        campaignID: 6240493133755,
        adsetName: "PH - 18-60",
        adsetID: 6240493135755,
        adID: 6240493141755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial A.2 | 500 - 1",
        campaignID: 6240495214355,
        adsetName: "PH - 20+",
        adsetID: 6240495221755,
        adID: 6240495227755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial A.2 | 500 - 2",
        campaignID: 6240495266955,
        adsetName: "PH - 20+",
        adsetID: 6240495269955,
        adID: 6240495273555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial A.2 | 500 - 3",
        campaignID: 6240495303355,
        adsetName: "PH - 20+",
        adsetID: 6240495306755,
        adID: 6240495312355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial A.2 | 750",
        campaignID: 6240495427555,
        adsetName: "PH - 20+",
        adsetID: 6240495432755,
        adID: 6240495436955,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sun | Smart Audience | 950",
        campaignID: 6240608427155,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6240608427755,
        adID: 6240608428555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sun | Try 1.1 | 500",
        campaignID: 6240609426955,
        adsetName: "PH - 20+",
        adsetID: 6240609427955,
        adID: 6240609429155,
    },
    {
        adName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sun | Try 1.1 | 500",
        campaignID: 6240609829555,
        adsetName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        adsetID: 6240609830755,
        adID: 6240609831155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sun | DDB | 500",
        campaignID: 6240610883555,
        adsetName: "PH - 18+",
        adsetID: 6240610884155,
        adID: 6240610885755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sat | Trial E | 459.51",
        campaignID: 6240617708155,
        adsetName: "PH - 18-60",
        adsetID: 6240617709155,
        adID: 6240617710355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Try 1 | 740",
        campaignID: 6240656813555,
        adsetName: "PH - 18+",
        adsetID: 6240656815555,
        adID: 6240656817355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue | Default | 250",
        campaignID: 6240721733955,
        adsetName: "PH - 18+",
        adsetID: 6240721734555,
        adID: 6240721735955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue | Engaged with ads | 250",
        campaignID: 6240721782355,
        adsetName: "PH - 18+",
        adsetID: 6240721783355,
        adID: 6240721784555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue-Wed | Try 1.1 - 627.81",
        campaignID: 6240794523555,
        adsetName: "PH - 20+",
        adsetID: 6240794524155,
        adID: 6240794527155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue-Wed | Trial E - 1000 + 358.52",
        campaignID: 6240794683555,
        adsetName: "PH - 18-60",
        adsetID: 6240794686155,
        adID: 6240794691755,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue-Wed | Smart Audience - 1500",
        campaignID: 6240818215755,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6240818216355,
        adID: 6240818217555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed | Default | Loft (Most Effective) - 150",
        campaignID: 6240821691155,
        adsetName: "PH - 20-64",
        adsetID: 6240821692755,
        adID: 6240821695355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue-Wed | Old - 500",
        campaignID: 6240881700155,
        adsetName: "PH - 18+",
        adsetID: 6240881701155,
        adID: 6240881705155,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed-Thu | Smart Audience - 750",
        campaignID: 6240905886955,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6240905887955,
        adID: 6240905888555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed-Thu Graveyard | Trial E - 300",
        campaignID: 6240946135555,
        adsetName: "PH - 18-60",
        adsetID: 6240946136355,
        adID: 6240946137955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed-Thu | Trial E - 600",
        campaignID: 6240947280155,
        adsetName: "PH - 18-60",
        adsetID: 6240947281355,
        adID: 6240947282955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Wed-Thu | Try 1 - 600",
        campaignID: 6240952181755,
        adsetName: "PH - 18-60",
        adsetID: 6240952182555,
        adID: 6240952184755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Until Sunday - Default - 1718",
        campaignID: 6241058611755,
        adsetName: "PH - 18+",
        adsetID: 6241058612755,
        adID: 6241058614355,
    },
    {
        adName: 'Post: ""',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: ""',
        campaignID: 6201114913555,
        adsetName: 'Post: ""',
        adsetID: 6201114914155,
        adID: 6201114914955,
    },
    {
        adName: "[30/06/2020] Promoting Olema's Pharmacy",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "[30/06/2020] Promoting Olema's Pharmacy",
        campaignID: 6201128295955,
        adsetName: "[30/06/2020] Promoting Olema's Pharmacy",
        adsetID: 6201128296755,
        adID: 6201128297355,
    },
    {
        adName: 'Post: "HI, AGGIES (and other agri lovers)! 💚"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "HI, AGGIES (and other agri lovers)! 💚"',
        campaignID: 6232959509755,
        adsetName: 'Post: "HI, AGGIES (and other agri lovers)! 💚"',
        adsetID: 6232959511155,
        adID: 6232959512555,
    },
    {
        adName: 'Post: "[ACCEPTING ORDERS | SPECIAL GIVEAWAY: WIN FREE MERCH]"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "[ACCEPTING ORDERS | SPECIAL GIVEAWAY: WIN FREE MERCH]"',
        campaignID: 6233695200955,
        adsetName: 'Post: "[ACCEPTING ORDERS | SPECIAL GIVEAWAY: WIN FREE MERCH]"',
        adsetID: 6233695201955,
        adID: 6233695202955,
    },
    {
        adName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        campaignID: 6237997679155,
        adsetName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        adsetID: 6237997680955,
        adID: 6237997681155,
    },
    {
        adName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        campaignID: 6238012922955,
        adsetName: 'Post: "Tunay at Mahusay na Serbisyong Medikal ang hanap?"',
        adsetID: 6238012923355,
        adID: 6238012923955,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        campaignID: 6238060335555,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6238060336355,
        adID: 6238060337555,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        campaignID: 6238061961155,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6238061961555,
        adID: 6238061962155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "E-Konsulta Medical Clinic - Message Replies",
        campaignID: 6238112384955,
        adsetName: "PH - 18+",
        adsetID: 6238112386555,
        adID: 6238112389355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Trial",
        campaignID: 6238250846355,
        adsetName: "PH - F - 25-54",
        adsetID: 6238250847955,
        adID: 6238250849955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Effective",
        campaignID: 6238251043755,
        adsetName: "PH - 18+",
        adsetID: 6238251044355,
        adID: 6238251047755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Trial",
        campaignID: 6238292190155,
        adsetName: "PH - F - 25-54",
        adsetID: 6238292191355,
        adID: 6238292223355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Monday | Effective | 500",
        campaignID: 6238494633955,
        adsetName: "PH - 18+",
        adsetID: 6238494634755,
        adID: 6238494636155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Monday | Try 1 | 500",
        campaignID: 6238494658755,
        adsetName: "PH - 18+",
        adsetID: 6238494659955,
        adID: 6238494661155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Monday | Try 1 | 1000",
        campaignID: 6238494704955,
        adsetName: "PH - 18+",
        adsetID: 6238494705755,
        adID: 6238494707555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Monday | Try 2 - With interests | 500",
        campaignID: 6238494938755,
        adsetName: "PH - 18+",
        adsetID: 6238494939755,
        adID: 6238494941755,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Fri | Try 1 | Additional 500",
        campaignID: 6239690296155,
        adsetName: "PH - 18+",
        adsetID: 6239690296755,
        adID: 6239690298355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sun | Smart Audience | 500 - 1",
        campaignID: 6240608392355,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6240608392955,
        adID: 6240608393155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Sun | New Try | 500",
        campaignID: 6240610589355,
        adsetName: "PH - 20-64",
        adsetID: 6240610592555,
        adID: 6240610596355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon | Smart Audience | 800+500",
        campaignID: 6240656866755,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6240656867155,
        adID: 6240656867555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon-Tue | Failed Try 1 + New | 710",
        campaignID: 6240706539355,
        adsetName: "PH - 18+",
        adsetID: 6240706540155,
        adID: 6240706541555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon-Tue | Trial E - 1000",
        campaignID: 6240706653355,
        adsetName: "PH - 18-60",
        adsetID: 6240706654555,
        adID: 6240706656355,
    },
    {
        adName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon-Tue | Smart Audience - 1300",
        campaignID: 6240707298755,
        adsetName: 'Post: "Paano magkonsulta sa magaling na doktor kahit..."',
        adsetID: 6240707299555,
        adID: 6240707299955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Mon-Tue | Most Effective Before - 392.43",
        campaignID: 6240768205955,
        adsetName: "PH - 18+",
        adsetID: 6240768206555,
        adID: 6240768208155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Tue-Wed | Trial A.2 - 500",
        campaignID: 6240794793355,
        adsetName: "PH - 20+",
        adsetID: 6240794794755,
        adID: 6240794796955,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu-Sat (2 Days) | Pub 1.0 | BPO - 1500 || CONTINUE",
        campaignID: 6241005810355,
        adsetName: "PH - 20+",
        adsetID: 6241005811155,
        adID: 6241005812555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu-Sat (2 Days) | Pub 1.0R | BPO - 1500",
        campaignID: 6241005827355,
        adsetName: "PH - 20+",
        adsetID: 6241005828155,
        adID: 6241005830155,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu-Sat (2 Days) | Pub 1.0R | Trial E - 1500",
        campaignID: 6241005881155,
        adsetName: "PH - 18-60",
        adsetID: 6241005881955,
        adID: 6241005883355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu-Sat (2 Days) | Pub 1.0R | Rich - 370",
        campaignID: 6241006131155,
        adsetName: "PH - 20+",
        adsetID: 6241006131955,
        adID: 6241006133555,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu-Sat (2 Days) | Pub 1.0 | Rich - 370 STOPPPP",
        campaignID: 6241006149555,
        adsetName: "PH - 20+",
        adsetID: 6241006150355,
        adID: 6241006151555,
    },
    {
        adName: 'Post: "Magpapakonsulta sa doktor pero.. hindi makalabas..."',
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Thu-Sat (2 Days) | Pub 1.0R | Smart - 370",
        campaignID: 6241025314155,
        adsetName: 'Post: "Magpapakonsulta sa doktor pero.. hindi makalabas..."',
        adsetID: 6241025314355,
        adID: 6241025315355,
    },
    {
        adName: "Ad",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Until Sunday - Default - 1000",
        campaignID: 6241330197955,
        adsetName: "PH - 18+",
        adsetID: 6241330198555,
        adID: 6241330201355,
    },
    {
        adName: "M1_W1_TA1_VID",
        accountID: 223042079,
        accountName: "Paula Jamir",
        campaignName: "Messaging_M1_W1",
        campaignID: 6241792139955,
        adsetName: "M1_W1_TA1_OLDCOPY",
        adsetID: 6241792141755,
        adID: 6241792142155,
    },
    {
        adName: "[EKMC 2] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 2] PM Acquisition | Smart - 1370/day (2-11PM)",
        campaignID: "23850062718590542",
        adsetName: "[EKMC] Vaccines Audience",
        adsetID: "23851007350260542",
        adID: "23851007350240542",
    },
    {
        adName: "[EKMC 2] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 2] PM Acquisition | Smart - 1370/day (2-11PM)",
        campaignID: "23850062718590542",
        adsetName: "[EKMC] Vaccines Audience",
        adsetID: "23851007350260542",
        adID: "23851007350250542",
    },
    {
        adName: "[EKMC 2] Medical Cert Video",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 2] PM Acquisition | Smart - 1370/day (2-11PM)",
        campaignID: "23850062718590542",
        adsetName: "[EKMC] Vaccines Audience",
        adsetID: "23851007350260542",
        adID: "23851007350270542",
    },
    {
        adName: "[EKMC 2] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 2] PM Acquisition | Smart - 1370/day (2-11PM)",
        campaignID: "23850062718590542",
        adsetName: "[EKMC] Vaccines Audience",
        adsetID: "23851007350260542",
        adID: "23851007350280542",
    },
    {
        adName: "[EKMC 1] Bakit Teleconsult?",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 1145/day (AM & PM shift ad set)",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days AM SCHED",
        adsetID: "23850062760120542",
        adID: "23851261428970542",
    },
    {
        adName: "[EKMC 1] Tuberculosis",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 1145/day (AM & PM shift ad set)",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days PM SCHED",
        adsetID: "23850062760100542",
        adID: "23851261436200542",
    },
    {
        adName: "[Old Audience] Immunization Month",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 200/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] Old Audience_05/02/2022",
        adsetID: "23850492401730542",
        adID: "23851267072820542",
    },
    {
        adName: "[Smart Audience] Immunization Month",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 200/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] EKMC Smart Audience",
        adsetID: "23850492401650542",
        adID: "23851267076250542",
    },
    {
        adName: "[EKMC 17] Flu 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] Liked/Follow Page",
        adsetID: "23850977535640542",
        adID: "23850977535710542",
    },
    {
        adName: "[EKMC 11] Updated Doctors",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 11] Acquisition | Smart - 360/day",
        campaignID: "23850263746190542",
        adsetName: "Smart_Excluded messaged 14 days",
        adsetID: "23850263746160542",
        adID: "23850977581860542",
    },
    {
        adName: "[EKMC 18] Med Cert Video",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 18] Acquisition - Vaccines Audience | 80/day",
        campaignID: "23851031647440542",
        adsetName: "[EKMC] Vaccines Audience",
        adsetID: "23851031647460542",
        adID: "23851031647500542",
    },
    {
        adName: "[EKMC 18] E-Doks",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 18] Acquisition - Vaccines Audience | 80/day",
        campaignID: "23851031647440542",
        adsetName: "[EKMC] Vaccines Audience",
        adsetID: "23851031647460542",
        adID: "23851031660660542",
    },
    {
        adName: "[EKMC 18] Online Checkup",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 18] Acquisition - Vaccines Audience | 80/day",
        campaignID: "23851031647440542",
        adsetName: "[EKMC] Vaccines Audience",
        adsetID: "23851031647460542",
        adID: "23851031661890542",
    },
    {
        adName: "[EKMC 17] Monkeypox vs Chickenpox",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] Smart Aud - Messaged 14 days",
        adsetID: "23851058197030542",
        adID: "23851058197020542",
    },
    {
        adName: "[EKMC 17] Diabetes",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] Smart Aud - Messaged 14 days",
        adsetID: "23851058197030542",
        adID: "23851058197050542",
    },
    {
        adName: "[EKMC 17] Flu 0",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] Smart Aud - Messaged 14 days",
        adsetID: "23851058197030542",
        adID: "23851058197060542",
    },
    {
        adName: "[EKMC 17] Diabetes",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] BPO - Messaged 14 days",
        adsetID: "23851058206790542",
        adID: "23851058206750542",
    },
    {
        adName: "[EKMC 17] Flu 0",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] BPO - Messaged 14 days",
        adsetID: "23851058206790542",
        adID: "23851058206760542",
    },
    {
        adName: "[EKMC 17] Monkeypox vs Chickenpox",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] BPO - Messaged 14 days",
        adsetID: "23851058206790542",
        adID: "23851058206780542",
    },
    {
        adName: "[EKMC 16] Bakit Teleconsult",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Retargeting - Acquisition 1 Ad Set | 80/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Engaged (365) & Liked/Follow Page",
        adsetID: "23850720647720542",
        adID: "23851191164060542",
    },
    {
        adName: "[EKMC 16] Loyalty Card",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Retargeting - Acquisition 1 Ad Set | 80/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Engaged (365) & Liked/Follow Page",
        adsetID: "23850720647720542",
        adID: "23851221285610542",
    },
    {
        adName: "[EKMC 16] BillEase",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Retargeting - Acquisition 1 Ad Set | 80/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Engaged (365) & Liked/Follow Page",
        adsetID: "23850720647720542",
        adID: "23851327401250542",
    },
    {
        adName: "[EKMC 17] Flu 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] Engaged 365 days",
        adsetID: "23850977535720542",
        adID: "23850977535650542",
    },
    {
        adName: "[EKMC 17] Online MU",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] Engaged 365 days",
        adsetID: "23850977535720542",
        adID: "23850977567810542",
    },
    {
        adName: "[EKMC 17] Flu 0",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] Liked/Follow Page",
        adsetID: "23850977535640542",
        adID: "23850979261400542",
    },
    {
        adName: "[EKMC 17] Flu 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] Smart Aud - Messaged 14 days",
        adsetID: "23851058197030542",
        adID: "23851058197040542",
    },
    {
        adName: "[EKMC 17] Flu 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 17] Engagement | 115/day",
        campaignID: "23850977535660542",
        adsetName: "[EKMC] BPO - Messaged 14 days",
        adsetID: "23851058206790542",
        adID: "23851058206770542",
    },
    {
        adName: "[Ideal + Current Flu + Pneumo",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 200/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] JVC CANDI Audience",
        adsetID: "23851413050060542",
        adID: "23851413050050542",
    },
    {
        adName: "[Ideal + Current] Immunization Month",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 200/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] JVC CANDI Audience",
        adsetID: "23851413050060542",
        adID: "23851413050070542",
    },
    {
        adName: "[Ideal + Current] Vaccines Old Super Pub",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 200/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] JVC CANDI Audience",
        adsetID: "23851413050060542",
        adID: "23851413050080542",
    },
    {
        adName: "[Ideal + Current] Immunization Month",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 200/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] DDB Rich Client Model",
        adsetID: "23851413164450542",
        adID: "23851413164420542",
    },
    {
        adName: "[Ideal + Current Flu + Pneumo",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 200/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] DDB Rich Client Model",
        adsetID: "23851413164450542",
        adID: "23851413164430542",
    },
    {
        adName: "[Ideal + Current] Vaccines Old Super Pub",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[VACCINES] Acquisition | Vax Default - 200/day",
        campaignID: "23850492401640542",
        adsetName: "[Vaccines] DDB Rich Client Model",
        adsetID: "23851413164450542",
        adID: "23851413164440542",
    },
    {
        adName: "[EKMC 18] E-Doks ng Bayan",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 18] Acquisition | Smart & BPO - 350/day (Doctor Referral ADS)",
        campaignID: "23851555610230542",
        adsetName: "Smart_Excluded messaged 14 days PM SCHED",
        adsetID: "23851555610240542",
        adID: "23851555610150542",
    },
    {
        adName: "[EKMC 18] E-Doks ng Bayan",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 18] Acquisition | Smart & BPO - 350/day (Doctor Referral ADS)",
        campaignID: "23851555610230542",
        adsetName: "Smart_Excluded messaged 14 days AM SCHED",
        adsetID: "23851555610140542",
        adID: "23851555610200542",
    },
    {
        adName: "[EKMC 10] Super Pub Post Pandemic",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF TEMP-EKMC 10] Acquisition | BPO - 582/day (6AM-10PM)",
        campaignID: "23850173519550542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23850173519560542",
        adID: "23851555683980542",
    },
    {
        adName: "[EKMC 1] Super Pub Post Pandemic",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 1960/day (AM & PM shift ad set) [20% = 280]",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days AM SCHED",
        adsetID: "23850062760120542",
        adID: "23851555759720542",
    },
    {
        adName: "[EKMC 1] Super Pub Post Pandemic",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 1] Acquisition | Smart - 1960/day (AM & PM shift ad set) [20% = 280]",
        campaignID: "23850062760060542",
        adsetName: "Smart_Excluded messaged 14 days PM SCHED",
        adsetID: "23850062760100542",
        adID: "23851555787300542",
    },
    {
        adName: "[EKMC 16] Rewards Card",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 19] Retargeting - Acquisition 1 Ad Set | 200/day",
        campaignID: "23851671939500542",
        adsetName: "[EKMC] Engaged (365) & Liked/Follow Page",
        adsetID: "23851671939480542",
        adID: "23851671939510542",
    },
    {
        adName: "[EKMC 20] Tuberculosis M",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 20] Lookalike - Reach 150/day",
        campaignID: "23851736821750542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23851736821780542",
        adID: "23851736821710542",
    },
    {
        adName: "[EKMC 20] Tuberculosis E",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 20] Lookalike - Reach 150/day",
        campaignID: "23851736821750542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23851736821770542",
        adID: "23851736821790542",
    },
    {
        adName: "[EKMC 7] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 7-DAILY] AM Acquisition | BPO - 294/day (6-3PM)",
        campaignID: "23851922518010542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23851922518070542",
        adID: "23851922518000542",
    },
    {
        adName: "[EKMC 7] MedCert 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 7-DAILY] AM Acquisition | BPO - 294/day (6-3PM)",
        campaignID: "23851922518010542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23851922518070542",
        adID: "23851922518020542",
    },
    {
        adName: "[EKMC 7] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 7-DAILY] AM Acquisition | BPO - 294/day (6-3PM)",
        campaignID: "23851922518010542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23851922518070542",
        adID: "23851922518030542",
    },
    {
        adName: "[EKMC 7] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 7-DAILY] AM Acquisition | BPO - 294/day (6-3PM)",
        campaignID: "23851922518010542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23851922517990542",
        adID: "23851922518040542",
    },
    {
        adName: "[EKMC 7] MGA DOKTOR",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 7-DAILY] AM Acquisition | BPO - 294/day (6-3PM)",
        campaignID: "23851922518010542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23851922517990542",
        adID: "23851922518050542",
    },
    {
        adName: "[EKMC 7] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[OFF-EKMC 7-DAILY] AM Acquisition | BPO - 294/day (6-3PM)",
        campaignID: "23851922518010542",
        adsetName: "BPO_Excluded messaged 14 days AM SCHED",
        adsetID: "23851922517990542",
        adID: "23851922518080542",
    },
    {
        adName: "[EKMC 10] Super Pub Post Pandemic",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 10-DAILY] Acquisition | BPO - 700/day (6AM-10PM)",
        campaignID: "23851946671020542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23851946671010542",
        adID: "23851946671030542",
    },
    {
        adName: "[EKMC 10] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 10-DAILY] Acquisition | BPO - 700/day (6AM-10PM)",
        campaignID: "23851946671020542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23851946671010542",
        adID: "23851946671040542",
    },
    {
        adName: "[EKMC 10] MedCert Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 10-DAILY] Acquisition | BPO - 700/day (6AM-10PM)",
        campaignID: "23851946671020542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23851946671010542",
        adID: "23851946671050542",
    },
    {
        adName: "[EKMC 10] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 10-DAILY] Acquisition | BPO - 700/day (6AM-10PM)",
        campaignID: "23851946671020542",
        adsetName: "BPO_Excluded messaged 14 days",
        adsetID: "23851946671010542",
        adID: "23851946671060542",
    },
    {
        adName: "[EKMC 13] MedCert 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 1795/day (2-11PM) [20% = 300]",
        campaignID: "23851982085210542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23851982085270542",
        adID: "23851982085200542",
    },
    {
        adName: "[EKMC 13] Doctors Pub 3",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 1795/day (2-11PM) [20% = 300]",
        campaignID: "23851982085210542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23851982085270542",
        adID: "23851982085220542",
    },
    {
        adName: "[EKMC 13] Super Pub 1",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 1795/day (2-11PM) [20% = 300]",
        campaignID: "23851982085210542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23851982085270542",
        adID: "23851982085240542",
    },
    {
        adName: "[EKMC 13] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 1795/day (2-11PM) [20% = 300]",
        campaignID: "23851982085210542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23851982085190542",
        adID: "23851982085250542",
    },
    {
        adName: "[EKMC 13] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 1795/day (2-11PM) [20% = 300]",
        campaignID: "23851982085210542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23851982085190542",
        adID: "23851982085260542",
    },
    {
        adName: "[EKMC 13] MGA DOKTOR",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 13] PM Acquisition | BPO - 1795/day (2-11PM) [20% = 300]",
        campaignID: "23851982085210542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23851982085190542",
        adID: "23851982085280542",
    },
    {
        adName: "[EKMC 13] Doctors Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 14] Retargeting | BPO - 200/day | TRIAL WITH CUSTOM AUDIENCE",
        campaignID: "23852124761190542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23852124761240542",
        adID: "23852124761170542",
    },
    {
        adName: "[EKMC 13] MedCert Pub 2",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 14] Retargeting | BPO - 200/day | TRIAL WITH CUSTOM AUDIENCE",
        campaignID: "23852124761190542",
        adsetName: "BPO_Excluded messaged 14 days PM SCHED",
        adsetID: "23852124761240542",
        adID: "23852124761180542",
    },
    {
        adName: "[EKMC 20] Doctors Pub 2 M",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 20] Lookalike - Reach 150/day",
        campaignID: "23851736821750542",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23851736821780542",
        adID: "23852124907970542",
    },
    {
        adName: "[EKMC 20] Doctors Pub 2 E",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 20] Lookalike - Reach 150/day",
        campaignID: "23851736821750542",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23851736821770542",
        adID: "23852124920450542",
    },
    {
        adName: "[EKMC 16] Rewards Card",
        accountID: 539865777100096,
        accountName: "EKMC Ads",
        campaignName: "[EKMC 16] Retargeting - Acquisition 1 Ad Set | 80+200/day",
        campaignID: "23850720647630542",
        adsetName: "[EKMC] Engaged (365) & Liked/Follow Page",
        adsetID: "23850720647720542",
        adID: "23851628183960542",
    },
    {
        adName: "[EKMC 15.1] New SuperPub",
        accountID: "1273895199849210",
        accountName: "EKMC Ads 2",
        campaignName: "[EKMC 15.1] Lookalike - Acquisition 2 Ad Sets | 250/day",
        campaignID: "23851953747050248",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23851953857120248",
        adID: "23851953857130248",
    },
    {
        adName: "[EKMC 15.1] MedCert Video",
        accountID: "1273895199849210",
        accountName: "EKMC Ads 2",
        campaignName: "[EKMC 15.1] Lookalike - Acquisition 2 Ad Sets | 250/day",
        campaignID: "23851953747050248",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23851953747070248",
        adID: "23851953830000248",
    },
    {
        adName: "[EKMC 15.1] MedCert Pub 2",
        accountID: "1273895199849210",
        accountName: "EKMC Ads 2",
        campaignName: "[EKMC 15.1] Lookalike - Acquisition 2 Ad Sets | 250/day",
        campaignID: "23851953747050248",
        adsetName: "[EKMC] Lookalike Messaged 365 days",
        adsetID: "23851953747070248",
        adID: "23851953835390248",
    },
    {
        adName: "[EKMC 15.1] Doctors Pub 2",
        accountID: "1273895199849210",
        accountName: "EKMC Ads 2",
        campaignName: "[EKMC 15.1] Lookalike - Acquisition 2 Ad Sets | 250/day",
        campaignID: "23851953747050248",
        adsetName: "[EKMC] Lookalike Engaged 365 days",
        adsetID: "23851953857120248",
        adID: "23851953857110248",
    },
];

module.exports = adsData;
