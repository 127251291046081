
import Header from 'components/header/header'

function Tracker() {
    return (
        <div className="h-full min-h-screen w-full max-w-screen antialiased relative">
            <Header></Header>
            <div className="flex flex-1 relative z-1">
                Tracker
            </div>
        </div>
    )
}

export default Tracker