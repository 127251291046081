import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    loading: true,
    data: {
        emailOptins: [],
        smsOptins: [],
    },
    error: "",
};

export const fetchFollowupsDashboard = createAsyncThunk("followupsDashboard/fetchFollowupsDashboard", async () => {
    let url = `${process.env.REACT_APP_PROD_API_DOMAIN}/follow-ups/`;
    if (process.env.REACT_APP_DEVELOPMENT == `TRUE`) {
        url = `${process.env.REACT_APP_DEV_API_DOMAIN}/follow-ups/`;
    }
    // console.log(process.env.REACT_APP_REFERRALS_ACCESS_TOKEN)
    // console.log(process.env.REACT_APP_GENERAL_ACCESS_TOKEN)
    const config = {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_GENERAL_ACCESS_TOKEN}`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin":
                process.env.REACT_APP_DEVELOPMENT == `TRUE` ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}` : `${process.env.REACT_APP_PROD_CMS_DOMAIN}`,
        },
        withCredentials: true,
    };
    // console.log(config)
    const response = await axios.get(url, config);
    return response.data;
});

const followupsDashboardSlice = createSlice({
    name: "followupsDashboard",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchFollowupsDashboard.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchFollowupsDashboard.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(fetchFollowupsDashboard.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default followupsDashboardSlice.reducer;
