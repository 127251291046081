/* eslint-disable no-async-promise-executor */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "scripts/dayjs-setup";
import { db, Timestamp } from "scripts/firebase-setup";
import adsData from "media/database/adsData";
import axios from "axios";

const initialState = {
    loading: true,
    data: {
        inquiriesByAdID: [],
        inquiriesByCampaignID: [],
    },
    error: "",
};

export const fetchPaidLeads = createAsyncThunk("paidLeads/fetchPaidLeads", async (args) => {
    let start = dayjs(args.start);
    let end = dayjs(args.end);
    let startTimestamp = new Timestamp(start.unix(), 0);
    let endTimestamp = new Timestamp(end.unix(), 0);
    let leadsSnapshot = await db.collection("patients").where(`inquiryTime`, ">=", startTimestamp).where(`inquiryTime`, "<=", endTimestamp).get();
    let inquiriesByAdID = [];
    let inquiriesByCampaignID = [];
    leadsSnapshot.docs.forEach(async (doc) => {
        let docData = doc.data();
        if ("referralAdID" in docData && docData.referralAdID !== " ") {
            let adObject = adsData.find((x) => x.adID == docData.referralAdID);
            const AdIDSnapshot = inquiriesByAdID.find((x) => x.adID == docData.referralAdID);
            if (typeof AdIDSnapshot == "undefined") {
                inquiriesByAdID.push({
                    adID: docData.referralAdID,
                    adName: adObject && "adName" in adObject ? adObject.adName : "unknown",
                    totalPaidLeads: 1,
                    link:
                        process.env.REACT_APP_DEVELOPMENT == `TRUE`
                            ? `${process.env.REACT_APP_DEV_CMS_DOMAIN}/analytics/ad/${docData.referralAdID}`
                            : `${process.env.REACT_APP_PROD_CMS_DOMAIN}/analytics/ad/${docData.referralAdID}`,
                });
            } else {
                let temp = AdIDSnapshot;
                temp.totalPaidLeads += 1;
                inquiriesByAdID[inquiriesByAdID.indexOf(AdIDSnapshot)] = temp;
            }
            if (typeof adObject !== "undefined") {
                const campaignIDSnapshot = inquiriesByCampaignID.find((x) => x.campaignID == adObject.campaignID);
                if (typeof campaignIDSnapshot == "undefined") {
                    inquiriesByCampaignID.push({
                        campaignID: adObject.campaignID,
                        campaignName: adObject.campaignName,
                        totalPaidLeads: 1,
                    });
                } else {
                    let temp = campaignIDSnapshot;
                    temp.totalPaidLeads += 1;
                    inquiriesByCampaignID[inquiriesByCampaignID.indexOf(campaignIDSnapshot)] = temp;
                }
            }
        }
    });
    let promises = [];
    for (let i = 0; i < inquiriesByAdID.length; i++) {
        let promise = new Promise(async (resolve) => {
            try {
                let adInquiryObject = inquiriesByAdID[i];
                let url = `https://graph.facebook.com/v14.0/${adInquiryObject.adID}/insights/`;
                const config = {
                    params: {
                        time_range: `{"since":"${start.format("YYYY-MM-DD")}","until":"${end.format("YYYY-MM-DD")}"}`,
                        fields: "actions,spend",
                        access_token: process.env.REACT_APP_FBMARKETINGAPI_ACCESS_TOKEN,
                    },
                };
                let response = await axios.get(url, config);
                let adApiObject = response.data.data[0];
                let actions = adApiObject.actions;
                let conversionsObject = actions.find((i) => i.action_type == "onsite_conversion.messaging_conversation_started_7d");
                inquiriesByAdID[i].totalPaidAMLeads = parseInt(conversionsObject.value);
                inquiriesByAdID[i].spend = parseFloat(adApiObject.spend);
                let costPerLDLead = adApiObject.spend / inquiriesByAdID[i].totalPaidLeads;
                inquiriesByAdID[i].costPerLDLead = costPerLDLead.toFixed(2);
                let costPerAMLead = adApiObject.spend / parseInt(conversionsObject.value);
                inquiriesByAdID[i].costPerAMLead = costPerAMLead.toFixed(2);
            } catch {
                inquiriesByAdID[i].totalPaidAMLeads = 0;
                inquiriesByAdID[i].spend = 0;
                inquiriesByAdID[i].costPerLDLead = 0;
                inquiriesByAdID[i].costPerAMLead = 0;
            }
            resolve();
        });
        promises.push(promise);
    }
    for (let i = 0; i < inquiriesByCampaignID.length; i++) {
        let promise = new Promise(async (resolve) => {
            try {
                let adInquiryObject = inquiriesByCampaignID[i];
                let url = `https://graph.facebook.com/v14.0/${adInquiryObject.campaignID}/insights/`;
                const config = {
                    params: {
                        time_range: `{"since":"${start.format("YYYY-MM-DD")}","until":"${end.format("YYYY-MM-DD")}"}`,
                        fields: "actions,spend",
                        access_token: process.env.REACT_APP_FBMARKETINGAPI_ACCESS_TOKEN,
                    },
                };
                let response = await axios.get(url, config);
                let campaignApiObject = response.data.data[0];
                let actions = campaignApiObject.actions;
                let conversionsObject = actions.find((i) => i.action_type == "onsite_conversion.messaging_conversation_started_7d");
                inquiriesByCampaignID[i].totalPaidAMLeads = parseInt(conversionsObject.value);
                inquiriesByCampaignID[i].spend = parseFloat(campaignApiObject.spend);
                let costPerLDLead = campaignApiObject.spend / inquiriesByCampaignID[i].totalPaidLeads;
                inquiriesByCampaignID[i].costPerLDLead = costPerLDLead.toFixed(2);
                let costPerAMLead = campaignApiObject.spend / parseInt(conversionsObject.value);
                inquiriesByCampaignID[i].costPerAMLead = costPerAMLead.toFixed(2);
            } catch {
                inquiriesByCampaignID[i].totalPaidAMLeads = 0;
                inquiriesByCampaignID[i].spend = 0;
                inquiriesByCampaignID[i].costPerLDLead = 0;
                inquiriesByCampaignID[i].costPerAMLead = 0;
            }
            resolve();
        });
        promises.push(promise);
    }
    await Promise.all(promises);

    let payload = {
        inquiriesByAdID: inquiriesByAdID,
        inquiriesByCampaignID: inquiriesByCampaignID,
    };
    return payload;
});

const paidLeadsSlice = createSlice({
    name: "paidLeads",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchPaidLeads.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchPaidLeads.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(fetchPaidLeads.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default paidLeadsSlice.reducer;
