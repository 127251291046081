import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    labRequestPayload: {
        documentID: "",
        dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
        patientName: "",
        patientAge: "0",
        patientSex: "M",
        address: "",
        notes: "",
    }
}

export const labRequestSlice = createSlice({
    name: 'labRequest',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            state.labRequestPayload = action.payload
        },
        clear: (state) => {
            state.labRequestPayload = initialState.labRequestPayload
        }
    }
})

export const { set, clear } = labRequestSlice.actions

export default labRequestSlice.reducer