import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    clinicalAbstractPayload: {
        documentID: "",
        dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
        patientName: "",
        patientAge: "0",
        patientSex: "M",
        address: "",
        notes: "",
        civilStatus: "Single",
        chiefComplaint: "",
        presentHistory: "",
        pastHistory: "",
        diagnosis: "",
        therapeutics: "",
        advice: "",
        diet: "",
    }
}

export const clinicalAbstractSlice = createSlice({
    name: 'clinicalAbstract',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            state.clinicalAbstractPayload = action.payload
        },
        clear: (state) => {
            state.clinicalAbstractPayload = initialState.clinicalAbstractPayload
        }
    }
})

export const { set, clear } = clinicalAbstractSlice.actions

export default clinicalAbstractSlice.reducer