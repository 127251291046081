// React Router
import {Routes, Route} from "react-router-dom";
import {BrowserRouter} from "react-router-dom";
import PrivateRoute from "./components/privateRoute/privateRoute";

// Pages
import Login from "./pages/login/login";
import Home from "./pages/home/home";
import Null from "./pages/null/null";

import Dashboard from "./pages/dashboard/dashboard";
import FollowUps from "pages/dashboard/follow-ups/follow-ups";
import Referrals from "./pages/dashboard/referrals/referrals";
import Sales from "./pages/dashboard/sales/sales";

import Analytics from "./pages/analytics/analytics";
import AdAnalytics from "./pages/analytics/ad/ad";
import CampaignAnalytics from "./pages/analytics/campaign/campaign";
import LeadsAnalytics from "./pages/analytics/leads/leads";
import PaidLeadsAnalytics from "./pages/analytics/paid-leads/paid-leads";
import PaidPatientsAnalytics from "./pages/analytics/paid-patients/paid-patients";

import Messages from "./pages/messages/messages";
import Calendar from "./pages/calendar/calendar";
import PatientTracker from "./pages/patients/tracker/tracker";
import PatientDirectory from "./pages/patients/directory/directory";
import Consultations from "pages/patients/consultations/consultations";
import PatientDocuments from "./pages/patients/documents/documents";
import LabFollowups from "./pages/patients/lab-followups/labfollowups";
import GenerateLoyaltyCard from "./pages/loyalty-program/generate/generate";

import MedCert from "./pages/medical-documents/med-cert/med-cert";
import PrescriptionForm from "./pages/medical-documents/prescription-form/prescription-form";
import LabRequest from "./pages/medical-documents/lab-request/lab-request";
import ClinicalAbstract from "./pages/medical-documents/clinical-abstract/clinical-abstract";
import ReferralForm from "./pages/medical-documents/referral-form/referral-form";
import MyFiles from "./pages/medical-documents/my-files/myFiles";

// React Redux Firebase
import {Provider as ReduxProvider} from "react-redux";
import {ReactReduxFirebaseProvider} from "react-redux-firebase";
import {reduxStore, rrfProps} from "./app/store";

// App
export default function App() {
    return (
        <ReduxProvider store={reduxStore}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Login />}></Route>
                        <Route path="/login" element={<Login />}></Route>

                        <Route
                            path="/home"
                            element={
                                <PrivateRoute roles={[]}>
                                    <Home />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/dashboard/sales"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user"]}>
                                    <Sales />
                                </PrivateRoute>
                            }
                        ></Route>
                        <Route
                            path="/dashboard/referrals"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user"]}>
                                    <Referrals />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/dashboard/follow-ups"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user"]}>
                                    <FollowUps />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/consultations"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <Consultations />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/analytics"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <Analytics />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/analytics/leads"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <LeadsAnalytics />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/analytics/paid-leads"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <PaidLeadsAnalytics />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/analytics/paid-patients"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <PaidPatientsAnalytics />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/analytics/ad/:adID"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <AdAnalytics />
                                </PrivateRoute>
                            }
                        ></Route>
                        <Route
                            path="/analytics/campaign/:campaignID"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <CampaignAnalytics />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/calendar"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <Calendar />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/messages"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <Messages />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/patient-tracker"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <PatientTracker />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/patient-directory"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <PatientDirectory />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/patient-documents"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <PatientDocuments />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/lab-followups"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user", "staff", "doctor"]}>
                                    <LabFollowups />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/medical-documents/generate/med-cert"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "doctor"]}>
                                    <MedCert />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/medical-documents/generate/prescription-form"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "doctor"]}>
                                    <PrescriptionForm />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/medical-documents/generate/lab-request"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "doctor"]}>
                                    <LabRequest />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/medical-documents/generate/clinical-abstract"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "doctor"]}>
                                    <ClinicalAbstract />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/medical-documents/generate/referral-form"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "doctor"]}>
                                    <ReferralForm />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/medical-documents/my-files"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "doctor"]}>
                                    <MyFiles />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route
                            path="/loyalty-program/generate"
                            element={
                                <PrivateRoute roles={["superadmin", "admin", "user"]}>
                                    <GenerateLoyaltyCard />
                                </PrivateRoute>
                            }
                        ></Route>

                        <Route path="*" element={<Null />}></Route>
                    </Routes>
                </BrowserRouter>
            </ReactReduxFirebaseProvider>
        </ReduxProvider>
    );
}
