/* eslint-disable react/jsx-key */
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingBlock } from "components/loading/loading";
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from "react-table";
import dayjs from "scripts/dayjs-setup";
import linkButton from "components/cells/linkButton";
export function adNameCell({ value }) {
    return (
        <div className="flex justify-start">
            <span>{value}</span>
        </div>
    );
}

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <label className="flex items-baseline gap-x-2">
            <span className="text-gray-700">Search: </span>
            <input
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 whitespace-nowrap"
                value={value || ``}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </label>
    );
}

export function Consultations({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data
        },
        useGlobalFilter,
        useSortBy
    );

    return (
        <div className="flex flex-col justify-center w-full h-full">
            <div className="flex items-center p-2 mb-2 font-bold gap-x-2">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>
            <div className="flex flex-1 overflow-x-scroll">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-6 py-3 text-xs font-bold tracking-wider text-center text-black uppercase"
                                    >
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? " 🔽"
                                                    : " 🔼"
                                                : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-6 py-4 text-center whitespace-nowrap"
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

import checkBox from "components/cells/checkBox";
export function ConsultationsParent() {
    const consultationsPayload = useSelector((state) => state.paidPatients);
    const consultationsData = consultationsPayload.data;
    const columns = useMemo(
        () => [
            {
                Header: "Timestamp",
                accessor: "timestamp",
                sortType: (rowA, rowB, columnId) => {
                    /* if (rowA > rowB) return 1;
                    return -1; */
                    const valueA = dayjs(rowA.values[columnId]);
                    const valueB = dayjs(rowB.values[columnId]);

                    // Opposite
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: "Patient Name",
                accessor: "patientName"
            },
            {
                Header: "PSID",
                accessor: "psID",
                Cell: adNameCell
            },
            {
                Header: "AD ID",
                accessor: "adID",
                Cell: adNameCell
            },
            {
                Header: "Patient ID",
                accessor: "patientID"
            },
            {
                Header: "Consultation ID",
                accessor: "consultationID"
            },
            {
                Header: "Manychat Link",
                accessor: "manychatLink",
                Cell: linkButton
            },
            {
                Header: "Booked",
                accessor: "isBooked",
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: "Consulted",
                accessor: "isConsulted",
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: "Cancelled",
                accessor: "isCancelled",
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: "Paid",
                accessor: "isPaid",
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: "Amount Paid",
                accessor: "amountPaid"
            }
        ],
        []
    );
    return (
        <div>
            {!consultationsPayload.loading && (
                <Consultations columns={columns} data={consultationsData}></Consultations>
            )}
            {consultationsPayload.loading && (
                <div className="w-full h-80">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}
