import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    referralFormPayload: {
        documentID: "",
        dateOfCertification: `${new Date().toLocaleDateString(`en-CA`)}`,
        patientName: "",
        patientAge: "0",
        patientSex: "M",
        chiefComplaint: "",
        diagnosis: "",
        therapeutics: "",
        referralReason: "",
        referralReceiver: "",
        dateOfConsultation: `${new Date().toLocaleDateString(`en-CA`)}`,
        dateOfBirth: "",
        history: "",
        diagnostics: "",
        remarks: "",
    }
}

export const referralFormSlice = createSlice({
    name: 'referralForm',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            state.referralFormPayload = action.payload
        },
        clear: (state) => {
            state.referralFormPayload = initialState.referralFormPayload
        }
    }
})

export const { set, clear } = referralFormSlice.actions

export default referralFormSlice.reducer