
import Header from 'components/header/header'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchMyFiles } from 'features/medical-documents/my-files/myFilesSlice'
import dayjs from 'scripts/dayjs-setup'
import documentType from 'components/cells/documentType'
import downloadButton from 'components/cells/downloadButton'
import editButton from './editButton'
import Table, { SelectColumnFilter } from './table'


function MyFiles() {
    const profile = useSelector(state => state.firebase.profile)
    const myFilesData = useSelector(state => state.myFiles)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchMyFiles(profile.displayName))
    }, [])

    const columns = useMemo(() => [
        {
            Header: `Timestamp`,
            accessor: `timestamp`,
            sortType: (rowA, rowB, columnId) => {
                /* if (rowA > rowB) return 1;
                return -1; */
                const valueA = dayjs(rowA.values[columnId]);
                const valueB = dayjs(rowB.values[columnId]);

                // Opposite
                return +valueA > +valueB ? -1 : 1;
            },
        },
        {
            Header: `Patient Name`,
            accessor: `patient_name`,
        },
        {
            Header: `Form Type`,
            accessor: `form_type`,
            Filter: SelectColumnFilter,  // new
            filter: `includes`,  // new
            Cell: documentType,
        },
        {
            Header: `Download`,
            accessor: `download`,
            Cell: downloadButton,
        },
        {
            Header: `Edit`,
            accessor: `edit`,
            Cell: editButton,
        },
    ], [])

    return (
        <div className="flex flex-col h-full min-h-screen w-full max-w-screen antialiased">
            <Header></Header>
            <div className="flex flex-1 w-full bg-medical-documents bg-cover bg-no-repeat justify-center items-center">
                <div className='bg-white border-2 h-full border-primary rounded-xl shadow-xl p-10 flex items-center justify-center m-10'>
                    {!myFilesData.loading && <div className="w-full h-full">
                        <Table columns={columns} data={myFilesData.data}></Table>
                    </div>}
                </div>

            </div>
        </div>
    )
}

export default MyFiles