
import { useState } from 'react'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { BsCircleFill } from 'react-icons/bs'

const activities = {
    online: {
        name: "Online",
        color: "text-green-400"
    },
    offline: {
        name: "Offline",
        color: "text-red-400"
    },
}

function StatusButton() {
    let [currentStatus, setCurrentStatus] = useState(activities.online)
    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement)
    return (
        <Popover className="" >
            <Popover.Button ref={setReferenceElement} className="bg-white px-2 py-1 rounded-lg font-bold flex flex-row items-center">Status: <BsCircleFill className={`h-3 w-3 ${currentStatus.color} ml-2 mr-1`} /> {currentStatus.name}</Popover.Button>
            <Popover.Panel className="" ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}>
                <div className="grid grid-cols-1 bg-white gap-1 py-3 px-4 mt-2 border border-primary rounded-lg shadow-lg">
                    <button className="flex flex-row bg-slate-200 p-1 rounded-lg items-center" onClick={(e) => {
                        e.preventDefault()
                        setCurrentStatus(activities.online)
                    }}>
                        <BsCircleFill className={`h-3 w-3 ${activities.online.color} mx-1`} />  Online
                    </button>
                    <button className="flex flex-row bg-slate-200 p-1 rounded-lg items-center" onClick={(e) => {
                        e.preventDefault()
                        setCurrentStatus(activities.offline)
                    }}>
                        <BsCircleFill className={`h-3 w-3 ${activities.offline.color} mx-1`} /> Offline
                    </button>
                </div>
            </Popover.Panel>
        </Popover>
    )
}

export default StatusButton