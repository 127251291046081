import { Popover, Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";

import Userbutton from "./userbutton";
import StatusButton from "./statusButton";
import tertiaryLogo from "media/branding/tertiary-logo.png";

import { GiHamburgerMenu, GiErlenmeyer } from "react-icons/gi";
import { AiFillCloseCircle, AiFillCaretDown, AiOutlineIdcard } from "react-icons/ai";
import { RiPencilRuler2Line } from "react-icons/ri";
import { IoIosAnalytics } from "react-icons/io";
import { IoDocumentOutline } from "react-icons/io5";
import {
    BsFillPeopleFill,
    BsFillPersonFill,
    BsFillPersonCheckFill,
    BsFillPersonPlusFill
} from "react-icons/bs";
import { HiOutlineDocumentText, HiOutlinePencilAlt } from "react-icons/hi";
import { FiSettings, FiHelpCircle } from "react-icons/fi";
import { MdLoyalty, MdOutlineSpaceDashboard, MdPeopleAlt } from "react-icons/md";
import { TbStethoscope } from "react-icons/tb";

function Header() {
    return (
        <div className="sticky top-0 z-10 flex items-center justify-between w-full h-16 px-2 pr-4 shadow-xl bg-primary max-w-screen">
            <div className="flex items-center">
                <Popover className="">
                    <Popover.Button className="flex items-center justify-center p-2">
                        <GiHamburgerMenu className="w-6 h-6 text-white"></GiHamburgerMenu>
                    </Popover.Button>
                    <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <Popover.Panel>
                        {() => (
                            <div className="fixed left-0 top-0 h-full bg-white w-[17rem] p-1 flex flex-col overflow-y-auto transition duration-700 ease-in-out">
                                <div className="flex items-center justify-between w-full ">
                                    <div className="p-2 pl-4 mr-8 grow">
                                        <Link to="/home">
                                            <img
                                                src={tertiaryLogo}
                                                alt="EKMC Logo"
                                                className="object-fit"
                                            ></img>
                                        </Link>
                                    </div>
                                    <div className="flex-none pr-3">
                                        <Popover.Button className="flex items-center justify-center">
                                            <AiFillCloseCircle className="w-6 h-6 text-primary grow"></AiFillCloseCircle>
                                        </Popover.Button>
                                    </div>
                                </div>

                                <div className="flex flex-col px-4 mt-10 space-y-3 font-bold text-primary">
                                    <Disclosure
                                        as="div"
                                        className="p-1 hover:bg-gray-100 rounded-xl"
                                    >
                                        <Disclosure.Button className="flex items-center w-full ">
                                            <IoDocumentOutline className="h-7 w-7 text-primary"></IoDocumentOutline>
                                            <span className="pl-2 font-bold text-left grow">
                                                Medical Documents
                                            </span>
                                            <AiFillCaretDown className="w-5 h-5"></AiFillCaretDown>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="">
                                            <div className="flex flex-col px-2 mt-2 space-y-1">
                                                <Link
                                                    to="/medical-documents/generate/med-cert"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <HiOutlinePencilAlt className="h-7 w-7"></HiOutlinePencilAlt>
                                                    <span className="pl-2 grow">Generate</span>
                                                </Link>
                                                <Link
                                                    to="/medical-documents/my-files"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <RiPencilRuler2Line className="h-7 w-7"></RiPencilRuler2Line>
                                                    <span className="pl-2 grow">My Files</span>
                                                </Link>
                                            </div>
                                        </Disclosure.Panel>
                                    </Disclosure>

                                    <Disclosure
                                        as="div"
                                        className="p-1 hover:bg-gray-100 rounded-xl"
                                    >
                                        <Disclosure.Button className="flex items-center w-full ">
                                            <BsFillPeopleFill className="h-7 w-7 text-primary"></BsFillPeopleFill>
                                            <span className="pl-2 font-bold text-left grow">
                                                Patient Tracker
                                            </span>
                                            <AiFillCaretDown className="w-5 h-5"></AiFillCaretDown>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="">
                                            <div className="flex flex-col px-2 mt-2 space-y-1">
                                                <Link
                                                    to="/patient-documents"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <HiOutlineDocumentText className="h-7 w-7"></HiOutlineDocumentText>
                                                    <span className="pl-2 grow">Documents</span>
                                                </Link>
                                                <Link
                                                    to="/lab-followups"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <GiErlenmeyer className="h-7 w-7"></GiErlenmeyer>
                                                    <span className="pl-2 grow">Laboratories</span>
                                                </Link>
                                                <Link
                                                    to="/consultations"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <MdPeopleAlt className="h-7 w-7"></MdPeopleAlt>
                                                    <span className="pl-2 grow">Consultations</span>
                                                </Link>
                                            </div>
                                        </Disclosure.Panel>
                                    </Disclosure>

                                    <Disclosure
                                        as="div"
                                        className="p-1 hover:bg-gray-100 rounded-xl"
                                    >
                                        <Disclosure.Button className="flex items-center w-full ">
                                            <TbStethoscope className="h-7 w-7 text-primary"></TbStethoscope>
                                            <span className="pl-2 font-bold text-left grow">
                                                Referrals Program
                                            </span>
                                            <AiFillCaretDown className="w-5 h-5"></AiFillCaretDown>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="">
                                            <div className="flex flex-col px-2 mt-2 space-y-1">
                                                <Link
                                                    to="/dashboard/referrals"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <MdOutlineSpaceDashboard className="h-7 w-7"></MdOutlineSpaceDashboard>
                                                    <span className="pl-2 grow">Dashboard</span>
                                                </Link>
                                                <a
                                                    href="https://docs.google.com/spreadsheets/d/124IqhU3swksgiLUlFY8ao9_pqSyRUwdSiPoG6MsFrVg/edit?usp=sharing"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <MdOutlineSpaceDashboard className="h-7 w-7"></MdOutlineSpaceDashboard>
                                                    <span className="pl-2 grow">Tracker</span>
                                                </a>
                                            </div>
                                        </Disclosure.Panel>
                                    </Disclosure>
                                    <Disclosure
                                        as="div"
                                        className="p-1 hover:bg-gray-100 rounded-xl"
                                    >
                                        <Disclosure.Button className="flex items-center w-full ">
                                            <IoIosAnalytics className="h-7 w-7 text-primary"></IoIosAnalytics>
                                            <span className="pl-2 font-bold text-left grow">
                                                Leads Database
                                            </span>
                                            <AiFillCaretDown className="w-5 h-5"></AiFillCaretDown>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="">
                                            <div className="flex flex-col px-2 mt-2 space-y-1">
                                                <Link
                                                    to="/analytics/leads"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <BsFillPersonFill className="h-7 w-7"></BsFillPersonFill>
                                                    <span className="pl-2 grow">Leads</span>
                                                </Link>
                                                <Link
                                                    to="/analytics/paid-leads"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <BsFillPersonPlusFill className="h-7 w-7"></BsFillPersonPlusFill>
                                                    <span className="pl-2 grow">Paid Leads</span>
                                                </Link>
                                                <Link
                                                    to="/analytics/paid-patients"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <BsFillPersonCheckFill className="h-7 w-7"></BsFillPersonCheckFill>
                                                    <span className="pl-2 grow">Paid Patients</span>
                                                </Link>
                                            </div>
                                        </Disclosure.Panel>
                                    </Disclosure>

                                    <Disclosure
                                        as="div"
                                        className="p-1 hover:bg-gray-100 rounded-xl"
                                    >
                                        <Disclosure.Button className="flex items-center w-full ">
                                            <MdLoyalty className="h-7 w-7 text-primary"></MdLoyalty>
                                            <span className="pl-2 font-bold text-left grow">
                                                Rewards Program
                                            </span>
                                            <AiFillCaretDown className="w-5 h-5"></AiFillCaretDown>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="">
                                            <div className="flex flex-col px-2 mt-2 space-y-1">
                                                <Link
                                                    to="/loyalty-program/dashboard"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <MdOutlineSpaceDashboard className="h-7 w-7"></MdOutlineSpaceDashboard>
                                                    <span className="pl-2 grow">Dashboard</span>
                                                </Link>
                                                <Link
                                                    to="/loyalty-program/generate"
                                                    className="flex items-center w-full py-1 hover:bg-gray-200 rounded-xl"
                                                >
                                                    <AiOutlineIdcard className="h-7 w-7"></AiOutlineIdcard>
                                                    <span className="pl-2 grow">Generate</span>
                                                </Link>
                                            </div>
                                        </Disclosure.Panel>
                                    </Disclosure>
                                </div>
                                <div className="flex w-auto mx-6 my-10 border rounded-full border-primary" />
                                <div className="flex flex-col px-4 space-y-3 font-bold text-primary">
                                    <Link
                                        to="/dashboard"
                                        className="flex items-center w-full p-1 hover:bg-gray-100 rounded-xl"
                                    >
                                        <FiSettings className="h-7 w-7 text-primary"></FiSettings>
                                        <span className="pl-2 grow">Settings</span>
                                    </Link>
                                    <Link
                                        to="/analytics"
                                        className="flex items-center w-full p-1 hover:bg-gray-100 rounded-xl"
                                    >
                                        <FiHelpCircle className="h-7 w-7"></FiHelpCircle>
                                        <span className="pl-2 grow">Documentation</span>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </Popover.Panel>
                </Popover>
                <div className="hidden md:ml-2 md:flex md:items">
                    <StatusButton />
                </div>
            </div>
            <Userbutton></Userbutton>
        </div>
    );
}

export default Header;
