import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
export default function checkBox({ value }) {
    return (
        <div className="flex justify-center">
            {value ? <div className="text-green-700">
                <AiFillCheckCircle></AiFillCheckCircle>
            </div> : <div className="text-red-700">
                <AiFillCloseCircle></AiFillCloseCircle>
            </div>
            }
        </div>)
}
