
import Header from 'components/header/header'
import dayjs from 'scripts/dayjs-setup'
import { useEffect, useMemo } from 'react'
import { fetchDocuments } from 'features/patients/documents/documentsSlice'
import Table, { SelectColumnFilter } from './table'
import documentType from 'components/cells/documentType'
import downloadButton from 'components/cells/downloadButton'
import { useSelector, useDispatch } from 'react-redux'
function Document() {
    const documentsData = useSelector(state => state.documents)
    const dispatch = useDispatch()
    useEffect(() => {
        if (documentsData.data.length == 0)
            dispatch(fetchDocuments())
    }, [])

    const columns = useMemo(() => [
        {
            Header: `Timestamp`,
            accessor: `timestamp`,
            sortType: (rowA, rowB, columnId) => {
                /* if (rowA > rowB) return 1;
                return -1; */
                const valueA = dayjs(rowA.values[columnId]);
                const valueB = dayjs(rowB.values[columnId]);

                // Opposite
                return +valueA > +valueB ? -1 : 1;
            },
        },
        {
            Header: `Patient Name`,
            accessor: `patient_name`,
        },
        {
            Header: `Doctor Name`,
            Filter: SelectColumnFilter,
            accessor: `doctor_name`,
        },
        {
            Header: `Form Type`,
            accessor: `form_type`,
            Filter: SelectColumnFilter,
            filter: `includes`,
            Cell: documentType,
        },
        {
            Header: `Download`,
            accessor: `download`,
            Cell: downloadButton,
        },
    ], [])

    return (
        <div className="flex flex-col h-full min-h-screen w-full max-w-screen antialiased">
            <Header></Header>
            <div className="flex flex-1 w-full bg-medical-documents bg-cover bg-no-repeat justify-center items-center">
                <div className='bg-white border-2 h-full border-primary rounded-xl shadow-xl p-10 flex items-center justify-center m-10'>
                    {!documentsData.loading && <div className="w-full h-full">
                        <Table columns={columns} data={documentsData.data}></Table>
                    </div>}
                </div>

            </div>
        </div>
    )
}

export default Document