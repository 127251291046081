/* eslint-disable react/jsx-key */
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import linkButton from 'components/cells/linkButton'
import { LoadingBlock } from 'components/loading/loading'
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table'
import dayjs from 'scripts/dayjs-setup'

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <label className="flex gap-x-2 items-baseline">
            <span className="text-gray-700">Search: </span>
            <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 whitespace-nowrap"
                value={value || ``}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </label>
    )
}

export function AdInquiries({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy
    )

    return (
        <div className="h-full w-full flex flex-col">
            <div className="flex gap-x-2 mb-2 font-bold p-2 items-center">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter} />
            </div>
            <div className="flex flex-1 overflow-x-scroll">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-6 py-3 text-xs text-center text-black uppercase tracking-wider font-bold">
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {rows.map(
                            (row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()} className="px-6 py-4 text-center whitespace-nowrap">{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                </table>
            </div>



        </div>
    )

}

export function AdInquiriesParent() {
    const adAnalyticsData = useSelector(state => state.adAnalytics)
    const AdInfoData = adAnalyticsData.data.info
    const AdInquiriesData = adAnalyticsData.data.inquiries
    const columns = useMemo(
        () => [
            {
                Header: 'Timestamp',
                accessor: 'timestamp',
                sortType: (rowA, rowB, columnId) => {
                    /* if (rowA > rowB) return 1;
                    return -1; */
                    const valueA = dayjs(rowA.values[columnId]);
                    const valueB = dayjs(rowB.values[columnId]);

                    // Opposite
                    return +valueA > +valueB ? -1 : 1;
                },
            },
            {
                Header: 'PSID',
                accessor: 'psID',
            },
            {
                Header: 'Patient ID',
                accessor: 'patientID',
            },
            {
                Header: 'Patient Name',
                accessor: 'patientName',
            },
            {
                Header: 'Manychat Link',
                accessor: 'manychatLink',
                Cell: linkButton
            },
            {
                Header: 'Messages Sent',
                accessor: 'messagesSentCounter',
            },
        ], []
    )
    return (
        <div>
            {!adAnalyticsData.loading &&
                <div className="flex flex-col w-full">
                    <div className="flex justify-between pr-20">
                        <div className="flex flex-col">
                            <span>
                                AdName: {AdInfoData.adName}
                            </span>
                            <span>
                                AdID: {AdInfoData.adID}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span>
                                AdSetName: {AdInfoData.adsetName}
                            </span>
                            <span>
                                AdSetID: {AdInfoData.adsetID}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span>
                                CampaignName: {AdInfoData.campaignName}
                            </span>
                            <span>
                                CampaignID: {AdInfoData.campaignID}
                            </span>
                        </div>
                    </div>

                    <AdInquiries columns={columns} data={AdInquiriesData}>

                    </AdInquiries>
                </div>
            }
            {adAnalyticsData.loading && <div className="w-full h-80">
                <LoadingBlock></LoadingBlock>
            </div>}
        </div>
    )
}

import checkBox from 'components/cells/checkBox'
export function AdPatientsParent() {
    const adAnalyticsData = useSelector(state => state.adAnalytics)
    const AdInquiriesData = adAnalyticsData.data.consultations
    const columns = useMemo(
        () => [
            {
                Header: 'Timestamp',
                accessor: 'timestamp',
                sortType: (rowA, rowB, columnId) => {
                    /* if (rowA > rowB) return 1;
                    return -1; */
                    const valueA = dayjs(rowA.values[columnId]);
                    const valueB = dayjs(rowB.values[columnId]);

                    // Opposite
                    return +valueA > +valueB ? -1 : 1;
                },
            },
            {
                Header: 'PSID',
                accessor: 'psID',
            },
            {
                Header: 'Patient ID',
                accessor: 'patientID',
            },
            {
                Header: 'Consultation ID',
                accessor: 'consultationID',
            },
            {
                Header: 'Patient Name',
                accessor: 'patientName',
            },
            {
                Header: 'Manychat Link',
                accessor: 'manychatLink',
                Cell: linkButton
            },
            {
                Header: 'Booked',
                accessor: 'isBooked',
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    /* if (rowA > rowB) return 1;
                    return -1; */
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];

                    // Opposite
                    return +valueA > +valueB ? -1 : 1;
                },
            },
            {
                Header: 'Consulted',
                accessor: 'isConsulted',
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    /* if (rowA > rowB) return 1;
                    return -1; */
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];

                    // Opposite
                    return +valueA > +valueB ? -1 : 1;
                },
            }, 
            {
                Header: 'Cancelled',
                accessor: 'isCancelled',
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    /* if (rowA > rowB) return 1;
                    return -1; */
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];

                    // Opposite
                    return +valueA > +valueB ? -1 : 1;
                },
            },
            {
                Header: 'Paid',
                accessor: 'isPaid',
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    /* if (rowA > rowB) return 1;
                    return -1; */
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];

                    // Opposite
                    return +valueA > +valueB ? -1 : 1;
                },
            },
            {
                Header: 'Amount Paid',
                accessor: 'amountPaid',
            },

        ], []
    )
    return (
        <div>
            {!adAnalyticsData.loading &&
                <div className="flex flex-col w-full">
                    <AdInquiries columns={columns} data={AdInquiriesData}>

                    </AdInquiries>
                </div>
            }
            {adAnalyticsData.loading && <div className="w-full h-80">
                <LoadingBlock></LoadingBlock>
            </div>}
        </div>
    )
}