import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "scripts/dayjs-setup";
import { Timestamp, db } from "scripts/firebase-setup";
import referralCodes from "media/database/referralsData";

const initialState = {
    loading: true,
    data: {
        line: [],
        pie: [],
        table: [],
        labels: [],
        summary: [],
        tiktokPatients: [],
        tiktokSummary: []
    },
    error: ""
};

export const fetchReferrals = createAsyncThunk("referrals/fetchReferrals", async (args) => {
    let start = dayjs(args.start);
    let end = dayjs(args.end);
    const daysDiff = Math.abs(end.diff(start, "day"));
    const dailyLabels = [];
    for (let i = 0; i <= daysDiff; i++) {
        const tempDayjs = start.add(i, "day");
        dailyLabels.push(tempDayjs.format("ll")); // Aug 16, 2018
    }
    const dailyLineData = [];
    const pieData = [];
    const tableData = [];
    const summaryData = [];
    const tiktokPatientsData = [];
    const tiktokSummaryData = [];

    // get data from firestore database
    const startTimestamp = new Timestamp(start.unix(), 0);
    const endTimestamp = new Timestamp(end.unix(), 0);
    const patientsRef = db.collection(`patients`);
    const consultationsRef = db.collection(`consultations`);

    const patientsSnapshot = await patientsRef
        .where(`referralSource`, `==`, `SHORTLINK`)
        .where(`referralTime`, ">=", startTimestamp)
        .where(`referralTime`, "<=", endTimestamp)
        .get();

    const consultationsSnapshot = await consultationsRef
        .where(`createdAt`, ">=", startTimestamp)
        .where(`createdAt`, "<=", endTimestamp)
        .get();

    const patientsData = [];
    const consultationsData = [];
    for (let doc of patientsSnapshot.docs) {
        patientsData.push(doc.data());
    }
    for (let doc of consultationsSnapshot.docs) {
        consultationsData.push(doc.data());
    }

    for (const doc of patientsData) {
        try {
            if (!referralCodes.some((i) => i.referralCode == doc.referralRef)) {
                console.log(doc.referralRef);
                continue; // referral data is not available in the database
            }
            //  info regarding referral code
            const referralCodeData = referralCodes.find(
                (i) => i.referralCode == doc.referralRef
            ) || {
                doctorName: "None",
                doctorCode: "None"
            };
            const doctorName = referralCodeData.name;
            const doctorCode = referralCodeData.referralCode;
            const referralTimestamp = doc.referralTime;
            const referralDate = referralTimestamp.toDate();
            const referralTime = dayjs(referralDate);
            const dayIndex = Math.abs(referralTime.diff(start, "day"));

            // info regarding consultation status
            let consultationData = {
                booked: "",
                consulted: "",
                cancelled: "",
                paid: "",
                amountPaid: "0"
            };
            const consultationIndex = consultationsData.findIndex((i) => i.psID == doc.psID);

            if (consultationIndex !== -1) {
                consultationData = {
                    booked: consultationsData[consultationIndex].isBookedTagApplied,
                    consulted: consultationsData[consultationIndex].isConsultedTagApplied,
                    cancelled: consultationsData[consultationIndex].isCancelledTagApplied,
                    paid: consultationsData[consultationIndex].isPaidTagApplied,
                    amountPaid: consultationsData[consultationIndex].amountPaid
                };
            } else {
                let consultSnapshot = await db
                    .collection("consultations")
                    .where(`psID`, "==", doc.psID)
                    .get();
                let consultDocument = consultSnapshot.docs[0].data();
                consultationData = {
                    booked: consultDocument.isBookedTagApplied,
                    consulted: consultDocument.isConsultedTagApplied,
                    cancelled: consultDocument.isCancelledTagApplied,
                    paid: consultDocument.isPaidTagApplied,
                    amountPaid: consultDocument.amountPaid
                };
            }

            // build the line data
            const lineIndex = dailyLineData.findIndex((i) => i.doctorCode == doctorCode);
            if (dayIndex <= daysDiff) {
                if (lineIndex == -1) {
                    const tempData = dailyLabels.map(() => 0);
                    tempData[dayIndex] += 1;
                    dailyLineData.push({
                        doctorName: doctorName,
                        doctorCode: doctorCode,
                        data: tempData
                    });
                } else {
                    dailyLineData[lineIndex].data[dayIndex] += 1;
                }
            }

            // build the pie data
            const pieIndex = pieData.findIndex((i) => i.doctorCode == doctorCode);
            if (pieIndex == -1) {
                pieData.push({
                    doctorName: doctorName,
                    doctorCode: doctorCode,
                    data: 1
                });
            } else {
                pieData[pieIndex].data += 1;
            }

            // add to table data
            tableData.push({
                timestamp: referralTime.format("LLL"),
                patientName: doc.fFullName,
                referrer: doctorName,
                manychatLink: doc.manychatLink,
                referralCode: referralCodeData.referralCode,
                psID: doc.psID,
                booked: consultationData.booked,
                consulted: consultationData.consulted,
                cancelled: consultationData.cancelled,
                paid: consultationData.paid,
                amountPaid: consultationData.amountPaid || 0
            });
            if (referralCodeData.userType == "Tiktok") {
                tiktokPatientsData.push({
                    timestamp: referralTime.format("LLL"),
                    patientName: doc.fFullName,
                    referrer: doctorName,
                    manychatLink: doc.manychatLink,
                    referralCode: referralCodeData.referralCode,
                    psID: doc.psID,
                    booked: consultationData.booked,
                    consulted: consultationData.consulted,
                    cancelled: consultationData.cancelled,
                    paid: consultationData.paid,
                    amountPaid: consultationData.amountPaid || 0
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    // summarize all the info available
    for (const data of tableData) {
        let index = summaryData.findIndex((i) => i.referralCode == data.referralCode);
        if (index == -1) {
            summaryData.push({
                referralCode: data.referralCode,
                referrer: data.referrer,
                inquiries: 1,
                booked: data.booked ? 1 : 0,
                consulted: data.consulted ? 1 : 0,
                cancelled: data.cancelled ? 1 : 0,
                paid: data.paid ? 1 : 0,
                amountPaid: parseFloat(data.amountPaid) || 0
            });
        } else {
            summaryData[index].inquiries += 1;
            summaryData[index].booked += data.booked ? 1 : 0;
            summaryData[index].consulted += data.consulted ? 1 : 0;
            summaryData[index].cancelled += data.cancelled ? 1 : 0;
            summaryData[index].paid += data.paid ? 1 : 0;
            summaryData[index].amountPaid += parseFloat(data.amountPaid) || 0;
        }
    }
    // build tiktok summary data

    for (let item of summaryData) {
        const referralCodeData = referralCodes.find((i) => i.referralCode == item.referralCode);
        if (referralCodeData.userType == "Tiktok") tiktokSummaryData.push(item);
    }

    return {
        line: dailyLineData,
        pie: pieData,
        table: tableData,
        summary: summaryData,
        labels: dailyLabels,
        tiktokPatients: tiktokPatientsData,
        tiktokSummary: tiktokSummaryData
    };
});

const referralsSlice = createSlice({
    name: "referrals",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchReferrals.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchReferrals.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(fetchReferrals.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    }
});

export default referralsSlice.reducer;
