/* eslint-disable react/jsx-key */
import { useMemo, useState } from "react";
import { adNameCell } from "../buttons";
import { useSelector } from "react-redux";
import { LoadingBlock } from "components/loading/loading";
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from "react-table";
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <label className="flex gap-x-2 items-baseline">
            <span className="text-gray-700">Search: </span>
            <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 whitespace-nowrap"
                value={value || ``}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </label>
    );
}

export function PaidLeadsByAdID({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, preGlobalFilteredRows, setGlobalFilter } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy
    );

    return (
        <div className="h-full w-full flex flex-col">
            <div className="flex gap-x-2 mb-2 font-bold p-2 items-center">
                <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
            </div>
            <div className="flex flex-1 overflow-x-scroll">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-6 py-3 text-xs text-center text-black uppercase tracking-wider font-bold"
                                    >
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className="px-6 py-4 text-center whitespace-nowrap">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

import linkButton from "components/cells/linkButton";
export function PaidLeadsByAdIDParent() {
    const paidLeadsData = useSelector((state) => state.paidLeads);
    const paidLeadsByAdIDData = paidLeadsData.data.inquiriesByAdID;
    const columns = useMemo(
        () => [
            {
                Header: "Ad ID",
                accessor: "adID",
            },
            {
                Header: "Ad Name",
                accessor: "adName",
                Cell: adNameCell,
            },
            {
                Header: "Total LD Inquiries",
                accessor: "totalPaidLeads",
            },
            {
                Header: "Total AM Inquiries",
                accessor: "totalPaidAMLeads",
            },
            {
                Header: "Total Amount Spent",
                accessor: "spend",
            },
            {
                Header: "Cost/LD Lead",
                accessor: "costPerLDLead",
            },
            {
                Header: "Cost/AM Lead",
                accessor: "costPerAMLead",
            },
            {
                Header: "More Info",
                accessor: "link",
                Cell: linkButton,
            },
        ],
        []
    );
    return (
        <div>
            {!paidLeadsData.loading && <PaidLeadsByAdID columns={columns} data={paidLeadsByAdIDData}></PaidLeadsByAdID>}
            {paidLeadsData.loading && (
                <div className="w-full h-80">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}

export function PaidLeadsByCampaignID({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, preGlobalFilteredRows, setGlobalFilter } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy
    );

    return (
        <div className="h-full w-full flex flex-col">
            <div className="flex gap-x-2 mb-2 font-bold p-2 items-center">
                <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
            </div>
            <div className="flex flex-1 overflow-x-scroll">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-6 py-3 text-xs text-center text-black uppercase tracking-wider font-bold"
                                    >
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className="px-6 py-4 text-center whitespace-nowrap">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export function PaidLeadsByCampaignIDParent() {
    const paidLeadsData = useSelector((state) => state.paidLeads);
    const paidLeadsByCampaignIDData = paidLeadsData.data.inquiriesByCampaignID;
    const columns = useMemo(
        () => [
            {
                Header: "Campaign ID",
                accessor: "campaignID",
            },
            {
                Header: "Campaign Name",
                accessor: "campaignName",
                Cell: adNameCell,
            },
            {
                Header: "Total LD Inquiries",
                accessor: "totalPaidLeads",
            },
            {
                Header: "Total AM Inquiries",
                accessor: "totalPaidAMLeads",
            },
            {
                Header: "Total Amount Spent",
                accessor: "spend",
            },
            {
                Header: "Cost/LD Lead",
                accessor: "costPerLDLead",
            },
            {
                Header: "Cost/AM Lead",
                accessor: "costPerAMLead",
            },
        ],
        []
    );
    return (
        <div>
            {!paidLeadsData.loading && <PaidLeadsByCampaignID columns={columns} data={paidLeadsByCampaignIDData}></PaidLeadsByCampaignID>}
            {paidLeadsData.loading && (
                <div className="w-full h-80">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}
