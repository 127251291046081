/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux'
import LoadingPage from 'components/loading/loading'
import { Navigate } from "react-router-dom";
import findCommonElements from 'scripts/findcommonelements';

function PrivateRoute({ children, roles }) {
    const profile = useSelector(state => state.firebase.profile)
    return (
        <div>
            {profile.isLoaded ?
                !profile.isEmpty ?
                    roles.length == 0 ?
                        children
                        : findCommonElements(profile.roles ? profile.roles : [], roles) ?
                            children
                            : <Navigate to="/home" />
                    : <Navigate to="/login" />
                : <LoadingPage ></LoadingPage>}
        </div>
    )
}

export default PrivateRoute