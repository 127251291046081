/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon
} from "@heroicons/react/solid";
import { classNames } from "scripts/classnames";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useFilters,
    useSortBy,
    usePagination
} from "react-table";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
} from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);
import { Pie, Bar } from "react-chartjs-2";

import { LoadingBlock } from "components/loading/loading";
import { useSelector } from "react-redux";
import getRandomColors, { getRandomBackgroundColor } from "scripts/getRandomColors";
import React, { useMemo } from "react";
import dayjs from "scripts/dayjs-setup";
import linkButton from "components/cells/linkButton";
import checkBox from "components/cells/checkBox";

export function Button({ children, className, ...rest }) {
    return (
        <button
            type="button"
            className={classNames(
                `relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`,
                className
            )}
            {...rest}
        >
            {children}
        </button>
    );
}

export function PageButton({ children, className, ...rest }) {
    return (
        <button
            type="button"
            className={classNames(
                `relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`,
                className
            )}
            {...rest}
        >
            {children}
        </button>
    );
}

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <label className="flex items-baseline gap-x-2">
            <span className="text-gray-700">Search: </span>
            <input
                type="text"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 whitespace-nowrap"
                value={value || ``}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </label>
    );
}

export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id, render }
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <label className="flex items-center gap-x-2 whitespace-nowrap">
            <span className="text-gray-700 whitespace-nowrap">{render(`Header`)}: </span>
            <select
                className="items-center block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 whitespace-nowrap"
                name={id}
                id={id}
                value={filterValue}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}
            >
                <option value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </label>
    );
}

export function SummaryReferredPatientsParent() {
    const referralsData = useSelector((state) => state.referrals);
    return (
        <div>
            {!referralsData.loading && (
                <div className="w-full h-full">
                    <SummaryReferredPatients
                        data={referralsData.data.summary}
                    ></SummaryReferredPatients>
                </div>
            )}
            {referralsData.loading && (
                <div className="w-full h-[30rem]">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}

export function SummaryReferredPatients({ data }) {
    const columns = useMemo(
        () => [
            {
                Header: `Referral Code`,
                accessor: `referralCode`
            },
            {
                Header: `Referrer `,
                accessor: `referrer`,
                Filter: SelectColumnFilter, // new
                filter: `includes` // new
            },
            {
                Header: `Inquiries`,
                accessor: `inquiries`
            },
            {
                Header: `Booked`,
                accessor: `booked`
            },
            {
                Header: `Consulted`,
                accessor: `consulted`
            },
            {
                Header: `Cancelled`,
                accessor: `cancelled`
            },
            {
                Header: `Paid`,
                accessor: `paid`
            },
            {
                Header: `Amount Paid`,
                accessor: `amountPaid`
            }
        ],
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // Render the UI for your table
    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex items-center p-2 mb-2 font-bold gap-x-2">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                            <div className="items-center mt-2 sm:mt-0" key={column.id}>
                                {column.render(`Filter`)}
                            </div>
                        ) : null
                    )
                )}
            </div>

            <div className="flex flex-col flex-1 ">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-bold tracking-wider text-center text-black uppercase"
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render(`Header`)}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ` ▼`
                                                    : ` ▲`
                                                : ``}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-4 py-4 text-sm text-center whitespace-nowrap"
                                            >
                                                {cell.render(`Cell`)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="flex items-center justify-between py-3">
                    <div className="flex justify-between flex-1 sm:hidden">
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </Button>
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </Button>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex items-center p-1 gap-x-2">
                            <span className="items-center mr-3 text-sm text-gray-700 whitespace-nowrap">
                                Page <span className="font-medium">{state.pageIndex + 1}</span> of{" "}
                                <span className="font-medium">{pageOptions.length}</span>
                            </span>
                            <label>
                                <span className="sr-only">Items Per Page</span>
                                <select
                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={state.pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[5, 10, 20].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div>
                            <nav
                                className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                                aria-label="Pagination"
                            >
                                <PageButton
                                    className="rounded-l-md"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    <span className="sr-only">First</span>
                                    <ChevronDoubleLeftIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton
                                    className="rounded-r-md"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    <span className="sr-only">Last</span>
                                    <ChevronDoubleRightIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                    />
                                </PageButton>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function SummaryReferredTiktokPatientsParent() {
    const referralsData = useSelector((state) => state.referrals);
    return (
        <div>
            {!referralsData.loading && (
                <div className="w-full h-full">
                    <SummaryReferredPatients
                        data={referralsData.data.tiktokSummary}
                    ></SummaryReferredPatients>
                </div>
            )}
            {referralsData.loading && (
                <div className="w-full h-[30rem]">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}

export function SummaryReferredTiktokPatients({ data }) {
    const columns = useMemo(
        () => [
            {
                Header: `Referral Code`,
                accessor: `referralCode`
            },
            {
                Header: `Referrer `,
                accessor: `referrer`,
                Filter: SelectColumnFilter, // new
                filter: `includes` // new
            },
            {
                Header: `Inquiries`,
                accessor: `inquiries`
            },
            {
                Header: `Booked`,
                accessor: `booked`
            },
            {
                Header: `Consulted`,
                accessor: `consulted`
            },
            {
                Header: `Cancelled`,
                accessor: `cancelled`
            },
            {
                Header: `Paid`,
                accessor: `paid`
            },
            {
                Header: `Amount Paid`,
                accessor: `amountPaid`
            }
        ],
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // Render the UI for your table
    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex items-center p-2 mb-2 font-bold gap-x-2">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                            <div className="items-center mt-2 sm:mt-0" key={column.id}>
                                {column.render(`Filter`)}
                            </div>
                        ) : null
                    )
                )}
            </div>

            <div className="flex flex-col flex-1 ">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-bold tracking-wider text-center text-black uppercase"
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render(`Header`)}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ` ▼`
                                                    : ` ▲`
                                                : ``}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-4 py-4 text-sm text-center whitespace-nowrap"
                                            >
                                                {cell.render(`Cell`)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="flex items-center justify-between py-3">
                    <div className="flex justify-between flex-1 sm:hidden">
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </Button>
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </Button>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex items-center p-1 gap-x-2">
                            <span className="items-center mr-3 text-sm text-gray-700 whitespace-nowrap">
                                Page <span className="font-medium">{state.pageIndex + 1}</span> of{" "}
                                <span className="font-medium">{pageOptions.length}</span>
                            </span>
                            <label>
                                <span className="sr-only">Items Per Page</span>
                                <select
                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={state.pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[5, 10, 20].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div>
                            <nav
                                className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                                aria-label="Pagination"
                            >
                                <PageButton
                                    className="rounded-l-md"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    <span className="sr-only">First</span>
                                    <ChevronDoubleLeftIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton
                                    className="rounded-r-md"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    <span className="sr-only">Last</span>
                                    <ChevronDoubleRightIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                    />
                                </PageButton>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ReferredPatientsParent() {
    const referralsData = useSelector((state) => state.referrals);
    return (
        <div>
            {!referralsData.loading && (
                <div className="w-full h-full">
                    <ReferredPatients data={referralsData.data.table}></ReferredPatients>
                </div>
            )}
            {referralsData.loading && (
                <div className="w-full h-[30rem]">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}

export function ReferredPatients({ data }) {
    const columns = useMemo(
        () => [
            {
                Header: `Timestamp`,
                accessor: `timestamp`,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = dayjs(rowA.values[columnId]);
                    const valueB = dayjs(rowB.values[columnId]);
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Patient Name`,
                accessor: `patientName`
            },
            {
                Header: `PSID`,
                accessor: `psID`
            },
            {
                Header: `Referral Code`,
                accessor: `referralCode`
            },
            {
                Header: `Referrer `,
                accessor: `referrer`,
                Filter: SelectColumnFilter, // new
                filter: `includes` // new
            },
            {
                Header: `Booked`,
                accessor: `booked`,
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Consulted`,
                accessor: `consulted`,
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Cancelled`,
                accessor: `cancelled`,
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Paid`,
                accessor: `paid`,
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Amount Paid`,
                accessor: `amountPaid`
            },
            {
                Header: `Manychat`,
                accessor: `manychatLink`,
                Cell: linkButton
            }
        ],
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // Render the UI for your table
    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex items-center p-2 mb-2 font-bold gap-x-2">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                            <div className="items-center mt-2 sm:mt-0" key={column.id}>
                                {column.render(`Filter`)}
                            </div>
                        ) : null
                    )
                )}
            </div>

            <div className="flex flex-col flex-1 ">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-bold tracking-wider text-center text-black uppercase"
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render(`Header`)}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ` ▼`
                                                    : ` ▲`
                                                : ``}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-4 py-4 text-sm text-center whitespace-nowrap"
                                            >
                                                {cell.render(`Cell`)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="flex items-center justify-between py-3">
                    <div className="flex justify-between flex-1 sm:hidden">
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </Button>
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </Button>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex items-center p-1 gap-x-2">
                            <span className="items-center mr-3 text-sm text-gray-700 whitespace-nowrap">
                                Page <span className="font-medium">{state.pageIndex + 1}</span> of{" "}
                                <span className="font-medium">{pageOptions.length}</span>
                            </span>
                            <label>
                                <span className="sr-only">Items Per Page</span>
                                <select
                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={state.pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[5, 10, 20].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div>
                            <nav
                                className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                                aria-label="Pagination"
                            >
                                <PageButton
                                    className="rounded-l-md"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    <span className="sr-only">First</span>
                                    <ChevronDoubleLeftIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton
                                    className="rounded-r-md"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    <span className="sr-only">Last</span>
                                    <ChevronDoubleRightIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                    />
                                </PageButton>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ReferredTiktokPatientsParent() {
    const referralsData = useSelector((state) => state.referrals);
    return (
        <div>
            {!referralsData.loading && (
                <div className="w-full h-full">
                    <ReferredTiktokPatients
                        data={referralsData.data.tiktokPatients}
                    ></ReferredTiktokPatients>
                </div>
            )}
            {referralsData.loading && (
                <div className="w-full h-[30rem]">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}

export function ReferredTiktokPatients({ data }) {
    const columns = useMemo(
        () => [
            {
                Header: `Timestamp`,
                accessor: `timestamp`,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = dayjs(rowA.values[columnId]);
                    const valueB = dayjs(rowB.values[columnId]);
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Patient Name`,
                accessor: `patientName`
            },
            {
                Header: `PSID`,
                accessor: `psID`
            },
            {
                Header: `Referral Code`,
                accessor: `referralCode`
            },
            {
                Header: `Referrer `,
                accessor: `referrer`,
                Filter: SelectColumnFilter, // new
                filter: `includes` // new
            },
            {
                Header: `Booked`,
                accessor: `booked`,
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Consulted`,
                accessor: `consulted`,
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Cancelled`,
                accessor: `cancelled`,
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Paid`,
                accessor: `paid`,
                Cell: checkBox,
                sortType: (rowA, rowB, columnId) => {
                    const valueA = rowA.values[columnId];
                    const valueB = rowB.values[columnId];
                    return +valueA > +valueB ? -1 : 1;
                }
            },
            {
                Header: `Amount Paid`,
                accessor: `amountPaid`
            },
            {
                Header: `Manychat`,
                accessor: `manychatLink`,
                Cell: linkButton
            }
        ],
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // Render the UI for your table
    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex items-center p-2 mb-2 font-bold gap-x-2">
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                            <div className="items-center mt-2 sm:mt-0" key={column.id}>
                                {column.render(`Filter`)}
                            </div>
                        ) : null
                    )
                )}
            </div>

            <div className="flex flex-col flex-1 ">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-bold tracking-wider text-center text-black uppercase"
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render(`Header`)}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ` ▼`
                                                    : ` ▲`
                                                : ``}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-4 py-4 text-sm text-center whitespace-nowrap"
                                            >
                                                {cell.render(`Cell`)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="flex items-center justify-between py-3">
                    <div className="flex justify-between flex-1 sm:hidden">
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Previous
                        </Button>
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </Button>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex items-center p-1 gap-x-2">
                            <span className="items-center mr-3 text-sm text-gray-700 whitespace-nowrap">
                                Page <span className="font-medium">{state.pageIndex + 1}</span> of{" "}
                                <span className="font-medium">{pageOptions.length}</span>
                            </span>
                            <label>
                                <span className="sr-only">Items Per Page</span>
                                <select
                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={state.pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[5, 10, 20].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div>
                            <nav
                                className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                                aria-label="Pagination"
                            >
                                <PageButton
                                    className="rounded-l-md"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    <span className="sr-only">First</span>
                                    <ChevronDoubleLeftIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                                </PageButton>
                                <PageButton
                                    className="rounded-r-md"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    <span className="sr-only">Last</span>
                                    <ChevronDoubleRightIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                    />
                                </PageButton>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function PieChart({ referralsData }) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom",
                display: false
            },
            title: {
                display: true,
                text: "Patient Referrals by Doctor"
            }
        }
    };
    const labels = referralsData.pie.map((i) => {
        return i.doctorName;
    });
    let patientData = referralsData.pie.map((i) => {
        return i.data;
    });
    let [backgroundColors, borderColors] = getRandomColors();
    const datasets = [
        {
            label: "# of Patients",
            data: patientData,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1
        }
    ];

    const data = { labels, datasets };

    return <Pie options={options} data={data} />;
}

export function PieChartParent() {
    const referralsData = useSelector((state) => state.referrals);
    return (
        <div>
            {!referralsData.loading && (
                <div className="w-full h-[31rem]">
                    <PieChart referralsData={referralsData.data}></PieChart>
                </div>
            )}
            {referralsData.loading && (
                <div className="w-full h-[31rem]">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}

export function LineChart({ referralsData }) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom",
                display: false
            },
            title: {
                display: true,
                text: "Patient Referrals per Day"
            }
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true
            }
        }
    };

    const labels = referralsData.labels;

    const datasets = referralsData.line.map((i) => {
        return {
            label: i.doctorName,
            data: i.data,
            backgroundColor: getRandomBackgroundColor()
        };
    });

    const data = { labels, datasets };

    return <Bar options={options} data={data} width={"100%"} height={"100%"} />;
}

export function LineChartParent() {
    const referralsData = useSelector((state) => state.referrals);
    return (
        <div>
            {!referralsData.loading && (
                <div className="w-full h-[31rem]">
                    <LineChart referralsData={referralsData.data}></LineChart>
                </div>
            )}
            {referralsData.loading && (
                <div className="w-full h-[31rem]">
                    <LoadingBlock></LoadingBlock>
                </div>
            )}
        </div>
    );
}
